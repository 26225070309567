import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
  JsonArray: { input: any; output: any; }
};

export type AdHocService = {
  __typename?: 'AdHocService';
  appointments: Array<Appointment>;
  business: Business;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  maxAppointments: Scalars['Int']['output'];
  service?: Maybe<Service>;
  timeIncrement: Scalars['Int']['output'];
  times: Array<Scalars['DateTime']['output']>;
  timeslots: Scalars['JsonArray']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type Analytics = {
  __typename?: 'Analytics';
  appointments: Array<AnalyticsAppointmentEntry>;
  events: Array<AnalyticsEventEntry>;
  sms: Array<AnalyticsSmsEntry>;
};


export type AnalyticsAppointmentsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
};


export type AnalyticsEventsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
};


export type AnalyticsSmsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnalyticsAppointmentEntry = {
  __typename?: 'AnalyticsAppointmentEntry';
  cancelled: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
};

export type AnalyticsEventEntry = {
  __typename?: 'AnalyticsEventEntry';
  all: Scalars['Int']['output'];
  appointmentCreated: Scalars['Int']['output'];
  appointmentDeleted: Scalars['Int']['output'];
  appointmentUpdated: Scalars['Int']['output'];
  businessUpdated: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  formCreated: Scalars['Int']['output'];
  formDeleted: Scalars['Int']['output'];
  formUpdated: Scalars['Int']['output'];
  patientCreated: Scalars['Int']['output'];
  patientDeleted: Scalars['Int']['output'];
  patientUpdated: Scalars['Int']['output'];
  serviceCreated: Scalars['Int']['output'];
  serviceDeleted: Scalars['Int']['output'];
  serviceUpdated: Scalars['Int']['output'];
  staffCreated: Scalars['Int']['output'];
  staffDeleted: Scalars['Int']['output'];
  staffUpdated: Scalars['Int']['output'];
};

export type AnalyticsSmsEntry = {
  __typename?: 'AnalyticsSMSEntry';
  credits: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  delivered: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invalidatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  adHocService?: Maybe<AdHocService>;
  additionalStaff?: Maybe<Array<Staff>>;
  answers?: Maybe<Scalars['JsonArray']['output']>;
  approved?: Maybe<Scalars['Boolean']['output']>;
  business: Business;
  cancelReason?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  clientTimezone?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  duration: Scalars['Int']['output'];
  events?: Maybe<Array<Event>>;
  groupBookingId?: Maybe<Scalars['String']['output']>;
  guests?: Maybe<Array<Maybe<Guest>>>;
  hangoutLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Invoice>>;
  location?: Maybe<Location>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  originalDate?: Maybe<Scalars['DateTime']['output']>;
  patient: Patient;
  patientVerified?: Maybe<Scalars['Boolean']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  recurrence?: Maybe<Scalars['Json']['output']>;
  recurringAppointmentId?: Maybe<Scalars['ID']['output']>;
  requestUrl?: Maybe<Scalars['String']['output']>;
  reschedulingForm: Form;
  rrule?: Maybe<Scalars['String']['output']>;
  service: Service;
  staff: Staff;
  tagIds?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Scalars['String']['output']>;
  transactionUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type AppointmentReschedulingFormArgs = {
  token: Scalars['String']['input'];
};

export const AppointmentOrderByInput = {
  AnswersAsc: 'answers_ASC',
  AnswersDesc: 'answers_DESC',
  ApprovedAsc: 'approved_ASC',
  ApprovedDesc: 'approved_DESC',
  CancelledAsc: 'cancelled_ASC',
  CancelledDesc: 'cancelled_DESC',
  ClientTimezoneAsc: 'clientTimezone_ASC',
  ClientTimezoneDesc: 'clientTimezone_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  CurrencyAsc: 'currency_ASC',
  CurrencyDesc: 'currency_DESC',
  DateAsc: 'date_ASC',
  DateDesc: 'date_DESC',
  DurationAsc: 'duration_ASC',
  DurationDesc: 'duration_DESC',
  GroupBookingIdAsc: 'groupBookingId_ASC',
  GroupBookingIdDesc: 'groupBookingId_DESC',
  HangoutLinkAsc: 'hangoutLink_ASC',
  HangoutLinkDesc: 'hangoutLink_DESC',
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  IsVideoCallAsc: 'isVideoCall_ASC',
  IsVideoCallDesc: 'isVideoCall_DESC',
  LocationTypeAsc: 'locationType_ASC',
  LocationTypeDesc: 'locationType_DESC',
  LocationValueAsc: 'locationValue_ASC',
  LocationValueDesc: 'locationValue_DESC',
  OriginalDateAsc: 'originalDate_ASC',
  OriginalDateDesc: 'originalDate_DESC',
  PatientEmailRemindedAsc: 'patientEmailReminded_ASC',
  PatientEmailRemindedDesc: 'patientEmailReminded_DESC',
  PatientIdDuplicateAsc: 'patientIdDuplicate_ASC',
  PatientIdDuplicateDesc: 'patientIdDuplicate_DESC',
  PatientSmsRemindedAsc: 'patientSMSReminded_ASC',
  PatientSmsRemindedDesc: 'patientSMSReminded_DESC',
  PaymentIdAsc: 'paymentId_ASC',
  PaymentIdDesc: 'paymentId_DESC',
  PaymentProviderAsc: 'paymentProvider_ASC',
  PaymentProviderDesc: 'paymentProvider_DESC',
  PriceAsc: 'price_ASC',
  PriceDesc: 'price_DESC',
  RecurrenceAsc: 'recurrence_ASC',
  RecurrenceDesc: 'recurrence_DESC',
  RecurringAppointmentIdAsc: 'recurringAppointmentId_ASC',
  RecurringAppointmentIdDesc: 'recurringAppointmentId_DESC',
  RruleAsc: 'rrule_ASC',
  RruleDesc: 'rrule_DESC',
  StaffEmailRemindedAsc: 'staffEmailReminded_ASC',
  StaffEmailRemindedDesc: 'staffEmailReminded_DESC',
  StaffIdDuplicateAsc: 'staffIdDuplicate_ASC',
  StaffIdDuplicateDesc: 'staffIdDuplicate_DESC',
  StaffSmsRemindedAsc: 'staffSMSReminded_ASC',
  StaffSmsRemindedDesc: 'staffSMSReminded_DESC',
  TokenAsc: 'token_ASC',
  TokenDesc: 'token_DESC',
  TransactionUrlAsc: 'transactionUrl_ASC',
  TransactionUrlDesc: 'transactionUrl_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC'
} as const;

export type AppointmentOrderByInput = typeof AppointmentOrderByInput[keyof typeof AppointmentOrderByInput];
export type AuthPayload = {
  __typename?: 'AuthPayload';
  forcedSessionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  user: AuthUserPayload;
  userIsNew: Scalars['Boolean']['output'];
};

export type AuthUserPayload = {
  __typename?: 'AuthUserPayload';
  id: Scalars['ID']['output'];
};

export const AvailabilityBlocker = {
  Appointments: 'APPOINTMENTS',
  ExternalEvents: 'EXTERNAL_EVENTS',
  SameServiceAppointments: 'SAME_SERVICE_APPOINTMENTS',
  TimeBlockers: 'TIME_BLOCKERS'
} as const;

export type AvailabilityBlocker = typeof AvailabilityBlocker[keyof typeof AvailabilityBlocker];
export type AvailableTimes = {
  __typename?: 'AvailableTimes';
  times?: Maybe<Scalars['Json']['output']>;
};

export const BasicAuthCalendarConnectionProvider = {
  Icloud: 'ICLOUD'
} as const;

export type BasicAuthCalendarConnectionProvider = typeof BasicAuthCalendarConnectionProvider[keyof typeof BasicAuthCalendarConnectionProvider];
export const BiologicalSex = {
  Female: 'FEMALE',
  Male: 'MALE',
  Other: 'OTHER'
} as const;

export type BiologicalSex = typeof BiologicalSex[keyof typeof BiologicalSex];
export type BookAppointment = {
  __typename?: 'BookAppointment';
  addToGoogleUrl?: Maybe<Scalars['String']['output']>;
  addToOutlookUrl?: Maybe<Scalars['String']['output']>;
  answers?: Maybe<Scalars['JsonArray']['output']>;
  approved?: Maybe<Scalars['Boolean']['output']>;
  business?: Maybe<Scalars['Json']['output']>;
  clientTimezone?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  hangoutLink?: Maybe<Scalars['String']['output']>;
  icsFileContent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['Json']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<Scalars['String']['output']>;
  pendingPayment?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  rrule?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['Json']['output']>;
  staff?: Maybe<Scalars['Json']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  transactionUrl?: Maybe<Scalars['String']['output']>;
};

export const BookingEvent = {
  AppointmentCreated: 'APPOINTMENT_CREATED',
  AppointmentDeleted: 'APPOINTMENT_DELETED',
  AppointmentUpdated: 'APPOINTMENT_UPDATED',
  RecurringAppointmentCreated: 'RECURRING_APPOINTMENT_CREATED',
  RecurringAppointmentDeleted: 'RECURRING_APPOINTMENT_DELETED',
  RecurringAppointmentUpdated: 'RECURRING_APPOINTMENT_UPDATED'
} as const;

export type BookingEvent = typeof BookingEvent[keyof typeof BookingEvent];
export type BookingEventNotification = {
  __typename?: 'BookingEventNotification';
  appointment: Appointment;
  bookingEventNotificationTemplate: BookingEventNotificationTemplate;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  event: BookingEvent;
  id: Scalars['ID']['output'];
  recipient: MessageRecipient;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentTo?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type BookingEventNotificationTemplate = {
  __typename?: 'BookingEventNotificationTemplate';
  bookingEventNotifications: Array<BookingEventNotification>;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  defaultTemplate?: Maybe<Scalars['Json']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  event: BookingEvent;
  id: Scalars['ID']['output'];
  messageTemplate?: Maybe<Scalars['Json']['output']>;
  recipient: MessageRecipient;
  services: Array<Service>;
  type: MessageType;
  updatedAt: Scalars['DateTime']['output'];
};

export type Business = {
  __typename?: 'Business';
  activeServices?: Maybe<Array<Service>>;
  alias: Scalars['String']['output'];
  allowChoosingStaff?: Maybe<Scalars['Boolean']['output']>;
  allowNoStaffPreference?: Maybe<Scalars['Boolean']['output']>;
  allowStaffApprovals?: Maybe<Scalars['Boolean']['output']>;
  allowTimeRequests?: Maybe<Scalars['Boolean']['output']>;
  anyStaffCalendarSyncEnabled?: Maybe<Scalars['Boolean']['output']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsColumns?: Maybe<Array<Scalars['String']['output']>>;
  attachICSFiles?: Maybe<Scalars['Boolean']['output']>;
  autoGenerateBookingInvoices?: Maybe<Scalars['Boolean']['output']>;
  availabilityLimitDays?: Maybe<Scalars['Int']['output']>;
  availableFeatures?: Maybe<Scalars['Json']['output']>;
  bookingForm: Form;
  bookingFormLegacyConfig?: Maybe<Scalars['Json']['output']>;
  bookingLocale?: Maybe<Scalars['String']['output']>;
  bookingNotificationsReplyTo?: Maybe<NotificationsReplyToType>;
  bookingPageUrl?: Maybe<Scalars['String']['output']>;
  bookingTemplates?: Maybe<Scalars['Json']['output']>;
  bookingTemplatesHtml?: Maybe<Scalars['Json']['output']>;
  bookingsRequireApprovalUntilPaid?: Maybe<Scalars['Boolean']['output']>;
  bufferAfterMinutes?: Maybe<Scalars['Int']['output']>;
  bufferBeforeMinutes?: Maybe<Scalars['Int']['output']>;
  calendarConnectionsCount?: Maybe<Scalars['Int']['output']>;
  calendarSyncEnabled?: Maybe<Scalars['Boolean']['output']>;
  calendarView?: Maybe<CalendarView>;
  canAcceptStripePayments?: Maybe<Scalars['Boolean']['output']>;
  canGenerateHangoutLinks?: Maybe<Scalars['Boolean']['output']>;
  canGenerateTeamsMeetings?: Maybe<Scalars['Boolean']['output']>;
  canGenerateZoomMeetings?: Maybe<Scalars['Boolean']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  contactUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  dateFormat?: Maybe<Scalars['Json']['output']>;
  defaultInvoiceDaysUntilDue?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  enabledStaffsCount?: Maybe<Scalars['Int']['output']>;
  exportedEventsTemplate?: Maybe<Scalars['Json']['output']>;
  features?: Maybe<Scalars['Json']['output']>;
  forceServiceStep?: Maybe<Scalars['Boolean']['output']>;
  googleTrackingId?: Maybe<Scalars['String']['output']>;
  hidePatientTimezoneDropdwon?: Maybe<Scalars['Boolean']['output']>;
  hidePrivacyButton?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  includeAnswersInExportedEvents?: Maybe<Scalars['Boolean']['output']>;
  includeAnswersOnRedirect?: Maybe<Scalars['Boolean']['output']>;
  invoicesTaxBehaviour?: Maybe<TaxBehaviour>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Array<Location>>;
  logo?: Maybe<Picture>;
  logoPosition?: Maybe<Scalars['String']['output']>;
  marketingMeta?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  notificationSettings?: Maybe<Scalars['Json']['output']>;
  notifications?: Maybe<Array<Notification>>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  ownerNotificationsCC?: Maybe<Scalars['String']['output']>;
  ownerNotificationsRecipient?: Maybe<OwnerNotificationsRecipient>;
  patients?: Maybe<Array<Patient>>;
  patientsCount?: Maybe<Scalars['Int']['output']>;
  physicalAddress?: Maybe<Scalars['String']['output']>;
  postOnRedirect?: Maybe<Scalars['Boolean']['output']>;
  previewMode?: Maybe<Scalars['Boolean']['output']>;
  primaryDomain?: Maybe<Scalars['String']['output']>;
  primaryDomainVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  publicServices?: Maybe<Array<Service>>;
  redirectAfterBookingUrl?: Maybe<Scalars['String']['output']>;
  remainingTrialDays?: Maybe<Scalars['Int']['output']>;
  reminderPatientEmail?: Maybe<Scalars['Int']['output']>;
  reminderPatientSMS?: Maybe<Scalars['Int']['output']>;
  reminderStaffEmail?: Maybe<Scalars['Int']['output']>;
  reminderStaffSMS?: Maybe<Scalars['Int']['output']>;
  reminderTemplates?: Maybe<Array<ReminderTemplate>>;
  reschedulingForm: Form;
  sendSMSFromStaffNumber?: Maybe<Scalars['Boolean']['output']>;
  serviceCategories?: Maybe<Array<ServiceCategory>>;
  services?: Maybe<Array<Service>>;
  servicesColumns?: Maybe<Array<Scalars['String']['output']>>;
  servicesCount?: Maybe<Scalars['Int']['output']>;
  servicesOrder?: Maybe<Array<Scalars['String']['output']>>;
  servicesPageView?: Maybe<Scalars['JsonArray']['output']>;
  showCalendarEventsNames?: Maybe<Scalars['Boolean']['output']>;
  showDropdownFilter?: Maybe<Scalars['Boolean']['output']>;
  staffColours?: Maybe<Scalars['Json']['output']>;
  staffRoles: Array<StaffRole>;
  staffs?: Maybe<Array<Staff>>;
  staffsColumns?: Maybe<Array<Scalars['String']['output']>>;
  staffsCount?: Maybe<Scalars['Int']['output']>;
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeData?: Maybe<Scalars['Json']['output']>;
  stripeProducts: Array<StripeProduct>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  teamsConnection?: Maybe<ThirdPartyConnection>;
  templates?: Maybe<Scalars['JsonArray']['output']>;
  termsOfServiceUrl?: Maybe<Scalars['String']['output']>;
  timeRequestButtonLabel?: Maybe<Scalars['String']['output']>;
  timeRequestText?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  trackingPixelUrl?: Maybe<Scalars['String']['output']>;
  unreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usage: Scalars['Json']['output'];
  user?: Maybe<User>;
  /** @deprecated Use staffRole instead */
  userRole?: Maybe<StaffRole>;
  webhooks: Array<OutgoingWebhook>;
  website?: Maybe<Scalars['String']['output']>;
  websiteContainingEmbedCode?: Maybe<Scalars['String']['output']>;
  workingHours: Scalars['Json']['output'];
  zoomConnection?: Maybe<ThirdPartyConnection>;
};


export type BusinessLocationsArgs = {
  where?: InputMaybe<LocationWhereInput>;
};


export type BusinessPatientsArgs = {
  where?: InputMaybe<PatientWhereInput>;
};


export type BusinessPublicServicesArgs = {
  includeServiceForAppointmentId?: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessReminderTemplatesArgs = {
  where?: InputMaybe<ReminderTemplateWhereInput>;
};


export type BusinessServicesPageViewArgs = {
  appointmentsDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  appointmentsDateStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type BusinessUsageArgs = {
  cached?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  accountId?: Maybe<Scalars['String']['output']>;
  allDayEventsTimezone?: Maybe<Scalars['String']['output']>;
  business?: Maybe<Business>;
  calendarEvents: Array<CalendarEvent>;
  calendars?: Maybe<Scalars['JsonArray']['output']>;
  connectedResourceType: CalendarConnectionResourceType;
  createdAt: Scalars['DateTime']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  exportAttendees?: Maybe<Array<CalendarEventAttendee>>;
  exportToCalendarId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importFromCalendarsIds: Array<Scalars['String']['output']>;
  isAuthorized: Scalars['Boolean']['output'];
  lastSyncedAt?: Maybe<Scalars['DateTime']['output']>;
  logs: Array<SynchronizationLog>;
  oauthTokens: Array<OAuthToken>;
  provider: CalendarConnectionProvider;
  providerUser?: Maybe<Scalars['String']['output']>;
  staff?: Maybe<Staff>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export const CalendarConnectionProvider = {
  Google: 'GOOGLE',
  Icloud: 'ICLOUD',
  Microsoft: 'MICROSOFT'
} as const;

export type CalendarConnectionProvider = typeof CalendarConnectionProvider[keyof typeof CalendarConnectionProvider];
export const CalendarConnectionResourceType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type CalendarConnectionResourceType = typeof CalendarConnectionResourceType[keyof typeof CalendarConnectionResourceType];
export type CalendarConnectionWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export const CalendarEventAttendee = {
  AdditionalStaff: 'ADDITIONAL_STAFF',
  Guest: 'GUEST',
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type CalendarEventAttendee = typeof CalendarEventAttendee[keyof typeof CalendarEventAttendee];
export type CalendarEventWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export const CalendarView = {
  Month: 'MONTH',
  Week: 'WEEK'
} as const;

export type CalendarView = typeof CalendarView[keyof typeof CalendarView];
export type CalendarViewExternalEventPayload = {
  __typename?: 'CalendarViewExternalEventPayload';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  staffId?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type CancelAppointment = {
  __typename?: 'CancelAppointment';
  id?: Maybe<Scalars['String']['output']>;
};

export type CheckoutSessionPayload = {
  __typename?: 'CheckoutSessionPayload';
  id: Scalars['String']['output'];
};

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Int']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Float']['output']>;
  redemptionsCount: Scalars['Int']['output'];
  stripeCouponId?: Maybe<Scalars['String']['output']>;
  stripePromotionCodeId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreateFormSubmissionPayload = {
  __typename?: 'CreateFormSubmissionPayload';
  events: Array<FormSubmissionEvent>;
  id: Scalars['ID']['output'];
  redirectDelay?: Maybe<Scalars['Int']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type CreateGuestInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
};

export type CustomField = {
  __typename?: 'CustomField';
  alias: Scalars['String']['output'];
  allowedValues?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  fieldType: CustomFieldType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const CustomFieldType = {
  Boolean: 'BOOLEAN',
  Date: 'DATE',
  Number: 'NUMBER',
  Select: 'SELECT',
  Text: 'TEXT'
} as const;

export type CustomFieldType = typeof CustomFieldType[keyof typeof CustomFieldType];
export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  createdAt: Scalars['DateTime']['output'];
  fieldKey: Scalars['String']['output'];
  fieldType: CustomFieldType;
  id: Scalars['ID']['output'];
  patientId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type DataImport = {
  __typename?: 'DataImport';
  columnsMap: Scalars['Json']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Staff;
  dataImportErrors: Array<DataImportError>;
  id: Scalars['ID']['output'];
  resourceType: Scalars['String']['output'];
  rowsComplete?: Maybe<Scalars['Int']['output']>;
  rowsCount: Scalars['Int']['output'];
  rowsFailed?: Maybe<Scalars['Int']['output']>;
  rowsProcessed?: Maybe<Scalars['Int']['output']>;
  rowsSkipped?: Maybe<Scalars['Int']['output']>;
  status: DataImportStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DataImportError = {
  __typename?: 'DataImportError';
  createdAt: Scalars['DateTime']['output'];
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lineNumber: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export const DataImportStatus = {
  Complete: 'COMPLETE',
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Uploaded: 'UPLOADED',
  Uploading: 'UPLOADING'
} as const;

export type DataImportStatus = typeof DataImportStatus[keyof typeof DataImportStatus];
export type DataRequest = {
  __typename?: 'DataRequest';
  id: Scalars['ID']['output'];
};

export type DefaultNotificationsTemplatesPayload = {
  __typename?: 'DefaultNotificationsTemplatesPayload';
  templates?: Maybe<Scalars['JsonArray']['output']>;
};

export const DiabeticType = {
  None: 'None',
  Type1: 'Type1',
  Type2: 'Type2'
} as const;

export type DiabeticType = typeof DiabeticType[keyof typeof DiabeticType];
export type DownloadFilePayload = {
  __typename?: 'DownloadFilePayload';
  expiresAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['Json']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outgoingWebhookDeliveries: Array<OutgoingWebhookDelivery>;
};

export const ExportableResource = {
  Appointments: 'APPOINTMENTS',
  Patients: 'PATIENTS'
} as const;

export type ExportableResource = typeof ExportableResource[keyof typeof ExportableResource];
export const FaceVitalsBaseRiskCategory = {
  High: 'High',
  Low: 'Low',
  LowMedium: 'LowMedium',
  Medium: 'Medium',
  OutOfBounds: 'OutOfBounds',
  Unknown: 'Unknown',
  VeryHigh: 'VeryHigh'
} as const;

export type FaceVitalsBaseRiskCategory = typeof FaceVitalsBaseRiskCategory[keyof typeof FaceVitalsBaseRiskCategory];
export const FaceVitalsBloodPressureRiskCategory = {
  Elevated: 'Elevated',
  HypertensionStage1: 'HypertensionStage1',
  HypertensionStage2: 'HypertensionStage2',
  HypertensiveCrisis: 'HypertensiveCrisis',
  Hypotensive: 'Hypotensive',
  Normal: 'Normal',
  OutOfBounds: 'OutOfBounds',
  Unknown: 'Unknown'
} as const;

export type FaceVitalsBloodPressureRiskCategory = typeof FaceVitalsBloodPressureRiskCategory[keyof typeof FaceVitalsBloodPressureRiskCategory];
export type FaceVitalsScanResult = {
  __typename?: 'FaceVitalsScanResult';
  appointment?: Maybe<Appointment>;
  bloodPressureDiastolicMmhg?: Maybe<Scalars['Float']['output']>;
  bloodPressureRiskCategory?: Maybe<FaceVitalsBloodPressureRiskCategory>;
  bloodPressureSystolicMmhg?: Maybe<Scalars['Float']['output']>;
  breathingRateBpm?: Maybe<Scalars['Float']['output']>;
  business: Business;
  cardiacWorkload?: Maybe<Scalars['Float']['output']>;
  cardiovascularDiseaseRisk?: Maybe<Scalars['Float']['output']>;
  cardiovascularDiseaseRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  createdAt: Scalars['DateTime']['output'];
  formSubmission?: Maybe<FormSubmission>;
  heartAttackRisk?: Maybe<Scalars['Float']['output']>;
  heartAttackRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  heartRateBpm?: Maybe<Scalars['Int']['output']>;
  heartRateHz?: Maybe<Scalars['Float']['output']>;
  heartRateIrregularBpm?: Maybe<Scalars['Float']['output']>;
  heartRateVariabilityRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  heartRateVariabilitySdnn?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  inputAge: Scalars['Int']['output'];
  inputBloodPressureMedication: Scalars['Boolean']['output'];
  inputDiabeticType: Scalars['String']['output'];
  inputGender: Scalars['String']['output'];
  inputHeight: Scalars['Float']['output'];
  inputHypertension: Scalars['Boolean']['output'];
  inputSmoker: Scalars['Boolean']['output'];
  inputWeight: Scalars['Float']['output'];
  patient?: Maybe<Patient>;
  provider: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  staff?: Maybe<Staff>;
  strokeRisk?: Maybe<Scalars['Float']['output']>;
  strokeRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  updatedAt: Scalars['DateTime']['output'];
  vascularCapacitySecs?: Maybe<Scalars['Float']['output']>;
};

export type File = {
  __typename?: 'File';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  patientId?: Maybe<Scalars['ID']['output']>;
  size: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  alias?: Maybe<Scalars['String']['output']>;
  business?: Maybe<Business>;
  category?: Maybe<Scalars['String']['output']>;
  config?: Maybe<FormConfig>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  settings?: Maybe<Scalars['Json']['output']>;
  status: FormStatus;
  steps?: Maybe<Scalars['Json']['output']>;
  submissionsCount?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['Json']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  unreadSubmissionsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  useAsTemplate?: Maybe<Scalars['Boolean']['output']>;
  warnings?: Maybe<Array<FormWarning>>;
};

export type FormBackground = {
  __typename?: 'FormBackground';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  backgroundSize?: Maybe<Scalars['String']['output']>;
  image?: Maybe<FormImage>;
};

export type FormConfig = {
  __typename?: 'FormConfig';
  background?: Maybe<FormBackground>;
  dateFormat?: Maybe<Scalars['Json']['output']>;
  defaultImage?: Maybe<FormImage>;
  defaultLayout?: Maybe<Scalars['String']['output']>;
  logoPosition?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  nextStepButtonLabel?: Maybe<Scalars['String']['output']>;
  omitPrefilledSteps?: Maybe<Scalars['Boolean']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  requirePatientAuthentication?: Maybe<Scalars['Boolean']['output']>;
};

export type FormImage = {
  __typename?: 'FormImage';
  croppedUrl?: Maybe<Scalars['String']['output']>;
  transforms?: Maybe<Scalars['Json']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export const FormQueryMode = {
  Live: 'LIVE',
  Preview: 'PREVIEW'
} as const;

export type FormQueryMode = typeof FormQueryMode[keyof typeof FormQueryMode];
export const FormStatus = {
  Draft: 'DRAFT',
  Published: 'PUBLISHED',
  PublishedWithDraft: 'PUBLISHED_WITH_DRAFT'
} as const;

export type FormStatus = typeof FormStatus[keyof typeof FormStatus];
export type FormSubmission = {
  __typename?: 'FormSubmission';
  answers: Scalars['Json']['output'];
  appointment?: Maybe<Appointment>;
  booking?: Maybe<Scalars['Json']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  form: Form;
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  patientVerified?: Maybe<Scalars['Boolean']['output']>;
  questions: Scalars['Json']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt: Scalars['DateTime']['output'];
};

export type FormSubmissionEvent = {
  __typename?: 'FormSubmissionEvent';
  data: Scalars['Json']['output'];
  name: Scalars['String']['output'];
};

export type FormSubmissionsMetaPayload = {
  __typename?: 'FormSubmissionsMetaPayload';
  count?: Maybe<Scalars['Int']['output']>;
};

export type FormSubmissionsResponse = {
  __typename?: 'FormSubmissionsResponse';
  formSubmissions: Array<FormSubmission>;
  formSubmissionsMeta: FormSubmissionsMetaPayload;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const FormTemplateLibrary = {
  Custom: 'CUSTOM',
  Official: 'OFFICIAL'
} as const;

export type FormTemplateLibrary = typeof FormTemplateLibrary[keyof typeof FormTemplateLibrary];
export type FormWarning = {
  __typename?: 'FormWarning';
  text: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type GetAppointment = {
  __typename?: 'GetAppointment';
  adHocService?: Maybe<Scalars['Json']['output']>;
  answers?: Maybe<Scalars['Json']['output']>;
  business?: Maybe<Scalars['Json']['output']>;
  clientTimezone?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  found?: Maybe<Scalars['Boolean']['output']>;
  hangoutLink?: Maybe<Scalars['String']['output']>;
  hasMoreOccurrences?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  noOcurrences?: Maybe<Scalars['Boolean']['output']>;
  originalDate?: Maybe<Scalars['DateTime']['output']>;
  patient?: Maybe<Scalars['Json']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service?: Maybe<Scalars['Json']['output']>;
  staff?: Maybe<Scalars['Json']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  tokenError?: Maybe<Scalars['Boolean']['output']>;
};

export type Guest = {
  __typename?: 'Guest';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phoneMobile?: Maybe<Scalars['String']['output']>;
};

export type ImportedPatient = {
  __typename?: 'ImportedPatient';
  email?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notValidReason?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatePatientId?: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDiscounted?: Maybe<Scalars['Int']['output']>;
  amountExcludingTax?: Maybe<Scalars['Int']['output']>;
  amountOutstanding?: Maybe<Scalars['Int']['output']>;
  amountSubtotal?: Maybe<Scalars['Int']['output']>;
  amountTax?: Maybe<Scalars['Int']['output']>;
  amountTotal?: Maybe<Scalars['Int']['output']>;
  appointment?: Maybe<Appointment>;
  business: Business;
  coupon?: Maybe<Coupon>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoicePdfUrl?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<InvoiceItem>>;
  markedUncollectibleAt?: Maybe<Scalars['DateTime']['output']>;
  markedUncollectibleBy?: Maybe<Staff>;
  number?: Maybe<Scalars['String']['output']>;
  patient: Patient;
  paymentPageUrl?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<InvoiceStatus>;
  updatedAt: Scalars['DateTime']['output'];
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
  voidedBy?: Maybe<Staff>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  quantity?: Maybe<Scalars['Int']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const InvoiceStatus = {
  Draft: 'DRAFT',
  Open: 'OPEN',
  Paid: 'PAID',
  Uncollectible: 'UNCOLLECTIBLE',
  Void: 'VOID'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];
export type LeastBusyStaff = {
  __typename?: 'LeastBusyStaff';
  id?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  googlePlaceAddress?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  googlePlaceImageUrl?: Maybe<Scalars['String']['output']>;
  googlePlaceMapUrl?: Maybe<Scalars['String']['output']>;
  googlePlaceName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<File>;
  services?: Maybe<Array<Service>>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workingHours?: Maybe<Scalars['Json']['output']>;
};

export const LocationType = {
  FreeformText: 'FREEFORM_TEXT',
  GoogleHangoutsUrl: 'GOOGLE_HANGOUTS_URL',
  GoogleMapsAddress: 'GOOGLE_MAPS_ADDRESS',
  None: 'NONE',
  SetByPatient: 'SET_BY_PATIENT',
  SetByPatientGoogleMapsAddress: 'SET_BY_PATIENT_GOOGLE_MAPS_ADDRESS',
  SetByPatientVirtual: 'SET_BY_PATIENT_VIRTUAL',
  Telehealth: 'TELEHEALTH',
  Url: 'URL'
} as const;

export type LocationType = typeof LocationType[keyof typeof LocationType];
export type LocationWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Log = {
  __typename?: 'Log';
  changes: Scalars['JsonArray']['output'];
  createdAt: Scalars['DateTime']['output'];
  direction: Scalars['String']['output'];
  errors: Scalars['JsonArray']['output'];
  id: Scalars['ID']['output'];
  inboundGoogleError: Scalars['Json']['output'];
  inboundGoogleHasChanges: Scalars['Boolean']['output'];
  inboundGoogleLog: Scalars['Json']['output'];
  inboundOutlookError: Scalars['Json']['output'];
  inboundOutlookHasChanges: Scalars['Boolean']['output'];
  inboundOutlookLog: Scalars['Json']['output'];
  service: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Message = {
  __typename?: 'Message';
  attachments: Array<MessageAttachment>;
  business: Business;
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  patientReadAt?: Maybe<Scalars['Date']['output']>;
  readAt?: Maybe<Scalars['Date']['output']>;
  sentAt?: Maybe<Scalars['Date']['output']>;
  sentBy: MessageSender;
  staff?: Maybe<Staff>;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  attachmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  type: MessageAttachmentType;
  url?: Maybe<Scalars['String']['output']>;
};

export type MessageAttachmentInput = {
  id: Scalars['String']['input'];
  type: MessageAttachmentType;
};

export const MessageAttachmentType = {
  Form: 'FORM',
  Invoice: 'INVOICE'
} as const;

export type MessageAttachmentType = typeof MessageAttachmentType[keyof typeof MessageAttachmentType];
export const MessageRecipient = {
  BusinessOwner: 'BUSINESS_OWNER',
  Guests: 'GUESTS',
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type MessageRecipient = typeof MessageRecipient[keyof typeof MessageRecipient];
export const MessageSender = {
  Business: 'BUSINESS',
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type MessageSender = typeof MessageSender[keyof typeof MessageSender];
export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageTemplatesResponse = {
  __typename?: 'MessageTemplatesResponse';
  templates: Array<MessageTemplate>;
  totalCount: Scalars['Int']['output'];
};

export type MessageThread = {
  __typename?: 'MessageThread';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  hasDraftMessage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastMessageSentAt?: Maybe<Scalars['DateTime']['output']>;
  messagesCount: Scalars['Int']['output'];
  patient: Patient;
  patientArchivedAt?: Maybe<Scalars['DateTime']['output']>;
  staffs: Array<Staff>;
  subject?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageThreadsResponse = {
  __typename?: 'MessageThreadsResponse';
  threads: Array<MessageThread>;
  totalCount: Scalars['Int']['output'];
};

export const MessageType = {
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];
export type Mutation = {
  __typename?: 'Mutation';
  _dbQueryCount?: Maybe<Scalars['Int']['output']>;
  _dbQueryDuration?: Maybe<Scalars['Int']['output']>;
  _dbQueryLogs?: Maybe<Scalars['Json']['output']>;
  _dbQueryMetrics?: Maybe<Scalars['Json']['output']>;
  addInvoiceItem: Invoice;
  approveAppointment?: Maybe<Appointment>;
  archiveMessageThread: MessageThread;
  archivePatient?: Maybe<Patient>;
  archiveStaff?: Maybe<Staff>;
  assignStaffStripeProductIds: Staff;
  attachPaymentToAppointment?: Maybe<BookAppointment>;
  authenticateUser: AuthPayload;
  bookAdHocAppointment?: Maybe<BookAppointment>;
  bookAppointment?: Maybe<BookAppointment>;
  cancelAppointment?: Maybe<CancelAppointment>;
  cancelGroupBooking: Array<Appointment>;
  completeStripeConnectAccountOnboarding: StripeConnectAccount;
  createAdHocAppointment: Appointment;
  createAdHocService: AdHocService;
  createApiKey: ApiKey;
  createAppointment?: Maybe<Appointment>;
  createBookingEventNotificationTemplate?: Maybe<BookingEventNotificationTemplate>;
  createBusiness: Business;
  createCoupon: Coupon;
  createCustomField: CustomField;
  createDataImport: DataImport;
  createDraftInvoice: Invoice;
  createForm: Form;
  createFormSubmission: CreateFormSubmissionPayload;
  createLocation?: Maybe<Location>;
  createMessageTemplate: MessageTemplate;
  createMessageThread: MessageThread;
  createNote: Note;
  createNoteTemplate: NoteTemplate;
  createOutgoingWebhook: OutgoingWebhook;
  createPatient?: Maybe<Patient>;
  createPatientBusiness: Business;
  createPostAppointmentMessageTemplate?: Maybe<PostAppointmentMessageTemplate>;
  createReminderTemplate?: Maybe<ReminderTemplate>;
  createService?: Maybe<Service>;
  createServiceCategory?: Maybe<ServiceCategory>;
  createServiceStripePaymentIntent?: Maybe<Scalars['Json']['output']>;
  createStaff?: Maybe<Staff>;
  createStripeBillingPortalSession: StripeBillingPortalSession;
  createStripeCheckoutSession: StripeCheckoutSession;
  createStripeConnectAccount: StripeConnectAccountCreatePayload;
  createStripeConnectAccountSession?: Maybe<StripeConnectAccountSession>;
  createStripeSetupIntent: StripeSetupIntent;
  createSupportIdentificationToken: SupportIdentificationToken;
  createTag: Tag;
  createThirdPartyConnectionOAuthSession: ThirdPartyConnectionOAuthSession;
  createThread: MessageThread;
  createTimeBlocker?: Maybe<TimeBlocker>;
  createWorkingHoursSchedule?: Maybe<WorkingHoursSchedule>;
  defaultToBusinessWorkingHours?: Maybe<Scalars['Boolean']['output']>;
  deleteApiKey: Scalars['Boolean']['output'];
  deleteAppointment?: Maybe<Appointment>;
  deleteBusiness?: Maybe<Business>;
  deleteCoupon: Scalars['Boolean']['output'];
  deleteDraftInvoice: Scalars['Boolean']['output'];
  deleteForm: Scalars['Boolean']['output'];
  deleteFormSubmission: Scalars['Boolean']['output'];
  deleteInvoiceItem: Invoice;
  deleteLocation?: Maybe<Scalars['Boolean']['output']>;
  deleteMessageTemplate: Scalars['Boolean']['output'];
  deleteNote: Scalars['Boolean']['output'];
  deleteNoteTemplate: Scalars['Boolean']['output'];
  deleteOutgoingWebhook: Scalars['Boolean']['output'];
  deletePatientCustomFieldValue: Scalars['Boolean']['output'];
  deleteServiceCategory?: Maybe<ServiceCategory>;
  deleteStaff?: Maybe<Staff>;
  deleteThirdPartyConnection?: Maybe<Scalars['Boolean']['output']>;
  deleteTimeBlocker?: Maybe<TimeBlocker>;
  discardFormDraft?: Maybe<Scalars['Boolean']['output']>;
  disconnectCalendarConnection: Scalars['ID']['output'];
  disconnectStripeAccount?: Maybe<Business>;
  duplicateForm: Form;
  duplicateService?: Maybe<Service>;
  enablePatientBilling: Patient;
  finalizeInvoice: Invoice;
  getSignedUploadUrl: SignedUploadParams;
  invalidateApiKey: ApiKey;
  optIn?: Maybe<Scalars['Boolean']['output']>;
  publishForm: Form;
  purchaseProduct: User;
  readMessage: Message;
  renameForm: Form;
  requestAccountDeletion?: Maybe<DataRequest>;
  requestExport?: Maybe<Scalars['Boolean']['output']>;
  rescheduleAppointment?: Maybe<RescheduleAppointment>;
  resetBusinessBookingForm?: Maybe<Scalars['Boolean']['output']>;
  restoreMessageThread: MessageThread;
  restorePatient?: Maybe<Patient>;
  restoreStaff?: Maybe<Staff>;
  sendInvoice: Scalars['Boolean']['output'];
  sendMessage: Message;
  sendPatientIntakeInvite: Scalars['Boolean']['output'];
  sendPatientInvite: Scalars['Boolean']['output'];
  sendPatientLoginEmail: Scalars['Boolean']['output'];
  sendReminders?: Maybe<SendRemindersPayload>;
  sendSignupEmail?: Maybe<Scalars['Boolean']['output']>;
  setAppointmentTags: Appointment;
  setPatientCustomFieldValue: CustomFieldValue;
  setPatientTags: Patient;
  syncStripeCustomer: SyncStripeCustomerPayload;
  unreadMessage: Message;
  updateAppointment?: Maybe<Appointment>;
  updateBookingEventNotificationTemplate?: Maybe<BookingEventNotificationTemplate>;
  updateBusiness?: Maybe<Business>;
  updateCalendarConnection?: Maybe<CalendarConnection>;
  updateCoupon: Coupon;
  updateCustomField: CustomField;
  updateDraftInvoice: Invoice;
  updateFormDraft: Form;
  updateFormSubmission: FormSubmission;
  updateGroupBooking: Array<Appointment>;
  updateInvoiceItem: Invoice;
  updateLocation?: Maybe<Location>;
  updateMessageTemplate: MessageTemplate;
  updateNote: Note;
  updateNoteTemplate: NoteTemplate;
  updateNotification?: Maybe<Notification>;
  updateOutgoingWebhook: OutgoingWebhook;
  updatePatient?: Maybe<Patient>;
  updatePostAppointmentMessageTemplate?: Maybe<PostAppointmentMessageTemplate>;
  updateReminderTemplate?: Maybe<ReminderTemplate>;
  updateSeats: UpdateSeatsResponse;
  updateService?: Maybe<Service>;
  updateServiceCategory?: Maybe<ServiceCategory>;
  updateStaff?: Maybe<Staff>;
  updateStaffColours?: Maybe<Business>;
  updateStaffRoles?: Maybe<Staff>;
  updateTag: Tag;
  updateThirdPartyConnection: ThirdPartyConnection;
  updateThread: MessageThread;
  updateUser?: Maybe<User>;
  updateUserPersonalData: User;
  uploadFile?: Maybe<File>;
  upsertMessageDraft?: Maybe<Message>;
  voidInvoice: Invoice;
};


export type MutationAddInvoiceItemArgs = {
  amount: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  invoiceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationApproveAppointmentArgs = {
  id: Scalars['ID']['input'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationArchiveMessageThreadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchivePatientArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationArchiveStaffArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAssignStaffStripeProductIdsArgs = {
  productIds: Array<Scalars['String']['input']>;
  staffId: Scalars['ID']['input'];
};


export type MutationAttachPaymentToAppointmentArgs = {
  id: Scalars['ID']['input'];
  paymentId: Scalars['String']['input'];
  paymentProvider: Scalars['String']['input'];
  token: Scalars['String']['input'];
  transactionUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAuthenticateUserArgs = {
  auth0Code: Scalars['String']['input'];
  hubspotCookie?: InputMaybe<Scalars['String']['input']>;
  signupKey?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBookAdHocAppointmentArgs = {
  businessId: Scalars['ID']['input'];
  clientTimezone?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  patientEmail?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patientPhone?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  requiresApproval?: InputMaybe<Scalars['Boolean']['input']>;
  rescheduleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceName: Scalars['String']['input'];
  staffId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBookAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']['input']>;
  clientTimezone?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  guests?: InputMaybe<Array<CreateGuestInput>>;
  isCustomTime?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  patientEmail?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patientPhone?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider?: InputMaybe<Scalars['String']['input']>;
  requestTimeMessage?: InputMaybe<Scalars['String']['input']>;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
  staffId?: InputMaybe<Scalars['ID']['input']>;
  transactionUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelAppointmentArgs = {
  cancelFutureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  occurrenceDate?: InputMaybe<Scalars['DateTime']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelGroupBookingArgs = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  groupBookingId: Scalars['String']['input'];
};


export type MutationCompleteStripeConnectAccountOnboardingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateAdHocAppointmentArgs = {
  end: Scalars['DateTime']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
  start: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateAdHocServiceArgs = {
  businessId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  maxAppointments: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  staffId: Scalars['ID']['input'];
  timeIncrement: Scalars['Int']['input'];
  timeslots: Scalars['Json']['input'];
};


export type MutationCreateApiKeyArgs = {
  description: Scalars['String']['input'];
};


export type MutationCreateAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']['input']>;
  clientTimezone?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
  patient: PatientCreateOneWithoutAppointmentsInput;
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
};


export type MutationCreateBookingEventNotificationTemplateArgs = {
  businessId: Scalars['ID']['input'];
  event: BookingEvent;
  recipient: MessageRecipient;
  services: Array<Scalars['ID']['input']>;
  type: MessageType;
};


export type MutationCreateBusinessArgs = {
  alias: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationCreateCouponArgs = {
  amountOff?: InputMaybe<Scalars['Int']['input']>;
  code: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  percentOff?: InputMaybe<Scalars['Float']['input']>;
  type: Scalars['String']['input'];
};


export type MutationCreateCustomFieldArgs = {
  alias: Scalars['String']['input'];
  allowedValues?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['String']['input'];
  fieldType: CustomFieldType;
  name: Scalars['String']['input'];
};


export type MutationCreateDataImportArgs = {
  columnsMap: Scalars['Json']['input'];
  fileId: Scalars['ID']['input'];
  resourceType: Scalars['String']['input'];
  rowsCount: Scalars['Int']['input'];
};


export type MutationCreateDraftInvoiceArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};


export type MutationCreateFormArgs = {
  formTemplateId?: InputMaybe<Scalars['ID']['input']>;
  steps?: InputMaybe<Scalars['Json']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateFormSubmissionArgs = {
  answers: Scalars['Json']['input'];
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  formId: Scalars['ID']['input'];
  requestUrl: Scalars['String']['input'];
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationCreateLocationArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceName?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationCreateMessageTemplateArgs = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateMessageThreadArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  content: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};


export type MutationCreateNoteArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  templateId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateNoteTemplateArgs = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateOutgoingWebhookArgs = {
  businessId: Scalars['ID']['input'];
  endpoint: Scalars['String']['input'];
};


export type MutationCreatePatientArgs = {
  businessId: Scalars['ID']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  primaryStaffId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePatientBusinessArgs = {
  currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  patientUserId: Scalars['ID']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationCreatePostAppointmentMessageTemplateArgs = {
  businessId: Scalars['ID']['input'];
  minutes: Scalars['Int']['input'];
  recipient: MessageRecipient;
  services: Array<Scalars['ID']['input']>;
  type: MessageType;
};


export type MutationCreateReminderTemplateArgs = {
  businessId: Scalars['ID']['input'];
  minutes: Scalars['Int']['input'];
  resourceType: ReminderResourceType;
  services: Array<Scalars['ID']['input']>;
  type: ReminderType;
};


export type MutationCreateServiceArgs = {
  additionalStaffIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowGuests?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']['input']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']['input']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']['input']>;
  businessId: Scalars['ID']['input'];
  cancelLeadTime?: InputMaybe<Scalars['Int']['input']>;
  categoriesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  durations: Scalars['Json']['input'];
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']['input']>;
  leadTime?: InputMaybe<Scalars['Int']['input']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  recurrence?: InputMaybe<Scalars['Json']['input']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']['input']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']['input']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationCreateServiceCategoryArgs = {
  businessId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateServiceStripePaymentIntentArgs = {
  duration: Scalars['Int']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateStaffArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  roles?: InputMaybe<Array<StaffRole>>;
  stripeProductIds?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationCreateStripeBillingPortalSessionArgs = {
  flow: Scalars['String']['input'];
};


export type MutationCreateStripeConnectAccountArgs = {
  country: Scalars['String']['input'];
};


export type MutationCreateStripeSetupIntentArgs = {
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  interval: StripeInterval;
  productQuantities: Scalars['Json']['input'];
};


export type MutationCreateSupportIdentificationTokenArgs = {
  provider: Scalars['String']['input'];
};


export type MutationCreateTagArgs = {
  alias: Scalars['String']['input'];
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateThirdPartyConnectionOAuthSessionArgs = {
  provider: ThirdPartyConnectionProvider;
  redirectUrl: Scalars['String']['input'];
  resourceType: ThirdPartyConnectionResourceType;
};


export type MutationCreateThreadArgs = {
  patientId: Scalars['ID']['input'];
};


export type MutationCreateTimeBlockerArgs = {
  endDate: Scalars['DateTime']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['DateTime']['input'];
};


export type MutationCreateWorkingHoursScheduleArgs = {
  from: Scalars['DateTime']['input'];
  staffId: Scalars['ID']['input'];
  to?: InputMaybe<Scalars['DateTime']['input']>;
  workingHours: Scalars['Json']['input'];
};


export type MutationDefaultToBusinessWorkingHoursArgs = {
  staffId: Scalars['ID']['input'];
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAppointmentArgs = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  futureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  occurrenceDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationDeleteBusinessArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDraftInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceItemArgs = {
  invoiceId: Scalars['ID']['input'];
  invoiceItemId: Scalars['ID']['input'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNoteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOutgoingWebhookArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePatientCustomFieldValueArgs = {
  fieldKey: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};


export type MutationDeleteServiceCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStaffArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteThirdPartyConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTimeBlockerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDiscardFormDraftArgs = {
  formId: Scalars['ID']['input'];
};


export type MutationDisconnectCalendarConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisconnectStripeAccountArgs = {
  businessId: Scalars['ID']['input'];
};


export type MutationDuplicateFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDuplicateServiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEnablePatientBillingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFinalizeInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationGetSignedUploadUrlArgs = {
  acl: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};


export type MutationInvalidateApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationOptInArgs = {
  feature: Scalars['String']['input'];
};


export type MutationPublishFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPurchaseProductArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};


export type MutationReadMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRenameFormArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  thumbnail?: InputMaybe<Scalars['Json']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useAsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRequestAccountDeletionArgs = {
  email: Scalars['String']['input'];
};


export type MutationRequestExportArgs = {
  businessId: Scalars['ID']['input'];
  resourcesToExport?: InputMaybe<ExportableResource>;
};


export type MutationRescheduleAppointmentArgs = {
  clientTimezone?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  occurrenceDate?: InputMaybe<Scalars['DateTime']['input']>;
  rescheduleFutureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRestoreMessageThreadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestorePatientArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreStaffArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendInvoiceArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  invoiceId: Scalars['ID']['input'];
};


export type MutationSendMessageArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  content: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
};


export type MutationSendPatientIntakeInviteArgs = {
  body: Scalars['String']['input'];
  formList: Array<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};


export type MutationSendPatientInviteArgs = {
  body: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};


export type MutationSendPatientLoginEmailArgs = {
  businessId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type MutationSendSignupEmailArgs = {
  patientId: Scalars['String']['input'];
};


export type MutationSetAppointmentTagsArgs = {
  appointmentId: Scalars['ID']['input'];
  tagIds: Array<Scalars['String']['input']>;
};


export type MutationSetPatientCustomFieldValueArgs = {
  fieldKey: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type MutationSetPatientTagsArgs = {
  patientId: Scalars['ID']['input'];
  tagIds: Array<Scalars['String']['input']>;
};


export type MutationUnreadMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  futureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
  originalDate?: InputMaybe<Scalars['DateTime']['input']>;
  patient?: InputMaybe<PatientCreateOneWithoutAppointmentsInput>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  singleOccurrence?: InputMaybe<Scalars['Boolean']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateBookingEventNotificationTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateBusinessArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  allowChoosingStaff?: InputMaybe<Scalars['Boolean']['input']>;
  allowNoStaffPreference?: InputMaybe<Scalars['Boolean']['input']>;
  allowStaffApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  allowTimeRequests?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  attachICSFiles?: InputMaybe<Scalars['Boolean']['input']>;
  autoGenerateBookingInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  bookingFormLegacyConfig?: InputMaybe<Scalars['Json']['input']>;
  bookingLocale?: InputMaybe<Scalars['String']['input']>;
  bookingNotificationsReplyTo?: InputMaybe<NotificationsReplyToType>;
  bookingTemplates?: InputMaybe<Scalars['Json']['input']>;
  bookingsRequireApprovalUntilPaid?: InputMaybe<Scalars['Boolean']['input']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']['input']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']['input']>;
  calendarView?: InputMaybe<CalendarView>;
  color?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactUrl?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  dateFormat?: InputMaybe<Scalars['Json']['input']>;
  defaultInvoiceDaysUntilDue?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportedEventsTemplate?: InputMaybe<Scalars['Json']['input']>;
  forceServiceStep?: InputMaybe<Scalars['Boolean']['input']>;
  googleTrackingId?: InputMaybe<Scalars['String']['input']>;
  hidePatientTimezoneDropdwon?: InputMaybe<Scalars['Boolean']['input']>;
  hidePrivacyButton?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  includeAnswersInExportedEvents?: InputMaybe<Scalars['Boolean']['input']>;
  includeAnswersOnRedirect?: InputMaybe<Scalars['Boolean']['input']>;
  invoicesTaxBehaviour?: InputMaybe<TaxBehaviour>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  logoPosition?: InputMaybe<Scalars['String']['input']>;
  marketingMeta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSettings?: InputMaybe<Scalars['Json']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  ownerNotificationsCC?: InputMaybe<Scalars['String']['input']>;
  ownerNotificationsRecipient?: InputMaybe<OwnerNotificationsRecipient>;
  physicalAddress?: InputMaybe<Scalars['String']['input']>;
  postOnRedirect?: InputMaybe<Scalars['Boolean']['input']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  redirectAfterBookingUrl?: InputMaybe<Scalars['String']['input']>;
  reminderPatientEmail?: InputMaybe<Scalars['Int']['input']>;
  reminderPatientSMS?: InputMaybe<Scalars['Int']['input']>;
  reminderStaffEmail?: InputMaybe<Scalars['Int']['input']>;
  reminderStaffSMS?: InputMaybe<Scalars['Int']['input']>;
  sendSMSFromStaffNumber?: InputMaybe<Scalars['Boolean']['input']>;
  servicesColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  servicesOrder?: InputMaybe<Array<Scalars['String']['input']>>;
  showCalendarEventsNames?: InputMaybe<Scalars['Boolean']['input']>;
  showDropdownFilter?: InputMaybe<Scalars['Boolean']['input']>;
  staffsColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  templates?: InputMaybe<Scalars['Json']['input']>;
  termsOfServiceUrl?: InputMaybe<Scalars['String']['input']>;
  timeRequestButtonLabel?: InputMaybe<Scalars['String']['input']>;
  timeRequestText?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trackingPixelUrl?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  websiteContainingEmbedCode?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateCalendarConnectionArgs = {
  allDayEventsTimezone?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportAttendees?: InputMaybe<Array<CalendarEventAttendee>>;
  exportToCalendarId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importFromCalendarsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationUpdateCouponArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateCustomFieldArgs = {
  allowedValues?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDraftInvoiceArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceId: Scalars['ID']['input'];
};


export type MutationUpdateFormDraftArgs = {
  formId: Scalars['ID']['input'];
  settings?: InputMaybe<Scalars['Json']['input']>;
  steps?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateFormSubmissionArgs = {
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};


export type MutationUpdateGroupBookingArgs = {
  date: Scalars['DateTime']['input'];
  groupBookingId: Scalars['String']['input'];
};


export type MutationUpdateInvoiceItemArgs = {
  amount: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  invoiceId: Scalars['ID']['input'];
  invoiceItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationUpdateLocationArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateMessageTemplateArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNoteArgs = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNoteTemplateArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNotificationArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  read?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateOutgoingWebhookArgs = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdatePatientArgs = {
  billingAddress?: InputMaybe<AddressInput>;
  biologicalSex?: InputMaybe<BiologicalSex>;
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  patientType?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  primaryStaffId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePostAppointmentMessageTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateReminderTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateSeatsArgs = {
  interval: StripeInterval;
  productQuantities: Scalars['Json']['input'];
};


export type MutationUpdateServiceArgs = {
  additionalStaffIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  alias?: InputMaybe<Scalars['String']['input']>;
  allowGuests?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  bookingPageVisibility?: InputMaybe<Scalars['String']['input']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']['input']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']['input']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']['input']>;
  cancelLeadTime?: InputMaybe<Scalars['Int']['input']>;
  categoriesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  durations?: InputMaybe<Scalars['Json']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  leadTime?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  overrideStaffVideoConferenceProvider?: InputMaybe<Scalars['Boolean']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  recurrence?: InputMaybe<Scalars['Json']['input']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']['input']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']['input']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
  videoConferenceProvider?: InputMaybe<VideoConferenceProvider>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateServiceCategoryArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateStaffArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  managedStaff?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketingMeta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  resendInvite?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<StaffRole>>;
  signature?: InputMaybe<Scalars['Json']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  videoConferenceProvider?: InputMaybe<VideoConferenceProvider>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateStaffColoursArgs = {
  businessId: Scalars['ID']['input'];
  staffColours: Scalars['Json']['input'];
};


export type MutationUpdateStaffRolesArgs = {
  id: Scalars['ID']['input'];
  roles: Array<StaffRole>;
};


export type MutationUpdateTagArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateThirdPartyConnectionArgs = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateThreadArgs = {
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  signature?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationUpdateUserPersonalDataArgs = {
  name: Scalars['String']['input'];
};


export type MutationUploadFileArgs = {
  acl?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  s3Key: Scalars['String']['input'];
};


export type MutationUpsertMessageDraftArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  content: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
};


export type MutationVoidInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  appointment?: Maybe<Appointment>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  staff: Staff;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NoteTemplate = {
  __typename?: 'NoteTemplate';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  business: Business;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type NotificationsMetaPayload = {
  __typename?: 'NotificationsMetaPayload';
  count?: Maybe<Scalars['Int']['output']>;
};

export const NotificationsReplyToType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type NotificationsReplyToType = typeof NotificationsReplyToType[keyof typeof NotificationsReplyToType];
export type OAuth2CallbackPayload = {
  __typename?: 'OAuth2CallbackPayload';
  configureUrl?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OAuthToken = {
  __typename?: 'OAuthToken';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invalidatedAt?: Maybe<Scalars['DateTime']['output']>;
  provider: OAuthTokenProvider;
  refreshToken: Scalars['String']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  tokenType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export const OAuthTokenProvider = {
  Google: 'GOOGLE',
  Microsoft: 'MICROSOFT'
} as const;

export type OAuthTokenProvider = typeof OAuthTokenProvider[keyof typeof OAuthTokenProvider];
export type OAuthUrlsPayload = {
  __typename?: 'OAuthUrlsPayload';
  google?: Maybe<Scalars['String']['output']>;
  microsoft?: Maybe<Scalars['String']['output']>;
};

export type OutgoingWebhook = {
  __typename?: 'OutgoingWebhook';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endpoint: Scalars['String']['output'];
  events: Array<Event>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type OutgoingWebhookEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type OutgoingWebhookDelivery = {
  __typename?: 'OutgoingWebhookDelivery';
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  event: Event;
  id: Scalars['ID']['output'];
  outgoingWebhook: OutgoingWebhook;
  requestHeaders: Scalars['Json']['output'];
  requestPayload: Scalars['Json']['output'];
  responseHeaders?: Maybe<Scalars['Json']['output']>;
  responsePayload?: Maybe<Scalars['Json']['output']>;
  responseStatus?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const OwnerNotificationsRecipient = {
  Business: 'BUSINESS',
  Owner: 'OWNER'
} as const;

export type OwnerNotificationsRecipient = typeof OwnerNotificationsRecipient[keyof typeof OwnerNotificationsRecipient];
export type Patient = {
  __typename?: 'Patient';
  age?: Maybe<Scalars['Int']['output']>;
  appointments?: Maybe<Array<Appointment>>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivedReason?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Address>;
  biologicalSex?: Maybe<BiologicalSex>;
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customFieldValues: Array<CustomFieldValue>;
  dob?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lastIntakeInviteSentAt?: Maybe<Scalars['DateTime']['output']>;
  lastInvitedToPortalAt?: Maybe<Scalars['DateTime']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  patientType?: Maybe<PatientType>;
  phoneMobile?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  primaryStaff?: Maybe<Staff>;
  selfDeclaredDiabeticType?: Maybe<DiabeticType>;
  selfDeclaredHypertension?: Maybe<Scalars['Boolean']['output']>;
  selfDeclaredIsSmoker?: Maybe<Scalars['Boolean']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  tagIds: Array<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type PatientCreateOneWithoutAppointmentsInput = {
  connect?: InputMaybe<PatientWhereUniqueInput>;
  create?: InputMaybe<PatientCreateWithoutAppointmentsInput>;
};

export type PatientCreateWithoutAppointmentsInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
};

export const PatientStatus = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED'
} as const;

export type PatientStatus = typeof PatientStatus[keyof typeof PatientStatus];
export const PatientType = {
  Adolescent: 'ADOLESCENT',
  Adult: 'ADULT',
  Child: 'CHILD',
  Couple: 'COUPLE',
  Infant: 'INFANT'
} as const;

export type PatientType = typeof PatientType[keyof typeof PatientType];
export type PatientWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientsMetaPayload = {
  __typename?: 'PatientsMetaPayload';
  count?: Maybe<Scalars['Int']['output']>;
};

export const PatientsOrderByInput = {
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC'
} as const;

export type PatientsOrderByInput = typeof PatientsOrderByInput[keyof typeof PatientsOrderByInput];
export type Picture = {
  __typename?: 'Picture';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};


export type PictureUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PostAppointmentMessage = {
  __typename?: 'PostAppointmentMessage';
  appointment: Appointment;
  appointmentDate?: Maybe<Scalars['DateTime']['output']>;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  postAppointmentMessageTemplate: PostAppointmentMessageTemplate;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  staff?: Maybe<Staff>;
};

export type PostAppointmentMessageTemplate = {
  __typename?: 'PostAppointmentMessageTemplate';
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  defaultTemplate?: Maybe<Scalars['Json']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  messageTemplate?: Maybe<Scalars['Json']['output']>;
  minutes: Scalars['Int']['output'];
  postAppointmentMessages: Array<PostAppointmentMessage>;
  recipient: MessageRecipient;
  services: Array<Service>;
  type: MessageType;
  updatedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  Appointment?: Maybe<Appointment>;
  Business?: Maybe<Business>;
  DataImport: DataImport;
  GroupBookingAppointments?: Maybe<Array<Appointment>>;
  Invoice: Invoice;
  Location?: Maybe<Location>;
  Patient: Patient;
  Service?: Maybe<Service>;
  Staff?: Maybe<Staff>;
  Tag?: Maybe<Tag>;
  _dbQueryCount?: Maybe<Scalars['Int']['output']>;
  _dbQueryDuration?: Maybe<Scalars['Int']['output']>;
  _dbQueryLogs?: Maybe<Scalars['Json']['output']>;
  _dbQueryMetrics?: Maybe<Scalars['Json']['output']>;
  _synchronizationLogsCount: Scalars['Int']['output'];
  adHocAvailableTimesUTC?: Maybe<AvailableTimes>;
  adhocServices: Array<AdHocService>;
  adhocServicesCount: Scalars['Int']['output'];
  allApiKeys: Array<ApiKey>;
  allCoupons: Array<Coupon>;
  allCustomFields: Array<CustomField>;
  allDataImports?: Maybe<Array<DataImport>>;
  allPatientFiles: Array<File>;
  allReminderTemplates: Array<ReminderTemplate>;
  allTags: Array<Tag>;
  allTimeBlockers: Array<TimeBlocker>;
  analytics?: Maybe<Analytics>;
  availableFeatures: Array<Scalars['String']['output']>;
  availableTimes?: Maybe<AvailableTimes>;
  availableTimesUTC?: Maybe<AvailableTimes>;
  businessBookingEventNotificationTemplates: Array<BookingEventNotificationTemplate>;
  businessBookingForm: Form;
  businessByAppointment?: Maybe<Business>;
  businessLocations: Array<Location>;
  businessLocationsCount: Scalars['Int']['output'];
  businessNotifications: Array<Notification>;
  businessNotificationsCount: Scalars['Int']['output'];
  businessPatients: Array<Patient>;
  businessPatientsCount: Scalars['Int']['output'];
  businessPostAppointmentMessageTemplates: Array<PostAppointmentMessageTemplate>;
  businessRoleConfigurations: Array<RoleConfiguration>;
  businessServices?: Maybe<Array<Service>>;
  businessServicesCount: Scalars['Int']['output'];
  businessStaffs: Array<Staff>;
  calendarConnection?: Maybe<CalendarConnection>;
  calendarConnections: Array<CalendarConnection>;
  calendarViewAppointments: Array<Appointment>;
  calendarViewExternalEvents: Array<CalendarViewExternalEventPayload>;
  currentBusiness: Business;
  currentStaff: Staff;
  currentUser?: Maybe<User>;
  defaultBookingTemplates?: Maybe<Scalars['Json']['output']>;
  defaultNotificationsTemplates?: Maybe<DefaultNotificationsTemplatesPayload>;
  downloadFile: DownloadFilePayload;
  faceVitalsScanResult?: Maybe<FaceVitalsScanResult>;
  faceVitalsScanResults: Array<FaceVitalsScanResult>;
  form: Form;
  formSubmission: FormSubmission;
  formSubmissionAttachmentURL?: Maybe<Scalars['String']['output']>;
  formSubmissions: FormSubmissionsResponse;
  formTemplates: Array<FormTemplate>;
  forms: Array<Form>;
  formsCount: Scalars['Int']['output'];
  getAppointment?: Maybe<GetAppointment>;
  getServiceTimezone: Scalars['String']['output'];
  leastBusyStaff?: Maybe<LeastBusyStaff>;
  logs?: Maybe<Array<Log>>;
  managedStaffs: Array<Staff>;
  managedStaffsCount: Scalars['Int']['output'];
  messageDraft?: Maybe<Message>;
  messageTemplate: MessageTemplate;
  messageTemplates: MessageTemplatesResponse;
  note?: Maybe<Note>;
  noteTemplate?: Maybe<NoteTemplate>;
  noteTemplates: Array<NoteTemplate>;
  noteTemplatesCount: Scalars['Int']['output'];
  oAuthUrls?: Maybe<OAuthUrlsPayload>;
  otherStaffCalendarConnections: Array<CalendarConnection>;
  outgoingWebhook?: Maybe<OutgoingWebhook>;
  outgoingWebhooks: Array<OutgoingWebhook>;
  patientInvoices: Array<Invoice>;
  patientInvoicesCount: Scalars['Int']['output'];
  patientNotes: Array<Note>;
  patientNotesCount: Scalars['Int']['output'];
  patientThreads: MessageThreadsResponse;
  recurringOccurrences?: Maybe<RecurrenceOccurrencesPayload>;
  staffCalendarConnections: Array<CalendarConnection>;
  staffIsAvailable: Scalars['Boolean']['output'];
  staffOAuthUrls?: Maybe<OAuthUrlsPayload>;
  staffTeamsOAuthUrl: Scalars['String']['output'];
  staffThreads: MessageThreadsResponse;
  staffsById: Array<Staff>;
  synchronizationLogs: Array<SynchronizationLog>;
  teamsOAuthUrl: Scalars['String']['output'];
  thirdPartyConnections: Array<ThirdPartyConnection>;
  thread: MessageThread;
  threadMessages: Array<Message>;
  /** @deprecated Use currentStaff */
  userStaff?: Maybe<Staff>;
  workingHoursSlots: WorkingHoursSlots;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBusinessArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDataImportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupBookingAppointmentsArgs = {
  groupBookingId: Scalars['ID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStaffArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTagArgs = {
  id: Scalars['ID']['input'];
};


export type Query_SynchronizationLogsCountArgs = {
  connectionId: Scalars['ID']['input'];
};


export type QueryAdHocAvailableTimesUtcArgs = {
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  businessId: Scalars['ID']['input'];
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  minimumNoticeTime?: InputMaybe<Scalars['Int']['input']>;
  staffIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdhocServicesArgs = {
  businessId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdhocServicesCountArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAllPatientFilesArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryAllReminderTemplatesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAllTimeBlockersArgs = {
  dateEnd: Scalars['DateTime']['input'];
  dateStart: Scalars['DateTime']['input'];
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryAvailableFeaturesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAvailableTimesArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  filterStaffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ignoreAppointmentId?: InputMaybe<Scalars['ID']['input']>;
  includeDisabledStaffId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type QueryAvailableTimesUtcArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  filterStaffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ignoreBufferTimes?: InputMaybe<Scalars['Boolean']['input']>;
  includeDisabledStaffId?: InputMaybe<Scalars['ID']['input']>;
  reschedulingId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type QueryBusinessBookingEventNotificationTemplatesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryBusinessBookingFormArgs = {
  adHocServiceId?: InputMaybe<Scalars['ID']['input']>;
  businessId: Scalars['ID']['input'];
  serviceCategoryId?: InputMaybe<Scalars['ID']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryBusinessByAppointmentArgs = {
  appointmentId: Scalars['ID']['input'];
};


export type QueryBusinessLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBusinessLocationsCountArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBusinessNotificationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
};


export type QueryBusinessNotificationsCountArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryBusinessPatientsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PatientsOrderByInput>;
  status?: InputMaybe<PatientStatus>;
};


export type QueryBusinessPatientsCountArgs = {
  businessId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PatientStatus>;
};


export type QueryBusinessPostAppointmentMessageTemplatesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryBusinessRoleConfigurationsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryBusinessServicesArgs = {
  businessId: Scalars['ID']['input'];
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBusinessServicesCountArgs = {
  businessId: Scalars['ID']['input'];
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBusinessStaffsArgs = {
  businessId: Scalars['ID']['input'];
  filterId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCalendarConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalendarConnectionsArgs = {
  resourceId: Scalars['ID']['input'];
  resourceType: CalendarConnectionResourceType;
};


export type QueryCalendarViewAppointmentsArgs = {
  dateEnd: Scalars['DateTime']['input'];
  dateStart: Scalars['DateTime']['input'];
  groupBookingId?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<LocationType>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  singleId?: InputMaybe<Scalars['ID']['input']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCalendarViewExternalEventsArgs = {
  dateEnd: Scalars['DateTime']['input'];
  dateStart: Scalars['DateTime']['input'];
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCurrentBusinessArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryCurrentStaffArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryDownloadFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFaceVitalsScanResultArgs = {
  businessId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type QueryFaceVitalsScanResultsArgs = {
  businessId: Scalars['ID']['input'];
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  patientId: Scalars['ID']['input'];
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
  mode?: InputMaybe<FormQueryMode>;
};


export type QueryFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormSubmissionAttachmentUrlArgs = {
  answerKey: Scalars['String']['input'];
  formSubmissionId: Scalars['ID']['input'];
};


export type QueryFormSubmissionsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  formId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormTemplatesArgs = {
  library?: InputMaybe<FormTemplateLibrary>;
};


export type QueryFormsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormsCountArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetServiceTimezoneArgs = {
  businessId: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryLeastBusyStaffArgs = {
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  serviceId: Scalars['String']['input'];
};


export type QueryLogsArgs = {
  first: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryManagedStaffsArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<Array<StaffRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<StaffStatus>>;
};


export type QueryManagedStaffsCountArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roles?: InputMaybe<Array<StaffRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<StaffStatus>>;
};


export type QueryMessageDraftArgs = {
  threadId: Scalars['ID']['input'];
};


export type QueryMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessageTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNoteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNoteTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOAuthUrlsArgs = {
  businessId: Scalars['ID']['input'];
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOtherStaffCalendarConnectionsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryOutgoingWebhookArgs = {
  businessId: Scalars['ID']['input'];
  outgoingWebhookId: Scalars['ID']['input'];
};


export type QueryOutgoingWebhooksArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryPatientInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};


export type QueryPatientInvoicesCountArgs = {
  patientId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};


export type QueryPatientNotesArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientNotesCountArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
};


export type QueryPatientThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  status: ThreadStatus;
};


export type QueryRecurringOccurrencesArgs = {
  count: Scalars['Int']['input'];
  date: Scalars['DateTime']['input'];
  recurrence: Scalars['Json']['input'];
  timezone: Scalars['String']['input'];
};


export type QueryStaffCalendarConnectionsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryStaffIsAvailableArgs = {
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  ignoreAppointmentId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
};


export type QueryStaffOAuthUrlsArgs = {
  businessId: Scalars['ID']['input'];
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStaffTeamsOAuthUrlArgs = {
  businessId: Scalars['ID']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type QueryStaffThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status: ThreadStatus;
};


export type QueryStaffsByIdArgs = {
  staffsIds: Array<Scalars['ID']['input']>;
};


export type QuerySynchronizationLogsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  connectionId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
};


export type QueryTeamsOAuthUrlArgs = {
  redirectUrl: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
  resourceType: Scalars['String']['input'];
};


export type QueryThirdPartyConnectionsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  provider: ThirdPartyConnectionProvider;
  staffId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryThreadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThreadMessagesArgs = {
  threadId: Scalars['ID']['input'];
};


export type QueryUserStaffArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryWorkingHoursSlotsArgs = {
  from: Scalars['DateTime']['input'];
  staffIds: Array<Scalars['ID']['input']>;
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};

export type RawData = {
  __typename?: 'RawData';
  data?: Maybe<Scalars['Json']['output']>;
};

export type RecurrenceOccurrencesPayload = {
  __typename?: 'RecurrenceOccurrencesPayload';
  hasMore: Scalars['Boolean']['output'];
  occurrences: Array<Scalars['String']['output']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  appointment: Appointment;
  appointmentDate?: Maybe<Scalars['DateTime']['output']>;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  reminderTemplate: ReminderTemplate;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  staff?: Maybe<Staff>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ReminderPatientArgs = {
  where?: InputMaybe<PatientWhereInput>;
};


export type ReminderReminderTemplateArgs = {
  where?: InputMaybe<ReminderTemplateWhereInput>;
};

export const ReminderResourceType = {
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type ReminderResourceType = typeof ReminderResourceType[keyof typeof ReminderResourceType];
export type ReminderTemplate = {
  __typename?: 'ReminderTemplate';
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  defaultTemplate?: Maybe<Scalars['Json']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  messageTemplate?: Maybe<Scalars['Json']['output']>;
  minutes: Scalars['Int']['output'];
  reminders: Array<Reminder>;
  resourceType: ReminderResourceType;
  services?: Maybe<Array<Service>>;
  type: ReminderType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReminderTemplateWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export const ReminderType = {
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type ReminderType = typeof ReminderType[keyof typeof ReminderType];
export type RescheduleAppointment = {
  __typename?: 'RescheduleAppointment';
  addToGoogleUrl?: Maybe<Scalars['String']['output']>;
  addToOutlookUrl?: Maybe<Scalars['String']['output']>;
  answers?: Maybe<Scalars['JsonArray']['output']>;
  approved?: Maybe<Scalars['Boolean']['output']>;
  business?: Maybe<Scalars['Json']['output']>;
  clientTimezone?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  hangoutLink?: Maybe<Scalars['String']['output']>;
  icsFileContent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['Json']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  rrule?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['Json']['output']>;
  staff?: Maybe<Scalars['Json']['output']>;
};

export type RoleConfiguration = {
  __typename?: 'RoleConfiguration';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scopes: Array<Scalars['String']['output']>;
};

export type SendRemindersPayload = {
  __typename?: 'SendRemindersPayload';
  success: Scalars['Boolean']['output'];
};

export type Service = {
  __typename?: 'Service';
  adHocService?: Maybe<AdHocService>;
  additionalStaff?: Maybe<Array<Staff>>;
  alias?: Maybe<Scalars['String']['output']>;
  allowGuests?: Maybe<Scalars['Boolean']['output']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsWithinRange?: Maybe<Scalars['JsonArray']['output']>;
  availabilityLimitDays?: Maybe<Scalars['Int']['output']>;
  bookingPageUrl?: Maybe<Scalars['String']['output']>;
  bookingPageVisibility?: Maybe<Scalars['String']['output']>;
  bookingsPerTimeslot?: Maybe<Scalars['Int']['output']>;
  bookingsRequireApproval?: Maybe<Scalars['Boolean']['output']>;
  bufferAfterMinutes?: Maybe<Scalars['Int']['output']>;
  bufferBeforeMinutes?: Maybe<Scalars['Int']['output']>;
  business: Business;
  cancelLeadTime?: Maybe<Scalars['Int']['output']>;
  categories?: Maybe<Array<ServiceCategory>>;
  currency: Scalars['String']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['String']['output']>;
  durations?: Maybe<Scalars['JsonArray']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  hideTimeslotsSeats?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  leadTime: Scalars['Int']['output'];
  location?: Maybe<Location>;
  locationOverridesStaffs?: Maybe<Scalars['Boolean']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overrideStaffVideoConferenceProvider?: Maybe<Scalars['Boolean']['output']>;
  picture?: Maybe<File>;
  recurrence?: Maybe<Scalars['Json']['output']>;
  requiredAdditionalStaffCount?: Maybe<Scalars['Int']['output']>;
  requiresStripePayment?: Maybe<Scalars['Boolean']['output']>;
  staffs?: Maybe<Array<Staff>>;
  timeIncrement: Scalars['Int']['output'];
  videoConferenceProvider?: Maybe<VideoConferenceProvider>;
  workingHours?: Maybe<Scalars['Json']['output']>;
};


export type ServiceAppointmentsWithinRangeArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  business: Business;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SignedUploadParams = {
  __typename?: 'SignedUploadParams';
  headers: Scalars['Json']['output'];
  url: Scalars['String']['output'];
};

export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export type Staff = {
  __typename?: 'Staff';
  activeServices?: Maybe<Array<Service>>;
  alias?: Maybe<Scalars['String']['output']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsCount?: Maybe<Scalars['Int']['output']>;
  appointmentsWithinRange?: Maybe<Scalars['JsonArray']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivedReason?: Maybe<Scalars['String']['output']>;
  availableBoolFeatures: Array<Scalars['String']['output']>;
  bookingPageUrl?: Maybe<Scalars['String']['output']>;
  business: Business;
  calendarConnections?: Maybe<Array<CalendarConnection>>;
  canGenerateHangoutLinks?: Maybe<Scalars['Boolean']['output']>;
  canGenerateTeamsMeetings?: Maybe<Scalars['Boolean']['output']>;
  canGenerateZoomMeetings?: Maybe<Scalars['Boolean']['output']>;
  capacity?: Maybe<StaffCapacity>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastInvitedAt?: Maybe<Scalars['DateTime']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  locationValue?: Maybe<Scalars['String']['output']>;
  managedStaff?: Maybe<Array<Staff>>;
  marketingMeta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  phoneMobile?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<File>;
  resendInvite?: Maybe<Scalars['Boolean']['output']>;
  roles: Array<StaffRole>;
  scopes: Array<Scalars['String']['output']>;
  services?: Maybe<Array<Service>>;
  signature?: Maybe<Scalars['Json']['output']>;
  status?: Maybe<StaffStatus>;
  stripeProductIds?: Maybe<Array<Scalars['String']['output']>>;
  teamsConnection?: Maybe<ThirdPartyConnection>;
  timezone?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  videoConferenceProvider?: Maybe<VideoConferenceProvider>;
  workingHours?: Maybe<Scalars['Json']['output']>;
  zoomConnection?: Maybe<ThirdPartyConnection>;
};


export type StaffAppointmentsWithinRangeArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type StaffCalendarConnectionsArgs = {
  where?: InputMaybe<CalendarConnectionWhereInput>;
};


export type StaffCapacityArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type StaffWorkingHoursArgs = {
  time?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StaffCapacity = {
  __typename?: 'StaffCapacity';
  availableBookableMinutes: Scalars['Int']['output'];
  blockedTime: StaffCapacityBlockedTime;
  bookableMinutes: Scalars['Int']['output'];
  bookedMinutes: Scalars['Int']['output'];
};

export type StaffCapacityBlockedTime = {
  __typename?: 'StaffCapacityBlockedTime';
  externalEvents: Scalars['Int']['output'];
  timeBlockers: Scalars['Int']['output'];
};

export const StaffRole = {
  Admin: 'ADMIN',
  DataManager: 'DATA_MANAGER',
  Developer: 'DEVELOPER',
  Manager: 'MANAGER',
  Owner: 'OWNER',
  Staff: 'STAFF',
  Support: 'SUPPORT'
} as const;

export type StaffRole = typeof StaffRole[keyof typeof StaffRole];
export const StaffStatus = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
  Disabled: 'DISABLED',
  NotInvited: 'NOT_INVITED',
  PendingInvite: 'PENDING_INVITE'
} as const;

export type StaffStatus = typeof StaffStatus[keyof typeof StaffStatus];
export type StripeBillingPortalSession = {
  __typename?: 'StripeBillingPortalSession';
  configuration: Scalars['String']['output'];
  customer: Scalars['String']['output'];
  id: Scalars['String']['output'];
  return_url: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  livemode?: Maybe<Scalars['Boolean']['output']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StripeConnectAccountCreatePayload = {
  __typename?: 'StripeConnectAccountCreatePayload';
  session?: Maybe<StripeConnectAccountSession>;
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type StripeConnectAccountSession = {
  __typename?: 'StripeConnectAccountSession';
  clientSecret: Scalars['String']['output'];
  expiresAt: Scalars['Date']['output'];
};

export const StripeIntentStatus = {
  Canceled: 'CANCELED',
  Processing: 'PROCESSING',
  RequiresAction: 'REQUIRES_ACTION',
  RequiresCapture: 'REQUIRES_CAPTURE',
  RequiresConfirmation: 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod: 'REQUIRES_PAYMENT_METHOD',
  Succeeded: 'SUCCEEDED'
} as const;

export type StripeIntentStatus = typeof StripeIntentStatus[keyof typeof StripeIntentStatus];
export const StripeInterval = {
  Month: 'MONTH',
  Year: 'YEAR'
} as const;

export type StripeInterval = typeof StripeInterval[keyof typeof StripeInterval];
export type StripePrice = {
  __typename?: 'StripePrice';
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
  unitAmount: Scalars['Int']['output'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  createdAt: Scalars['DateTime']['output'];
  defaultPriceId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  featureList: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prices: Array<StripePrice>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nextAction?: Maybe<StripeSetupIntentNextAction>;
  status: StripeIntentStatus;
  usage: Scalars['String']['output'];
};

export type StripeSetupIntentNextAction = {
  __typename?: 'StripeSetupIntentNextAction';
  redirectToUrl?: Maybe<StripeSetupIntentNextActionRedirectToUrl>;
  type: Scalars['String']['output'];
  useStripeSDK?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeSetupIntentNextActionRedirectToUrl = {
  __typename?: 'StripeSetupIntentNextActionRedirectToUrl';
  returnUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export const SubscriptionStatus = {
  Active: 'ACTIVE',
  Canceled: 'CANCELED',
  Incomplete: 'INCOMPLETE',
  IncompleteExpired: 'INCOMPLETE_EXPIRED',
  PastDue: 'PAST_DUE',
  Paused: 'PAUSED',
  Trialing: 'TRIALING',
  Unpaid: 'UNPAID'
} as const;

export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];
export type SupportIdentificationToken = {
  __typename?: 'SupportIdentificationToken';
  expiresAt?: Maybe<Scalars['Int']['output']>;
  token: Scalars['String']['output'];
};

export type SyncStripeCustomerPayload = {
  __typename?: 'SyncStripeCustomerPayload';
  business: Business;
};

export type SynchronizationLog = {
  __typename?: 'SynchronizationLog';
  calendarConnection: CalendarConnection;
  changes?: Maybe<Scalars['JsonArray']['output']>;
  createdAt: Scalars['DateTime']['output'];
  executionTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  type: SynchronizationLogType;
  updatedAt: Scalars['DateTime']['output'];
};

export const SynchronizationLogType = {
  Export: 'EXPORT',
  Import: 'IMPORT'
} as const;

export type SynchronizationLogType = typeof SynchronizationLogType[keyof typeof SynchronizationLogType];
export type SynchronizationLogsMetaPayload = {
  __typename?: 'SynchronizationLogsMetaPayload';
  count?: Maybe<Scalars['Int']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  alias: Scalars['String']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const TaxBehaviour = {
  Disabled: 'DISABLED',
  Exclusive: 'EXCLUSIVE',
  Inclusive: 'INCLUSIVE'
} as const;

export type TaxBehaviour = typeof TaxBehaviour[keyof typeof TaxBehaviour];
export type ThirdPartyConnection = {
  __typename?: 'ThirdPartyConnection';
  business: Business;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAuthorized?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  pictureUrl: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  staff?: Maybe<Staff>;
};

export type ThirdPartyConnectionOAuthSession = {
  __typename?: 'ThirdPartyConnectionOAuthSession';
  redirectUrl: Scalars['String']['output'];
};

export const ThirdPartyConnectionProvider = {
  Teams: 'TEAMS',
  Zoom: 'ZOOM'
} as const;

export type ThirdPartyConnectionProvider = typeof ThirdPartyConnectionProvider[keyof typeof ThirdPartyConnectionProvider];
export const ThirdPartyConnectionResourceType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type ThirdPartyConnectionResourceType = typeof ThirdPartyConnectionResourceType[keyof typeof ThirdPartyConnectionResourceType];
export const ThreadStatus = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED'
} as const;

export type ThreadStatus = typeof ThreadStatus[keyof typeof ThreadStatus];
export type TimeBlocker = {
  __typename?: 'TimeBlocker';
  business?: Maybe<Business>;
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  staff?: Maybe<Staff>;
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  isValid: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UpdateSeatsResponse = {
  __typename?: 'UpdateSeatsResponse';
  business: Business;
  checkoutSession?: Maybe<StripeCheckoutSession>;
};

export type User = {
  __typename?: 'User';
  /** @deprecated Use availableFeatures resolver directly */
  availableFeatures?: Maybe<Scalars['Json']['output']>;
  businessesCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ownedBusinessesCount: Scalars['Int']['output'];
  role: UserRole;
  staffs: Array<UserStaff>;
  updatedAt: Scalars['DateTime']['output'];
};

export const UserRole = {
  Superadmin: 'SUPERADMIN',
  SupportAgent: 'SUPPORT_AGENT',
  User: 'USER'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
export type UserStaff = {
  __typename?: 'UserStaff';
  alias?: Maybe<Scalars['String']['output']>;
  business: UserStaffBusiness;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  picture?: Maybe<Picture>;
  roles: Array<StaffRole>;
};

export type UserStaffBusiness = {
  __typename?: 'UserStaffBusiness';
  alias: Scalars['String']['output'];
  availableFeatures?: Maybe<Scalars['Json']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Picture>;
  name: Scalars['String']['output'];
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  remainingTrialDays?: Maybe<Scalars['Int']['output']>;
  servicesCount: Scalars['Int']['output'];
  /** @deprecated Use roles field on Staff */
  staffRoles: Array<StaffRole>;
  /** @deprecated No longer needed to pre condition subscription status */
  stripeData?: Maybe<Scalars['Json']['output']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
};

export type UserSynchronization = {
  __typename?: 'UserSynchronization';
  changes?: Maybe<Scalars['JsonArray']['output']>;
  createdAt: Scalars['DateTime']['output'];
  direction: Scalars['String']['output'];
  errors?: Maybe<Scalars['JsonArray']['output']>;
  id: Scalars['ID']['output'];
  inboundGoogleError?: Maybe<Scalars['Json']['output']>;
  inboundGoogleHasChanges?: Maybe<Scalars['Boolean']['output']>;
  inboundGoogleLog?: Maybe<Scalars['Json']['output']>;
  inboundOutlookError?: Maybe<Scalars['Json']['output']>;
  inboundOutlookHasChanges?: Maybe<Scalars['Boolean']['output']>;
  inboundOutlookLog?: Maybe<Scalars['Json']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user: User;
};

export type UserWhereInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export const VideoConferenceProvider = {
  GoogleMeet: 'GOOGLE_MEET',
  MicrosoftTeams: 'MICROSOFT_TEAMS',
  None: 'NONE',
  UpvioTelehealth: 'UPVIO_TELEHEALTH',
  Zoom: 'ZOOM'
} as const;

export type VideoConferenceProvider = typeof VideoConferenceProvider[keyof typeof VideoConferenceProvider];
export type WorkingHoursSchedule = {
  __typename?: 'WorkingHoursSchedule';
  from: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  staff?: Maybe<Staff>;
  to?: Maybe<Scalars['DateTime']['output']>;
  workingHours?: Maybe<Scalars['Json']['output']>;
  workingHoursUTC?: Maybe<Scalars['Json']['output']>;
};

export type WorkingHoursSlots = {
  __typename?: 'WorkingHoursSlots';
  slots: Array<Array<Scalars['DateTime']['output']>>;
  staffSlots?: Maybe<Scalars['Json']['output']>;
};

export type CreateAdHocAppointmentMutationVariables = Exact<{
  title: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  staffId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateAdHocAppointmentMutation = { __typename?: 'Mutation', createAdHocAppointment: { __typename?: 'Appointment', id: string } };

export type CreateAdHocServiceMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
  timeslots: Scalars['Json']['input'];
  timeIncrement: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  maxAppointments: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAdHocServiceMutation = { __typename?: 'Mutation', adHocService: { __typename?: 'AdHocService', id: string } };

export type AllApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type AllApiKeysQuery = { __typename?: 'Query', allApiKeys: Array<{ __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null, invalidatedAt?: any | null }> };

export type AllBusinessPatientsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PatientsOrderByInput>;
  status?: InputMaybe<PatientStatus>;
}>;


export type AllBusinessPatientsQuery = { __typename?: 'Query', count: number, patients: Array<{ __typename?: 'Patient', id: string, name: string, preferredName?: string | null, email: string, phoneMobile?: string | null, patientType?: PatientType | null, dob?: any | null, age?: number | null, gender?: string | null, archivedAt?: any | null }> };

export type AnalyticsSmsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type AnalyticsSmsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', sms: Array<{ __typename?: 'AnalyticsSMSEntry', date: any, sent: number, delivered: number, credits: number }> } | null };

export type AnalyticsAppointmentsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type AnalyticsAppointmentsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', appointments: Array<{ __typename?: 'AnalyticsAppointmentEntry', date: any, created: number, cancelled: number }> } | null };

export type AnalyticsEventsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type AnalyticsEventsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', events: Array<{ __typename?: 'AnalyticsEventEntry', date: any, all: number, appointmentCreated: number, appointmentUpdated: number, appointmentDeleted: number, businessUpdated: number, formCreated: number, formUpdated: number, formDeleted: number, patientCreated: number, patientUpdated: number, patientDeleted: number, serviceCreated: number, serviceUpdated: number, serviceDeleted: number, staffCreated: number, staffUpdated: number, staffDeleted: number }> } | null };

export type AppointmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', id: string, date: any, duration: number, approved?: boolean | null, locationType?: string | null, locationValue?: string | null, currency?: string | null, price?: number | null, answers?: any | null, paymentProvider?: string | null, rrule?: string | null, originalDate?: any | null, transactionUrl?: string | null, patientVerified?: boolean | null, tagIds?: Array<string> | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string }, additionalStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null, patient: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null }, service: { __typename?: 'Service', id: string, name: string, description?: string | null, durations?: any | null }, location?: { __typename?: 'Location', id: string, name: string } | null } | null };

export type ApproveAppointmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ApproveAppointmentMutation = { __typename?: 'Mutation', approveAppointment?: { __typename?: 'Appointment', id: string, approved?: boolean | null } | null };

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  futureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  occurrenceDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', deleteAppointment?: { __typename?: 'Appointment', id: string, cancelled?: boolean | null } | null };

export type CancelGroupBookingMutationVariables = Exact<{
  groupBookingId: Scalars['String']['input'];
}>;


export type CancelGroupBookingMutation = { __typename?: 'Mutation', cancelGroupBooking: Array<{ __typename?: 'Appointment', id: string }> };

export type CreateAppointmentMutationVariables = Exact<{
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  staffId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
  requestUrl?: InputMaybe<Scalars['String']['input']>;
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateAppointmentMutation = { __typename?: 'Mutation', createAppointment?: { __typename?: 'Appointment', id: string, date: any, price?: number | null, currency?: string | null, duration: number, rrule?: string | null, originalDate?: any | null, requestUrl?: string | null, business: { __typename?: 'Business', id: string }, staff: { __typename?: 'Staff', id: string }, patient: { __typename?: 'Patient', id: string }, service: { __typename?: 'Service', id: string } } | null };

export type GroupBookingAppointmentsQueryVariables = Exact<{
  groupBookingId: Scalars['ID']['input'];
}>;


export type GroupBookingAppointmentsQuery = { __typename?: 'Query', appointments?: Array<{ __typename?: 'Appointment', id: string, date: any, duration: number, approved?: boolean | null, cancelled?: boolean | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string }, patient: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null }, service: { __typename?: 'Service', id: string, name: string } }> | null };

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  date: Scalars['DateTime']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  singleOccurrence?: InputMaybe<Scalars['Boolean']['input']>;
  originalDate?: InputMaybe<Scalars['DateTime']['input']>;
  futureOccurrences?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateAppointmentMutation = { __typename?: 'Mutation', updateAppointment?: { __typename?: 'Appointment', id: string, date: any, price?: number | null, currency?: string | null, duration: number, rrule?: string | null, originalDate?: any | null, business: { __typename?: 'Business', id: string }, staff: { __typename?: 'Staff', id: string }, patient: { __typename?: 'Patient', id: string }, service: { __typename?: 'Service', id: string } } | null };

export type UpdateGroupBookingMutationVariables = Exact<{
  groupBookingId: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
}>;


export type UpdateGroupBookingMutation = { __typename?: 'Mutation', updateGroupBooking: Array<{ __typename?: 'Appointment', id: string, date: any, groupBookingId?: string | null }> };

export type AssignStaffStripeProductIdsMutationVariables = Exact<{
  staffId: Scalars['ID']['input'];
  productIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AssignStaffStripeProductIdsMutation = { __typename?: 'Mutation', assignStaffStripeProductIds: { __typename?: 'Staff', id: string, stripeProductIds?: Array<string> | null, availableBoolFeatures: Array<string>, business: { __typename?: 'Business', id: string, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null } } };

export type AuthenticateUserMutationVariables = Exact<{
  auth0Code: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  signupKey?: InputMaybe<Scalars['String']['input']>;
  hubspotCookie?: InputMaybe<Scalars['String']['input']>;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser: { __typename?: 'AuthPayload', id: string, token: string, userIsNew: boolean, forcedSessionId?: string | null, redirectUrl?: string | null, user: { __typename?: 'AuthUserPayload', id: string } } };

export type CurrentBusinessProductsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CurrentBusinessProductsQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string }> } };

export type FieldsFragment = { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string }> };

export type BusinessQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BusinessQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string }> } | null };

export type CurrentBusinessQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CurrentBusinessQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string }> } };

export type BusinessSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BusinessSettingsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, alias: string, name: string, email?: string | null, website?: string | null, timezone?: string | null, currency?: string | null, dateFormat?: any | null, physicalAddress?: string | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, allowStaffApprovals?: boolean | null, availabilityLimitDays?: number | null, availableFeatures?: any | null, workingHours: any, ownerNotificationsRecipient?: OwnerNotificationsRecipient | null, ownerNotificationsCC?: string | null, bookingNotificationsReplyTo?: NotificationsReplyToType | null, attachICSFiles?: boolean | null, sendSMSFromStaffNumber?: boolean | null, color?: string | null, logoPosition?: string | null, allowChoosingStaff?: boolean | null, allowNoStaffPreference?: boolean | null, marketingMeta?: any | null, defaultInvoiceDaysUntilDue?: number | null, autoGenerateBookingInvoices?: boolean | null, bookingsRequireApprovalUntilPaid?: boolean | null, invoicesTaxBehaviour?: TaxBehaviour | null, contactEmail?: string | null, contactPhone?: string | null, contactUrl?: string | null, privacyPolicyUrl?: string | null, termsOfServiceUrl?: string | null, logo?: { __typename?: 'Picture', id: string, url: string } | null } | null };

export type UpdateBusinessSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  physicalAddress?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
  dateFormat?: InputMaybe<Scalars['Json']['input']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']['input']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']['input']>;
  redirectAfterBookingUrl?: InputMaybe<Scalars['String']['input']>;
  postOnRedirect?: InputMaybe<Scalars['Boolean']['input']>;
  includeAnswersOnRedirect?: InputMaybe<Scalars['Boolean']['input']>;
  googleTrackingId?: InputMaybe<Scalars['String']['input']>;
  trackingPixelUrl?: InputMaybe<Scalars['String']['input']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  allowStaffApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  logoPosition?: InputMaybe<Scalars['String']['input']>;
  allowTimeRequests?: InputMaybe<Scalars['Boolean']['input']>;
  timeRequestButtonLabel?: InputMaybe<Scalars['String']['input']>;
  timeRequestText?: InputMaybe<Scalars['String']['input']>;
  hidePrivacyButton?: InputMaybe<Scalars['Boolean']['input']>;
  allowChoosingStaff?: InputMaybe<Scalars['Boolean']['input']>;
  allowNoStaffPreference?: InputMaybe<Scalars['Boolean']['input']>;
  exportedEventsTemplate?: InputMaybe<Scalars['Json']['input']>;
  marketingMeta?: InputMaybe<Scalars['Json']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  defaultInvoiceDaysUntilDue?: InputMaybe<Scalars['Int']['input']>;
  autoGenerateBookingInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  bookingsRequireApprovalUntilPaid?: InputMaybe<Scalars['Boolean']['input']>;
  invoicesTaxBehaviour?: InputMaybe<TaxBehaviour>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactUrl?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  termsOfServiceUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateBusinessSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, enabled?: boolean | null, alias: string, name: string, email?: string | null, website?: string | null, currency?: string | null, physicalAddress?: string | null, timezone?: string | null, color?: string | null, logoPosition?: string | null, workingHours: any, availableFeatures?: any | null, dateFormat?: any | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, redirectAfterBookingUrl?: string | null, postOnRedirect?: boolean | null, includeAnswersOnRedirect?: boolean | null, googleTrackingId?: string | null, trackingPixelUrl?: string | null, availabilityLimitDays?: number | null, allowStaffApprovals?: boolean | null, allowTimeRequests?: boolean | null, timeRequestButtonLabel?: string | null, timeRequestText?: string | null, hidePrivacyButton?: boolean | null, allowChoosingStaff?: boolean | null, allowNoStaffPreference?: boolean | null, exportedEventsTemplate?: any | null, marketingMeta?: any | null, onboardingCompletedAt?: any | null, defaultInvoiceDaysUntilDue?: number | null, autoGenerateBookingInvoices?: boolean | null, bookingsRequireApprovalUntilPaid?: boolean | null, invoicesTaxBehaviour?: TaxBehaviour | null, contactEmail?: string | null, contactPhone?: string | null, contactUrl?: string | null, privacyPolicyUrl?: string | null, termsOfServiceUrl?: string | null, logo?: { __typename?: 'Picture', id: string, url: string } | null } | null };

export type BusinessRoleConfigurationsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BusinessRoleConfigurationsQuery = { __typename?: 'Query', businessRoleConfigurations: Array<{ __typename?: 'RoleConfiguration', id: string, name: string, description: string, scopes: Array<string> }> };

export type CalendarConnectionsQueryQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  redirectUrl: Scalars['String']['input'];
}>;


export type CalendarConnectionsQueryQuery = { __typename?: 'Query', businessCalendarConnections: Array<{ __typename?: 'CalendarConnection', id: string, provider: CalendarConnectionProvider, accountId?: string | null, enabled?: boolean | null, isAuthorized: boolean, lastSyncedAt?: any | null, importFromCalendarsIds: Array<string>, calendars?: any | null, exportToCalendarId?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null }>, personalCalendarConnections: Array<{ __typename?: 'CalendarConnection', id: string, provider: CalendarConnectionProvider, accountId?: string | null, enabled?: boolean | null, isAuthorized: boolean, lastSyncedAt?: any | null, importFromCalendarsIds: Array<string>, calendars?: any | null, exportToCalendarId?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null }>, businessOAuthUrls?: { __typename?: 'OAuthUrlsPayload', google?: string | null, microsoft?: string | null } | null, personalOAuthUrls?: { __typename?: 'OAuthUrlsPayload', google?: string | null, microsoft?: string | null } | null };

export type CalendarSyncSettingsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CalendarSyncSettingsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, exportedEventsTemplate?: any | null } | null };

export type DisconnectCalendarConnectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DisconnectCalendarConnectionMutation = { __typename?: 'Mutation', disconnectCalendarConnection: string };

export type UpdateCalendarConnectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  importFromCalendarsIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  exportToCalendarId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  allDayEventsTimezone?: InputMaybe<Scalars['String']['input']>;
  exportAttendees?: InputMaybe<Array<CalendarEventAttendee> | CalendarEventAttendee>;
}>;


export type UpdateCalendarConnectionMutation = { __typename?: 'Mutation', updateCalendarConnection?: { __typename?: 'CalendarConnection', id: string, importFromCalendarsIds: Array<string>, exportToCalendarId?: string | null, calendars?: any | null, enabled?: boolean | null, allDayEventsTimezone?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null } | null };

export type CalendarAppointmentsQueryVariables = Exact<{
  dateStart: Scalars['DateTime']['input'];
  dateEnd: Scalars['DateTime']['input'];
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<LocationType>;
}>;


export type CalendarAppointmentsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: string, approved?: boolean | null, cancelled?: boolean | null, date: any, duration: number, price?: number | null, currency?: string | null, groupBookingId?: string | null, locationType?: string | null, locationValue?: string | null, rrule?: string | null, originalDate?: any | null, recurringAppointmentId?: string | null, tagIds?: Array<string> | null, staff: { __typename?: 'Staff', id: string, name?: string | null }, patient: { __typename?: 'Patient', id: string, name: string }, service: { __typename?: 'Service', id: string, name: string } }> };

export type DeleteTimeBlockerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTimeBlockerMutation = { __typename?: 'Mutation', deleteTimeBlocker?: { __typename?: 'TimeBlocker', id: string } | null };

export type CalendarExternalEventsQueryVariables = Exact<{
  dateStart: Scalars['DateTime']['input'];
  dateEnd: Scalars['DateTime']['input'];
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type CalendarExternalEventsQuery = { __typename?: 'Query', externalEvents: Array<{ __typename?: 'CalendarViewExternalEventPayload', id: string, startDate: any, endDate: any, title?: string | null, staffId?: string | null }> };

export type CalendarResourcesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  time: Scalars['DateTime']['input'];
  businessId: Scalars['ID']['input'];
  filterIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type CalendarResourcesQuery = { __typename?: 'Query', staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, workingHours?: any | null, picture?: { __typename?: 'File', id: string, url: string } | null }>, business?: { __typename?: 'Business', id: string, workingHours: any } | null };

export type CouponsQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponsQuery = { __typename?: 'Query', coupons: Array<{ __typename?: 'Coupon', id: string, createdAt: any, updatedAt: any, name: string, code: string, type: string, amountOff?: number | null, percentOff?: number | null, currency?: string | null, expiresAt?: any | null, redemptionsCount: number }> };

export type CreateCouponMutationVariables = Exact<{
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  amountOff?: InputMaybe<Scalars['Int']['input']>;
  percentOff?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type CreateCouponMutation = { __typename?: 'Mutation', createCoupon: { __typename?: 'Coupon', id: string, createdAt: any, updatedAt: any, name: string, code: string, type: string, amountOff?: number | null, percentOff?: number | null, currency?: string | null, expiresAt?: any | null, redemptionsCount: number } };

export type DeleteCouponMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCouponMutation = { __typename?: 'Mutation', deleteCoupon: boolean };

export type UpdateCouponMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateCouponMutation = { __typename?: 'Mutation', updateCoupon: { __typename?: 'Coupon', id: string, name: string } };

export type CreateApiKeyMutationVariables = Exact<{
  description: Scalars['String']['input'];
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', createApiKey: { __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null } };

export type CreateBusinessMutationVariables = Exact<{
  name: Scalars['String']['input'];
  alias: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}>;


export type CreateBusinessMutation = { __typename?: 'Mutation', createBusiness: { __typename?: 'Business', id: string, alias: string, name: string, timezone?: string | null } };

export type CreateStripeBillingPortalSessionMutationVariables = Exact<{
  flow: Scalars['String']['input'];
}>;


export type CreateStripeBillingPortalSessionMutation = { __typename?: 'Mutation', createStripeBillingPortalSession: { __typename?: 'StripeBillingPortalSession', url: string } };

export type CreateStripeCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCheckoutSession: { __typename?: 'StripeCheckoutSession', url: string } };

export type CreateStripeSetupIntentMutationVariables = Exact<{
  productQuantities: Scalars['Json']['input'];
  interval: StripeInterval;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateStripeSetupIntentMutation = { __typename?: 'Mutation', createStripeSetupIntent: { __typename?: 'StripeSetupIntent', id: string, customerId: string, clientSecret: string, status: StripeIntentStatus, usage: string, nextAction?: { __typename?: 'StripeSetupIntentNextAction', type: string, useStripeSDK?: boolean | null, redirectToUrl?: { __typename?: 'StripeSetupIntentNextActionRedirectToUrl', returnUrl?: string | null, url?: string | null } | null } | null } };

export type CurrentBusinessUsageQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CurrentBusinessUsageQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, usage: any } };

export type CurrentStaffQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CurrentStaffQuery = { __typename?: 'Query', currentStaff: { __typename?: 'Staff', id: string, alias?: string | null, enabled?: boolean | null, availableBoolFeatures: Array<string>, status?: StaffStatus | null, name?: string | null, occupation?: string | null, marketingMeta?: any | null, roles: Array<StaffRole>, description?: string | null, email: string, phoneMobile?: string | null, timezone?: string | null, bookingPageUrl?: string | null, stripeProductIds?: Array<string> | null, onboardingCompletedAt?: any | null, unreadMessagesCount?: number | null, business: { __typename?: 'Business', id: string, alias: string }, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null, picture?: { __typename?: 'File', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, name?: string | null, email: string, role: UserRole, businessesCount: number, ownedBusinessesCount: number, availableFeatures?: any | null, createdAt: any, updatedAt: any, staffs: Array<{ __typename?: 'UserStaff', id: string, onboardingCompletedAt?: any | null, roles: Array<StaffRole>, business: { __typename?: 'UserStaffBusiness', id: string, name: string, alias: string, enabled: boolean, servicesCount: number, staffRoles: Array<StaffRole>, onboardingCompletedAt?: any | null, subscriptionStatus?: SubscriptionStatus | null, availableFeatures?: any | null, stripeData?: any | null, remainingTrialDays?: number | null } }> } | null };

export type AllCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCustomFieldsQuery = { __typename?: 'Query', allCustomFields: Array<{ __typename?: 'CustomField', id: string, alias: string, name: string, description: string, fieldType: CustomFieldType, allowedValues?: Array<string> | null, createdAt: any, updatedAt: any }> };

export type CreateCustomFieldMutationVariables = Exact<{
  alias: Scalars['String']['input'];
  name: Scalars['String']['input'];
  fieldType: CustomFieldType;
  description: Scalars['String']['input'];
  allowedValues?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateCustomFieldMutation = { __typename?: 'Mutation', createCustomField: { __typename?: 'CustomField', id: string, alias: string, name: string, description: string, fieldType: CustomFieldType, allowedValues?: Array<string> | null } };

export type UpdateCustomFieldMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  allowedValues?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type UpdateCustomFieldMutation = { __typename?: 'Mutation', updateCustomField: { __typename?: 'CustomField', id: string, name: string, description: string, allowedValues?: Array<string> | null } };

export type SetPatientCustomFieldValueMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  fieldKey: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;


export type SetPatientCustomFieldValueMutation = { __typename?: 'Mutation', setPatientCustomFieldValue: { __typename?: 'CustomFieldValue', id: string, patientId: string, fieldKey: string, value: string } };

export type ArchivePatientMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type ArchivePatientMutation = { __typename?: 'Mutation', archivePatient?: { __typename?: 'Patient', id: string, archivedAt?: any | null } | null };

export type CreatePatientMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  primaryStaffId?: InputMaybe<Scalars['ID']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient?: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null, timezone?: string | null, dob?: any | null, age?: number | null, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null } | null } | null };

export type PatientQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;


export type PatientQuery = { __typename?: 'Query', patient: { __typename?: 'Patient', id: string, name: string, archivedAt?: any | null, archivedReason?: string | null, preferredName?: string | null, email: string, phoneMobile?: string | null, timezone?: string | null, dob?: any | null, age?: number | null, gender?: string | null, biologicalSex?: BiologicalSex | null, patientType?: PatientType | null, height?: number | null, weight?: number | null, selfDeclaredDiabeticType?: DiabeticType | null, selfDeclaredHypertension?: boolean | null, selfDeclaredIsSmoker?: boolean | null, stripeCustomerId?: string | null, tagIds: Array<string>, billingAddress?: { __typename?: 'Address', id: string, city: string, country: string, line1: string, line2?: string | null, name: string, postalCode: string, state: string } | null, customFieldValues: Array<{ __typename?: 'CustomFieldValue', id: string, fieldKey: string, fieldType: CustomFieldType, value: string }>, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, email: string, picture?: { __typename?: 'File', id: string, url: string } | null } | null } };

export type EnablePatientBillingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EnablePatientBillingMutation = { __typename?: 'Mutation', enablePatientBilling: { __typename?: 'Patient', id: string, stripeCustomerId?: string | null } };

export type RestorePatientMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RestorePatientMutation = { __typename?: 'Mutation', restorePatient?: { __typename?: 'Patient', id: string, archivedAt?: any | null } | null };

export type UpdatePatientMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  patientType?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  biologicalSex?: InputMaybe<BiologicalSex>;
  billingAddress?: InputMaybe<AddressInput>;
  primaryStaffId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePatientMutation = { __typename?: 'Mutation', updatePatient?: { __typename?: 'Patient', id: string, name: string, preferredName?: string | null, email: string, phoneMobile?: string | null, timezone?: string | null, dob?: any | null, age?: number | null, patientType?: PatientType | null, gender?: string | null, biologicalSex?: BiologicalSex | null, billingAddress?: { __typename?: 'Address', id: string, city: string, country: string, line1: string, line2?: string | null, name: string, postalCode: string, state: string } | null, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null } | null } | null };

export type DataImportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DataImportQuery = { __typename?: 'Query', DataImport: { __typename?: 'DataImport', id: string, columnsMap: any, resourceType: string, rowsComplete?: number | null, rowsCount: number, rowsFailed?: number | null, rowsProcessed?: number | null, rowsSkipped?: number | null, status: DataImportStatus, createdBy: { __typename?: 'Staff', id: string, alias?: string | null, email: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }, dataImportErrors: Array<{ __typename?: 'DataImportError', error: string, lineNumber: number }> } };

export type AllDataImportsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllDataImportsQuery = { __typename?: 'Query', allDataImports?: Array<{ __typename?: 'DataImport', id: string, columnsMap: any, resourceType: string, rowsComplete?: number | null, rowsCount: number, rowsFailed?: number | null, rowsProcessed?: number | null, rowsSkipped?: number | null, status: DataImportStatus, createdBy: { __typename?: 'Staff', id: string, alias?: string | null, email: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null } }> | null };

export type CreateDataImportMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
  columnsMap: Scalars['Json']['input'];
  resourceType: Scalars['String']['input'];
  rowsCount: Scalars['Int']['input'];
}>;


export type CreateDataImportMutation = { __typename?: 'Mutation', createDataImport: { __typename?: 'DataImport', id: string } };

export type DisconnectStripeAccountMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type DisconnectStripeAccountMutation = { __typename?: 'Mutation', disconnectStripeAccount?: { __typename?: 'Business', id: string, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, stripePublishableKey?: string | null } | null } | null };

export type FaceVitalsScanResultQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}>;


export type FaceVitalsScanResultQuery = { __typename?: 'Query', faceVitalsScanResult?: { __typename?: 'FaceVitalsScanResult', id: string, createdAt: any, inputAge: number, inputGender: string, inputHeight: number, inputWeight: number, inputDiabeticType: string, inputBloodPressureMedication: boolean, inputHypertension: boolean, inputSmoker: boolean, bloodPressureDiastolicMmhg?: number | null, bloodPressureSystolicMmhg?: number | null, bloodPressureRiskCategory?: FaceVitalsBloodPressureRiskCategory | null, breathingRateBpm?: number | null, cardiacWorkload?: number | null, cardiovascularDiseaseRisk?: number | null, cardiovascularDiseaseRiskCategory?: FaceVitalsBaseRiskCategory | null, heartAttackRisk?: number | null, heartAttackRiskCategory?: FaceVitalsBaseRiskCategory | null, heartRateHz?: number | null, heartRateBpm?: number | null, heartRateIrregularBpm?: number | null, heartRateVariabilitySdnn?: number | null, heartRateVariabilityRiskCategory?: FaceVitalsBaseRiskCategory | null, strokeRisk?: number | null, strokeRiskCategory?: FaceVitalsBaseRiskCategory | null, vascularCapacitySecs?: number | null, appointment?: { __typename?: 'Appointment', id: string } | null, patient?: { __typename?: 'Patient', id: string } | null } | null };

export type FaceVitalsScanResultsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
}>;


export type FaceVitalsScanResultsQuery = { __typename?: 'Query', faceVitalsScanResults: Array<{ __typename?: 'FaceVitalsScanResult', id: string, createdAt: any, inputAge: number, inputGender: string, inputHeight: number, inputWeight: number, inputBloodPressureMedication: boolean, inputHypertension: boolean, inputSmoker: boolean, bloodPressureDiastolicMmhg?: number | null, bloodPressureSystolicMmhg?: number | null, bloodPressureRiskCategory?: FaceVitalsBloodPressureRiskCategory | null, breathingRateBpm?: number | null, cardiacWorkload?: number | null, cardiovascularDiseaseRisk?: number | null, cardiovascularDiseaseRiskCategory?: FaceVitalsBaseRiskCategory | null, heartAttackRisk?: number | null, heartAttackRiskCategory?: FaceVitalsBaseRiskCategory | null, heartRateHz?: number | null, heartRateBpm?: number | null, heartRateIrregularBpm?: number | null, heartRateVariabilitySdnn?: number | null, heartRateVariabilityRiskCategory?: FaceVitalsBaseRiskCategory | null, strokeRisk?: number | null, strokeRiskCategory?: FaceVitalsBaseRiskCategory | null, vascularCapacitySecs?: number | null, appointment?: { __typename?: 'Appointment', id: string } | null, patient?: { __typename?: 'Patient', id: string, name: string, email: string } | null }> };

export type FileFieldsFragment = { __typename?: 'File', id: string, patientId?: string | null, name: string, url: string, size: number, contentType: string, createdAt: any, updatedAt: any };

export type AllPatientFilesQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;


export type AllPatientFilesQuery = { __typename?: 'Query', allPatientFiles: Array<{ __typename?: 'File', id: string, patientId?: string | null, name: string, url: string, size: number, contentType: string, createdAt: any, updatedAt: any }> };

export type DownloadFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DownloadFileQuery = { __typename?: 'Query', downloadFile: { __typename?: 'DownloadFilePayload', url: string, expiresAt: any } };

export type BusinessFormsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type BusinessFormsQuery = { __typename?: 'Query', count: number, forms: Array<{ __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, useAsTemplate?: boolean | null, category?: string | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null, business?: { __typename?: 'Business', id: string, alias: string } | null }> };

export type CreateFormMutationVariables = Exact<{
  title: Scalars['String']['input'];
  formTemplateId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateFormMutation = { __typename?: 'Mutation', createForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type DeleteFormMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteFormMutation = { __typename?: 'Mutation', deleteForm: boolean };

export type DuplicateFormMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DuplicateFormMutation = { __typename?: 'Mutation', duplicateForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type FormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FormQuery = { __typename?: 'Query', form: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type FormFieldsFragment = { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null };

export type FormEditorFieldsFragment = { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null };

export type FormStepsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FormStepsQuery = { __typename?: 'Query', form: { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type GetAllFormTemplatesQueryVariables = Exact<{
  library: FormTemplateLibrary;
}>;


export type GetAllFormTemplatesQuery = { __typename?: 'Query', formTemplates: Array<{ __typename?: 'FormTemplate', id: string, name: string, description: string, thumbnailUrl: string, previewUrl: string }> };

export type PublishFormMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PublishFormMutation = { __typename?: 'Mutation', form: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type RenameFormMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['Json']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  useAsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RenameFormMutation = { __typename?: 'Mutation', renameForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type ResetBusinessBookingFormMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetBusinessBookingFormMutation = { __typename?: 'Mutation', resetBusinessBookingForm?: boolean | null };

export type FormSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FormSubmissionQuery = { __typename?: 'Query', formSubmission: { __typename?: 'FormSubmission', id: string, startedAt: any, answers: any, createdAt: any, questions: any, readAt?: any | null, form: { __typename?: 'Form', id: string, title?: string | null } } };

export type FormSubmissionAttachmentUrlQueryVariables = Exact<{
  formSubmissionId: Scalars['ID']['input'];
  answerKey: Scalars['String']['input'];
}>;


export type FormSubmissionAttachmentUrlQuery = { __typename?: 'Query', url?: string | null };

export type FormSubmissionsQueryVariables = Exact<{
  formId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type FormSubmissionsQuery = { __typename?: 'Query', response: { __typename?: 'FormSubmissionsResponse', submissions: Array<{ __typename?: 'FormSubmission', id: string, startedAt: any, answers: any, createdAt: any, questions: any, readAt?: any | null, patientVerified?: boolean | null, patient?: { __typename?: 'Patient', id: string, name: string } | null, form: { __typename?: 'Form', id: string, title?: string | null }, appointment?: { __typename?: 'Appointment', id: string } | null }>, meta: { __typename?: 'FormSubmissionsMetaPayload', count?: number | null } } };

export type UpdateFormSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
}>;


export type UpdateFormSubmissionMutation = { __typename?: 'Mutation', submission: { __typename?: 'FormSubmission', id: string, readAt?: any | null } };

export type UpdateFormDraftMutationVariables = Exact<{
  formId: Scalars['ID']['input'];
  steps?: InputMaybe<Scalars['Json']['input']>;
  settings?: InputMaybe<Scalars['Json']['input']>;
}>;


export type UpdateFormDraftMutation = { __typename?: 'Mutation', updateFormDraft: { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type InvalidateApiKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvalidateApiKeyMutation = { __typename?: 'Mutation', invalidateApiKey: { __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null, invalidatedAt?: any | null } };

export type BusinessLocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BusinessLocationQuery = { __typename?: 'Query', Location?: { __typename?: 'Location', description?: string | null, address?: string | null, createdAt: any, googlePlaceAddress?: string | null, googlePlaceId?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, googlePlaceName?: string | null, id: string, instructions?: string | null, lat?: number | null, lng?: number | null, name: string, phone?: string | null, timezone?: string | null, updatedAt: any, workingHours?: any | null, business: { __typename?: 'Business', id: string, name: string }, picture?: { __typename?: 'File', id: string, url: string } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null };

export type BusinessLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type BusinessLocationsQuery = { __typename?: 'Query', count: number, businessLocations: Array<{ __typename?: 'Location', address?: string | null, createdAt: any, googlePlaceAddress?: string | null, googlePlaceId?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, googlePlaceName?: string | null, id: string, instructions?: string | null, lat?: number | null, lng?: number | null, name: string, phone?: string | null, timezone?: string | null, updatedAt: any, workingHours?: any | null, business: { __typename?: 'Business', id: string, name: string }, picture?: { __typename?: 'File', id: string, url: string } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null }> };

export type SelectLocationsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type SelectLocationsListQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, name: string, address?: string | null }> };

export type CreateBusinessLocationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceName?: InputMaybe<Scalars['String']['input']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']['input']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateBusinessLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string, name: string, address?: string | null, instructions?: string | null, phone?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null, workingHours?: any | null, googlePlaceId?: string | null, googlePlaceName?: string | null, googlePlaceAddress?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, picture?: { __typename?: 'File', id: string } | null, business: { __typename?: 'Business', id: string } } | null };

export type DeleteBusinessLocationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteBusinessLocationMutation = { __typename?: 'Mutation', deleteLocation?: boolean | null };

export type UpdateBusinessLocationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceName?: InputMaybe<Scalars['String']['input']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']['input']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateBusinessLocationMutation = { __typename?: 'Mutation', updateLocation?: { __typename?: 'Location', id: string, name: string, address?: string | null, instructions?: string | null, phone?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null, workingHours?: any | null, googlePlaceId?: string | null, googlePlaceName?: string | null, googlePlaceAddress?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, description?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null, business: { __typename?: 'Business', id: string }, services?: Array<{ __typename?: 'Service', id: string }> | null } | null };

export type MessageTemplateFieldsFragment = { __typename?: 'MessageTemplate', id: string, createdAt: any, name: string, description?: string | null, content: string };

export type MessageTemplatesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MessageTemplatesQuery = { __typename?: 'Query', messageTemplates: { __typename?: 'MessageTemplatesResponse', totalCount: number, templates: Array<{ __typename?: 'MessageTemplate', id: string, createdAt: any, name: string, description?: string | null, content: string }> } };

export type MessageTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MessageTemplateQuery = { __typename?: 'Query', messageTemplate: { __typename?: 'MessageTemplate', id: string, createdAt: any, name: string, description?: string | null, content: string } };

export type CreateMessageTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
}>;


export type CreateMessageTemplateMutation = { __typename?: 'Mutation', createMessageTemplate: { __typename?: 'MessageTemplate', id: string, createdAt: any, name: string, description?: string | null, content: string } };

export type UpdateMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateMessageTemplateMutation = { __typename?: 'Mutation', updateMessageTemplate: { __typename?: 'MessageTemplate', id: string, createdAt: any, name: string, description?: string | null, content: string } };

export type DeleteMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMessageTemplateMutation = { __typename?: 'Mutation', success: boolean };

export type MessagesQueryVariables = Exact<{
  threadId: Scalars['ID']['input'];
}>;


export type MessagesQuery = { __typename?: 'Query', messages: Array<{ __typename?: 'Message', id: string, createdAt: any, readAt?: any | null, sentBy: MessageSender, sentAt?: any | null, content: string, patient?: { __typename?: 'Patient', id: string, name: string } | null, staff?: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null } | null, attachments: Array<{ __typename?: 'MessageAttachment', id: string, url?: string | null, label?: string | null, type: MessageAttachmentType }> }> };

export type SendMessageMutationVariables = Exact<{
  threadId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  attachments?: InputMaybe<Array<MessageAttachmentInput> | MessageAttachmentInput>;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage: { __typename?: 'Message', id: string, createdAt: any, readAt?: any | null, sentBy: MessageSender, content: string, patient?: { __typename?: 'Patient', id: string, name: string } | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null } };

export type UpsertMessageDraftMutationVariables = Exact<{
  threadId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  attachments?: InputMaybe<Array<MessageAttachmentInput> | MessageAttachmentInput>;
}>;


export type UpsertMessageDraftMutation = { __typename?: 'Mutation', upsertMessageDraft?: { __typename?: 'Message', id: string, createdAt: any, readAt?: any | null, sentBy: MessageSender, content: string, patient?: { __typename?: 'Patient', id: string, name: string } | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null } | null };

export type ReadMessageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReadMessageMutation = { __typename?: 'Mutation', readMessage: { __typename?: 'Message', id: string, readAt?: any | null } };

export type UnreadMessageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnreadMessageMutation = { __typename?: 'Mutation', unreadMessage: { __typename?: 'Message', id: string, readAt?: any | null } };

export type ThreadFieldsFragment = { __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> };

export type StaffThreadsQueryVariables = Exact<{
  status: ThreadStatus;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StaffThreadsQuery = { __typename?: 'Query', staffThreads: { __typename?: 'MessageThreadsResponse', totalCount: number, threads: Array<{ __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> }> } };

export type PatientThreadsQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
  status: ThreadStatus;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PatientThreadsQuery = { __typename?: 'Query', patientThreads: { __typename?: 'MessageThreadsResponse', totalCount: number, threads: Array<{ __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> }> } };

export type ThreadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThreadQuery = { __typename?: 'Query', thread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> }, messageDraft?: { __typename?: 'Message', id: string, content: string, attachments: Array<{ __typename?: 'MessageAttachment', id: string, url?: string | null, label?: string | null, type: MessageAttachmentType }> } | null };

export type CreateMessageThreadMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  content: Scalars['String']['input'];
  attachments?: InputMaybe<Array<MessageAttachmentInput> | MessageAttachmentInput>;
}>;


export type CreateMessageThreadMutation = { __typename?: 'Mutation', createMessageThread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> } };

export type CreateThreadMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
}>;


export type CreateThreadMutation = { __typename?: 'Mutation', createThread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> } };

export type UpdateThreadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
}>;


export type UpdateThreadMutation = { __typename?: 'Mutation', updateThread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null, createdAt: any, subject?: string | null, messagesCount: number, unreadMessagesCount: number, hasDraftMessage?: boolean | null, patient: { __typename?: 'Patient', id: string, name: string }, staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> } };

export type ArchiveMessageThreadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveMessageThreadMutation = { __typename?: 'Mutation', archiveMessageThread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null } };

export type RestoreMessageThreadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RestoreMessageThreadMutation = { __typename?: 'Mutation', restoreMessageThread: { __typename?: 'MessageThread', id: string, archivedAt?: any | null } };

export type NoteTemplateFieldsFragment = { __typename?: 'NoteTemplate', id: string, createdAt: any, name: string, content: string };

export type NoteTemplatesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type NoteTemplatesQuery = { __typename?: 'Query', noteTemplatesCount: number, noteTemplates: Array<{ __typename?: 'NoteTemplate', id: string, createdAt: any, name: string, content: string }> };

export type NoteTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type NoteTemplateQuery = { __typename?: 'Query', noteTemplate?: { __typename?: 'NoteTemplate', id: string, createdAt: any, name: string, content: string } | null };

export type CreateNoteTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  content: Scalars['String']['input'];
}>;


export type CreateNoteTemplateMutation = { __typename?: 'Mutation', createNoteTemplate: { __typename?: 'NoteTemplate', id: string, createdAt: any, name: string, content: string } };

export type UpdateNoteTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateNoteTemplateMutation = { __typename?: 'Mutation', updateNoteTemplate: { __typename?: 'NoteTemplate', id: string, createdAt: any, name: string, content: string } };

export type DeleteNoteTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteNoteTemplateMutation = { __typename?: 'Mutation', success: boolean };

export type CreateNoteMutationVariables = Exact<{
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  templateId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', createNote: { __typename?: 'Note', id: string, content: string, title?: string | null, createdAt: any, updatedAt: any, appointment?: { __typename?: 'Appointment', id: string } | null, patient: { __typename?: 'Patient', id: string } } };

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote: boolean };

export type NoteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type NoteQuery = { __typename?: 'Query', note?: { __typename?: 'Note', id: string, createdAt: any, updatedAt: any, content: string, title?: string | null, appointment?: { __typename?: 'Appointment', id: string } | null, patient: { __typename?: 'Patient', id: string, archivedAt?: any | null }, staff: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null } } | null };

export type PatientNotesQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PatientNotesQuery = { __typename?: 'Query', count: number, notes: Array<{ __typename?: 'Note', id: string, createdAt: any, title?: string | null, content: string, appointment?: { __typename?: 'Appointment', id: string } | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string, picture?: { __typename?: 'File', id: string, url: string } | null } }> };

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateNoteMutation = { __typename?: 'Mutation', updateNote: { __typename?: 'Note', id: string, content: string, title?: string | null, createdAt: any, updatedAt: any, appointment?: { __typename?: 'Appointment', id: string } | null, patient: { __typename?: 'Patient', id: string } } };

export type BusinessNotificationsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BusinessNotificationsQuery = { __typename?: 'Query', totalCount: number, notifications: Array<{ __typename?: 'Notification', id: string, title: string, content?: string | null, type?: string | null, read?: boolean | null, createdAt: any }> };

export type BookingEventNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BookingEventNotificationTemplatesQuery = { __typename?: 'Query', templates: Array<{ __typename?: 'BookingEventNotificationTemplate', id: string, event: BookingEvent, type: MessageType, enabled?: boolean | null, deleted?: boolean | null, recipient: MessageRecipient, messageTemplate?: any | null, defaultTemplate?: any | null, services: Array<{ __typename?: 'Service', id: string, name: string }> }>, services?: Array<{ __typename?: 'Service', id: string, name: string, enabled?: boolean | null }> | null, business?: { __typename?: 'Business', id: string, templates?: any | null } | null };

export type CreateBookingEventNotificationTemplateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  recipient: MessageRecipient;
  type: MessageType;
  event: BookingEvent;
  services: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type CreateBookingEventNotificationTemplateMutation = { __typename?: 'Mutation', createBookingEventNotificationTemplate?: { __typename?: 'BookingEventNotificationTemplate', id: string, type: MessageType, event: BookingEvent, recipient: MessageRecipient, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services: Array<{ __typename?: 'Service', id: string }> } | null };

export type CreateFollowupMessageTemplateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  minutes: Scalars['Int']['input'];
  recipient: MessageRecipient;
  type: MessageType;
  services: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type CreateFollowupMessageTemplateMutation = { __typename?: 'Mutation', createPostAppointmentMessageTemplate?: { __typename?: 'PostAppointmentMessageTemplate', id: string, minutes: number, type: MessageType, recipient: MessageRecipient, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services: Array<{ __typename?: 'Service', id: string }> } | null };

export type CreateReminderTemplateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  minutes: Scalars['Int']['input'];
  type: ReminderType;
  resourceType: ReminderResourceType;
  services: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type CreateReminderTemplateMutation = { __typename?: 'Mutation', createReminderTemplate?: { __typename?: 'ReminderTemplate', id: string, minutes: number, type: ReminderType, resourceType: ReminderResourceType, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services?: Array<{ __typename?: 'Service', id: string }> | null } | null };

export type FollowupNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type FollowupNotificationTemplatesQuery = { __typename?: 'Query', postBookingTemplates: Array<{ __typename?: 'PostAppointmentMessageTemplate', id: string, minutes: number, type: MessageType, enabled?: boolean | null, deleted?: boolean | null, recipient: MessageRecipient, messageTemplate?: any | null, defaultTemplate?: any | null, services: Array<{ __typename?: 'Service', id: string, name: string }> }>, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null, business?: { __typename?: 'Business', id: string, templates?: any | null } | null, defaultMessageTemplates?: { __typename?: 'DefaultNotificationsTemplatesPayload', templates?: any | null } | null };

export type ReminderNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ReminderNotificationTemplatesQuery = { __typename?: 'Query', reminders: Array<{ __typename?: 'ReminderTemplate', id: string, minutes: number, type: ReminderType, resourceType: ReminderResourceType, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null, defaultTemplate?: any | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null }>, business?: { __typename?: 'Business', id: string, templates?: any | null } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null, defaultMessageTemplates?: { __typename?: 'DefaultNotificationsTemplatesPayload', templates?: any | null } | null };

export type UpdateBookingEventNotificationTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
}>;


export type UpdateBookingEventNotificationTemplateMutation = { __typename?: 'Mutation', updateBookingEventNotificationTemplate?: { __typename?: 'BookingEventNotificationTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type UpdatePostAppointmentMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
}>;


export type UpdatePostAppointmentMessageTemplateMutation = { __typename?: 'Mutation', updatePostAppointmentMessageTemplate?: { __typename?: 'PostAppointmentMessageTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type UpdateReminderTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  messageTemplate?: InputMaybe<Scalars['Json']['input']>;
}>;


export type UpdateReminderTemplateMutation = { __typename?: 'Mutation', updateReminderTemplate?: { __typename?: 'ReminderTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type OutgoingWebhooksQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type OutgoingWebhooksQuery = { __typename?: 'Query', outgoingWebhooks: Array<{ __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean }> };

export type OutgoingWebhookQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  outgoingWebhookId: Scalars['ID']['input'];
  eventsLimit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type OutgoingWebhookQuery = { __typename?: 'Query', outgoingWebhook?: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean, events: Array<{ __typename?: 'Event', id: string, name: string, data: any, createdAt: any, outgoingWebhookDeliveries: Array<{ __typename?: 'OutgoingWebhookDelivery', id: string, duration?: number | null, requestHeaders: any, requestPayload: any, responseStatus?: number | null, responseHeaders?: any | null, responsePayload?: any | null, event: { __typename?: 'Event', id: string }, outgoingWebhook: { __typename?: 'OutgoingWebhook', id: string } }> }> } | null };

export type CreateOutgoingWebhookMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  endpoint: Scalars['String']['input'];
}>;


export type CreateOutgoingWebhookMutation = { __typename?: 'Mutation', createOutgoingWebhook: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean } };

export type UpdateOutgoingWebhookMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateOutgoingWebhookMutation = { __typename?: 'Mutation', updateOutgoingWebhook: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean } };

export type DeleteOutgoingWebhookMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOutgoingWebhookMutation = { __typename?: 'Mutation', deleteOutgoingWebhook: boolean };

export type AddInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
}>;


export type AddInvoiceItemMutation = { __typename?: 'Mutation', addInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type CreateDraftInvoiceMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateDraftInvoiceMutation = { __typename?: 'Mutation', createDraftInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type DeleteDraftInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;


export type DeleteDraftInvoiceMutation = { __typename?: 'Mutation', deleted: boolean };

export type DeleteInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  invoiceItemId: Scalars['ID']['input'];
}>;


export type DeleteInvoiceItemMutation = { __typename?: 'Mutation', deleteInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type FinalizeInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;


export type FinalizeInvoiceMutation = { __typename?: 'Mutation', finalizeInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvoiceQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type InvoiceFieldsFragment = { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null };

export type PatientInvoicesQueryVariables = Exact<{
  patientId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PatientInvoicesQuery = { __typename?: 'Query', count: number, invoices: Array<{ __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, status?: InvoiceStatus | null, currency: string, dueDate?: any | null, amountTotal?: number | null, amountOutstanding?: number | null }> };

export type UpdateDraftInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDraftInvoiceMutation = { __typename?: 'Mutation', updateDraftInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type UpdateInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  invoiceItemId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
}>;


export type UpdateInvoiceItemMutation = { __typename?: 'Mutation', updateInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type VoidInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;


export type VoidInvoiceMutation = { __typename?: 'Mutation', voidInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type SendPatientIntakeInviteMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  body: Scalars['String']['input'];
  formList: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SendPatientIntakeInviteMutation = { __typename?: 'Mutation', success: boolean };

export type SendPortalInviteMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  body: Scalars['String']['input'];
}>;


export type SendPortalInviteMutation = { __typename?: 'Mutation', success: boolean };

export type SelectPatientsListQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type SelectPatientsListQuery = { __typename?: 'Query', patients: Array<{ __typename?: 'Patient', id: string, name: string, email: string }> };

export type SelectServicesListQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type SelectServicesListQuery = { __typename?: 'Query', services?: Array<{ __typename?: 'Service', id: string, name: string, description?: string | null, durations?: any | null }> | null };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteServiceCategory?: { __typename?: 'ServiceCategory', id: string } | null };

export type ServiceCategoriesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ServiceCategoriesQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, name: string, serviceCategories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null } };

export type AllBusinessServicesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type AllBusinessServicesQuery = { __typename?: 'Query', count: number, services?: Array<{ __typename?: 'Service', id: string, alias?: string | null, name: string, description?: string | null, durations?: any | null, currency: string, enabled?: boolean | null, locationType?: string | null, locationValue?: string | null, bookingPageUrl?: string | null, business: { __typename?: 'Business', id: string, alias: string }, staffs?: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null, picture?: { __typename?: 'File', id: string, url: string } | null }> | null };

export type BusinessServiceQueryVariables = Exact<{
  serviceId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BusinessServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, alias?: string | null, name: string, deleted?: boolean | null, description?: string | null, durations?: any | null, currency: string, leadTime: number, cancelLeadTime?: number | null, timeIncrement: number, workingHours?: any | null, availabilityLimitDays?: number | null, locationType?: string | null, locationValue?: string | null, recurrence?: any | null, enabled?: boolean | null, requiresStripePayment?: boolean | null, bookingsPerTimeslot?: number | null, bookingsRequireApproval?: boolean | null, hideTimeslotsSeats?: boolean | null, bookingPageVisibility?: string | null, allowGuests?: boolean | null, locationOverridesStaffs?: boolean | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, requiredAdditionalStaffCount?: number | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null, staffs?: Array<{ __typename?: 'Staff', id: string, name?: string | null, user?: { __typename?: 'User', id: string } | null }> | null, additionalStaff?: Array<{ __typename?: 'Staff', id: string }> | null, picture?: { __typename?: 'File', id: string, url: string } | null, location?: { __typename?: 'Location', id: string, name: string, address?: string | null } | null } | null };

export type BusinessServicesNamesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type BusinessServicesNamesQuery = { __typename?: 'Query', count: number, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null };

export type CreateServiceMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  durations: Scalars['Json']['input'];
  currency: Scalars['String']['input'];
}>;


export type CreateServiceMutation = { __typename?: 'Mutation', createService?: { __typename?: 'Service', id: string, name: string, business: { __typename?: 'Business', id: string, servicesCount?: number | null } } | null };

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createServiceCategory?: { __typename?: 'ServiceCategory', id: string, name: string, business: { __typename?: 'Business', id: string } } | null };

export type DeleteServiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteServiceMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, business: { __typename?: 'Business', id: string, servicesCount?: number | null } } | null };

export type DuplicateServiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DuplicateServiceMutation = { __typename?: 'Mutation', service?: { __typename?: 'Service', id: string } | null };

export type ServiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, name: string, durations?: any | null } | null };

export type ServiceWorkingHoursQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ServiceWorkingHoursQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, name: string, workingHours?: any | null, business: { __typename?: 'Business', id: string, timezone?: string | null, workingHours: any } } | null };

export type UpdateServiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  alias?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  durations?: InputMaybe<Scalars['Json']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  staffs?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  leadTime?: InputMaybe<Scalars['Int']['input']>;
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['Json']['input']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']['input']>;
  workingHours?: InputMaybe<Scalars['Json']['input']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']['input']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']['input']>;
  additionalStaff?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']['input']>;
  bookingPageVisibility?: InputMaybe<Scalars['String']['input']>;
  allowGuests?: InputMaybe<Scalars['Boolean']['input']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']['input']>;
  cancelLeadTime?: InputMaybe<Scalars['Int']['input']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']['input']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']['input']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UpdateServiceMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, alias?: string | null, name: string, description?: string | null, currency: string, durations?: any | null, leadTime: number, timeIncrement: number, locationType?: string | null, locationValue?: string | null, recurrence?: any | null, requiresStripePayment?: boolean | null, workingHours?: any | null, availabilityLimitDays?: number | null, bookingsPerTimeslot?: number | null, bookingsRequireApproval?: boolean | null, hideTimeslotsSeats?: boolean | null, bookingPageVisibility?: string | null, allowGuests?: boolean | null, locationOverridesStaffs?: boolean | null, cancelLeadTime?: number | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, requiredAdditionalStaffCount?: number | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string }> | null, staffs?: Array<{ __typename?: 'Staff', id: string }> | null, additionalStaff?: Array<{ __typename?: 'Staff', id: string }> | null, picture?: { __typename?: 'File', id: string, url: string } | null, business: { __typename?: 'Business', id: string, servicesCount?: number | null }, location?: { __typename?: 'Location', id: string } | null } | null };

export type UpdateServiceStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateServiceStatusMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, enabled?: boolean | null } | null };

export type UpdateBusinessEmailSettingsMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  ownerNotificationsRecipient?: InputMaybe<OwnerNotificationsRecipient>;
  bookingNotificationsReplyTo?: InputMaybe<NotificationsReplyToType>;
  ownerNotificationsCC?: InputMaybe<Scalars['String']['input']>;
  attachICSFiles?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateBusinessEmailSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, ownerNotificationsRecipient?: OwnerNotificationsRecipient | null, bookingNotificationsReplyTo?: NotificationsReplyToType | null, ownerNotificationsCC?: string | null, attachICSFiles?: boolean | null } | null };

export type UpdateSmsSettingsMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  sendSMSFromStaffNumber?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateSmsSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, sendSMSFromStaffNumber?: boolean | null } | null };

export type AllManagedStaffQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  status?: InputMaybe<Array<StaffStatus> | StaffStatus>;
}>;


export type AllManagedStaffQuery = { __typename?: 'Query', count: number, staff: Array<{ __typename?: 'Staff', id: string, alias?: string | null, name?: string | null, enabled?: boolean | null, description?: string | null, email: string, lastInvitedAt?: any | null, roles: Array<StaffRole>, status?: StaffStatus | null, createdAt: any, bookingPageUrl?: string | null, stripeProductIds?: Array<string> | null, business: { __typename?: 'Business', id: string, alias: string }, user?: { __typename?: 'User', id: string } | null, picture?: { __typename?: 'File', id: string, url: string } | null, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'File', id: string, url: string } | null }> | null }> };

export type AllManagedStaffCompactQueryVariables = Exact<{ [key: string]: never; }>;


export type AllManagedStaffCompactQuery = { __typename?: 'Query', staff: Array<{ __typename?: 'Staff', id: string, name?: string | null }> };

export type StaffFiltersQueryVariables = Exact<{
  filterIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type StaffFiltersQuery = { __typename?: 'Query', staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null }> };

export type SelectStaffListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<StaffStatus> | StaffStatus>;
}>;


export type SelectStaffListQuery = { __typename?: 'Query', staff: Array<{ __typename?: 'Staff', id: string, name?: string | null, email: string }> };

export type CreateStaffMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  stripeProductIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateStaffMutation = { __typename?: 'Mutation', staff?: { __typename?: 'Staff', id: string, name?: string | null, description?: string | null, email: string, phoneMobile?: string | null, roles: Array<StaffRole>, stripeProductIds?: Array<string> | null } | null };

export type CreateWorkingHoursScheduleMutationVariables = Exact<{
  staffId: Scalars['ID']['input'];
  from: Scalars['DateTime']['input'];
  to?: InputMaybe<Scalars['DateTime']['input']>;
  workingHours: Scalars['Json']['input'];
}>;


export type CreateWorkingHoursScheduleMutation = { __typename?: 'Mutation', createWorkingHoursSchedule?: { __typename?: 'WorkingHoursSchedule', id: string, staff?: { __typename?: 'Staff', id: string, workingHours?: any | null, business: { __typename?: 'Business', id: string, workingHours: any, timezone?: string | null } } | null } | null };

export type DeleteStaffMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteStaffMutation = { __typename?: 'Mutation', deleteStaff?: { __typename?: 'Staff', id: string } | null };

export type DeleteWorkingHoursSchedulesMutationVariables = Exact<{
  staffId: Scalars['ID']['input'];
}>;


export type DeleteWorkingHoursSchedulesMutation = { __typename?: 'Mutation', defaultToBusinessWorkingHours?: boolean | null };

export type ResendStaffInviteMutationVariables = Exact<{
  staffId: Scalars['ID']['input'];
}>;


export type ResendStaffInviteMutation = { __typename?: 'Mutation', updateStaff?: { __typename?: 'Staff', id: string, enabled?: boolean | null, status?: StaffStatus | null, lastInvitedAt?: any | null, name?: string | null, email: string } | null };

export type StaffQueryVariables = Exact<{
  staffId: Scalars['ID']['input'];
}>;


export type StaffQuery = { __typename?: 'Query', staff?: { __typename?: 'Staff', id: string, alias?: string | null, name?: string | null, roles: Array<StaffRole>, enabled?: boolean | null, status?: StaffStatus | null, description?: string | null, email: string, occupation?: string | null, phoneMobile?: string | null, timezone?: string | null, bookingPageUrl?: string | null, locationType?: string | null, locationValue?: string | null, business: { __typename?: 'Business', id: string, alias: string }, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null, picture?: { __typename?: 'File', id: string, url: string } | null } | null };

export type StaffIsAvailableQueryVariables = Exact<{
  staffId: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
  date: Scalars['DateTime']['input'];
  duration: Scalars['Int']['input'];
  ignoreAppointmentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type StaffIsAvailableQuery = { __typename?: 'Query', isAvailable: boolean };

export type UpdateStaffMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  alias?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  locationValue?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  managedStaff?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  marketingMeta?: InputMaybe<Scalars['Json']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateStaffMutation = { __typename?: 'Mutation', updateStaff?: { __typename?: 'Staff', id: string, alias?: string | null, enabled?: boolean | null, status?: StaffStatus | null, name?: string | null, description?: string | null, email: string, phoneMobile?: string | null, locationType?: string | null, locationValue?: string | null, timezone?: string | null, roles: Array<StaffRole>, onboardingCompletedAt?: any | null, occupation?: string | null, marketingMeta?: any | null, picture?: { __typename?: 'File', id: string, url: string } | null, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null } | null };

export type WorkingHoursSchedulesQueryVariables = Exact<{
  staffId: Scalars['ID']['input'];
  time: Scalars['DateTime']['input'];
}>;


export type WorkingHoursSchedulesQuery = { __typename?: 'Query', staff?: { __typename?: 'Staff', id: string, name?: string | null, workingHours?: any | null, timezone?: string | null, business: { __typename?: 'Business', id: string, workingHours: any, timezone?: string | null } } | null };

export type BusinessStripeAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BusinessStripeAccountQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, onboardingCompletedAt?: any | null } | null } | null };

export type CompleteStripeConnectAccountOnboardingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CompleteStripeConnectAccountOnboardingMutation = { __typename?: 'Mutation', completeStripeConnectAccountOnboarding: { __typename?: 'StripeConnectAccount', id: string, onboardingCompletedAt?: any | null } };

export type CreateStripeConnectAccountMutationVariables = Exact<{
  country: Scalars['String']['input'];
}>;


export type CreateStripeConnectAccountMutation = { __typename?: 'Mutation', createStripeConnectAccount: { __typename?: 'StripeConnectAccountCreatePayload', success: boolean, stripeConnectAccountId?: string | null, session?: { __typename?: 'StripeConnectAccountSession', clientSecret: string, expiresAt: any } | null } };

export type CreateStripeConnectAccountSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeConnectAccountSessionMutation = { __typename?: 'Mutation', session?: { __typename?: 'StripeConnectAccountSession', clientSecret: string, expiresAt: any } | null };

export type StripeProductsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type StripeProductsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', stripeData?: any | null, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string, description: string, featureList: Array<string>, prices: Array<{ __typename?: 'StripePrice', id: string, currency: string, unitAmount: number, interval: string }> }> } | null };

export type SyncStripeCustomerMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncStripeCustomerMutation = { __typename?: 'Mutation', syncStripeCustomer: { __typename?: 'SyncStripeCustomerPayload', business: { __typename?: 'Business', id: string, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null } } };

export type AllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTagsQuery = { __typename?: 'Query', allTags: Array<{ __typename?: 'Tag', id: string, alias: string, name: string, description: string, color: string }> };

export type TagQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', id: string, alias: string, name: string, description: string, color: string } | null };

export type CreateTagMutationVariables = Exact<{
  alias: Scalars['String']['input'];
  name: Scalars['String']['input'];
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'Tag', id: string, alias: string, name: string, color: string, description: string, createdAt: any } };

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'Tag', id: string, alias: string, name: string, color: string, description: string } };

export type UpdatePatientTagsMutationVariables = Exact<{
  patientId: Scalars['ID']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdatePatientTagsMutation = { __typename?: 'Mutation', setPatientTags: { __typename?: 'Patient', id: string, tagIds: Array<string> } };

export type SetAppointmentTagsMutationVariables = Exact<{
  appointmentId: Scalars['ID']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SetAppointmentTagsMutation = { __typename?: 'Mutation', setAppointmentTags: { __typename?: 'Appointment', id: string, tagIds?: Array<string> | null } };

export type ThirdPartyConnectionsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
}>;


export type ThirdPartyConnectionsQuery = { __typename?: 'Query', businessZoomConnections: Array<{ __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string }>, staffZoomConnections: Array<{ __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string }> };

export type CreateThirdPartyConnectionOAuthSessionMutationVariables = Exact<{
  provider: ThirdPartyConnectionProvider;
  resourceType: ThirdPartyConnectionResourceType;
  redirectUrl: Scalars['String']['input'];
}>;


export type CreateThirdPartyConnectionOAuthSessionMutation = { __typename?: 'Mutation', createThirdPartyConnectionOAuthSession: { __typename?: 'ThirdPartyConnectionOAuthSession', redirectUrl: string } };

export type UpdateThirdPartyConnectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateThirdPartyConnectionMutation = { __typename?: 'Mutation', updateThirdPartyConnection: { __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string, staff?: { __typename?: 'Staff', id: string, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } | null, business: { __typename?: 'Business', id: string, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } } };

export type DeleteThirdPartyConnectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteThirdPartyConnectionMutation = { __typename?: 'Mutation', deleteThirdPartyConnection?: boolean | null };

export type CreateTimeBlockerMutationVariables = Exact<{
  staffId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateTimeBlockerMutation = { __typename?: 'Mutation', createTimeBlocker?: { __typename?: 'TimeBlocker', id: string, startDate: any, endDate: any, reason?: string | null, business?: { __typename?: 'Business', id: string } | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null } | null };

export type CalendarTimeBlockersQueryVariables = Exact<{
  dateStart: Scalars['DateTime']['input'];
  dateEnd: Scalars['DateTime']['input'];
  staffsIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type CalendarTimeBlockersQuery = { __typename?: 'Query', timeBlockers: Array<{ __typename?: 'TimeBlocker', id: string, startDate: any, endDate: any, reason?: string | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null }> };

export type UpdateSeatsMutationVariables = Exact<{
  productQuantities: Scalars['Json']['input'];
  interval: StripeInterval;
}>;


export type UpdateSeatsMutation = { __typename?: 'Mutation', updateSeats: { __typename?: 'UpdateSeatsResponse', business: { __typename?: 'Business', id: string, stripeData?: any | null }, checkoutSession?: { __typename?: 'StripeCheckoutSession', id: string, url: string } | null } };

export type UpdateUserPersonalDataMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type UpdateUserPersonalDataMutation = { __typename?: 'Mutation', updateUserPersonalData: { __typename?: 'User', id: string, name?: string | null } };

export type WorkingHoursSlotsQueryVariables = Exact<{
  staffIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  timeIncrement?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
}>;


export type WorkingHoursSlotsQuery = { __typename?: 'Query', workingHoursSlots: { __typename?: 'WorkingHoursSlots', slots: Array<Array<any>>, staffSlots?: any | null } };

export const FieldsFragmentDoc = gql`
    fragment Fields on Business {
  id
  alias
  name
  bookingPageUrl
  timezone
  website
  logo {
    id
    url
  }
  staffRoles
  availableFeatures
  enabled
  dateFormat
  usage
  zoomConnection {
    id
  }
  teamsConnection {
    id
  }
  bookingForm {
    id
  }
  reschedulingForm {
    id
  }
  stripeConnectAccount {
    id
    stripeAccountId
    onboardingCompletedAt
  }
  marketingMeta
  onboardingCompletedAt
  primaryDomain
  primaryDomainVerifiedAt
  stripeCustomerId
  stripeSubscriptionId
  stripeData
  stripeProducts {
    id
    name
  }
  remainingTrialDays
  subscriptionStatus
  invoicesTaxBehaviour
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment FileFields on File {
  id
  patientId
  name
  url
  size
  contentType
  createdAt
  updatedAt
}
    `;
export const FormFieldsFragmentDoc = gql`
    fragment FormFields on Form {
  id
  url
  title
  alias
  description
  thumbnail
  category
  useAsTemplate
  createdAt
  updatedAt
  status
  steps
  submissionsCount
  unreadSubmissionsCount
}
    `;
export const FormEditorFieldsFragmentDoc = gql`
    fragment FormEditorFields on Form {
  ...FormFields
  settings
}
    ${FormFieldsFragmentDoc}`;
export const MessageTemplateFieldsFragmentDoc = gql`
    fragment MessageTemplateFields on MessageTemplate {
  id
  createdAt
  name
  description
  content
}
    `;
export const ThreadFieldsFragmentDoc = gql`
    fragment ThreadFields on MessageThread {
  id
  archivedAt
  createdAt
  subject
  messagesCount
  unreadMessagesCount
  hasDraftMessage
  patient {
    id
    name
  }
  staffs {
    id
    name
    picture {
      id
      url
    }
  }
}
    `;
export const NoteTemplateFieldsFragmentDoc = gql`
    fragment NoteTemplateFields on NoteTemplate {
  id
  createdAt
  name
  content
}
    `;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on Invoice {
  id
  number
  createdAt
  updatedAt
  patient {
    id
    name
    email
  }
  currency
  appointment {
    id
  }
  status
  voidedBy {
    id
    name
  }
  business {
    id
    defaultInvoiceDaysUntilDue
  }
  voidedAt
  dueDate
  sentAt
  amountTotal
  amountSubtotal
  amountOutstanding
  amountDiscounted
  amountExcludingTax
  amountTax
  coupon {
    id
    code
    name
  }
  markedUncollectibleAt
  markedUncollectibleBy {
    id
    name
  }
  finalizedAt
  items {
    id
    createdAt
    updatedAt
    description
    quantity
    unitAmount
    amount
  }
}
    `;
export const CreateAdHocAppointmentDocument = gql`
    mutation createAdHocAppointment($title: String!, $start: DateTime!, $end: DateTime!, $staffId: ID!, $patientId: ID!, $locationType: String, $locationValue: String, $locationId: ID) {
  createAdHocAppointment(
    title: $title
    start: $start
    end: $end
    staffId: $staffId
    patientId: $patientId
    locationType: $locationType
    locationValue: $locationValue
    locationId: $locationId
  ) {
    id
  }
}
    `;
export type CreateAdHocAppointmentMutationFn = Apollo.MutationFunction<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>;

/**
 * __useCreateAdHocAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAdHocAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocAppointmentMutation, { data, loading, error }] = useCreateAdHocAppointmentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCreateAdHocAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>(CreateAdHocAppointmentDocument, options);
      }
export type CreateAdHocAppointmentMutationHookResult = ReturnType<typeof useCreateAdHocAppointmentMutation>;
export type CreateAdHocAppointmentMutationResult = Apollo.MutationResult<CreateAdHocAppointmentMutation>;
export type CreateAdHocAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>;
export const CreateAdHocServiceDocument = gql`
    mutation createAdHocService($businessId: ID!, $staffId: ID!, $timeslots: Json!, $timeIncrement: Int!, $name: String!, $duration: Int!, $maxAppointments: Int!, $locationId: ID, $locationType: String, $locationValue: String) {
  adHocService: createAdHocService(
    businessId: $businessId
    staffId: $staffId
    timeslots: $timeslots
    timeIncrement: $timeIncrement
    name: $name
    duration: $duration
    maxAppointments: $maxAppointments
    locationType: $locationType
    locationValue: $locationValue
    locationId: $locationId
  ) {
    id
  }
}
    `;
export type CreateAdHocServiceMutationFn = Apollo.MutationFunction<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>;

/**
 * __useCreateAdHocServiceMutation__
 *
 * To run a mutation, you first call `useCreateAdHocServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocServiceMutation, { data, loading, error }] = useCreateAdHocServiceMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      staffId: // value for 'staffId'
 *      timeslots: // value for 'timeslots'
 *      timeIncrement: // value for 'timeIncrement'
 *      name: // value for 'name'
 *      duration: // value for 'duration'
 *      maxAppointments: // value for 'maxAppointments'
 *      locationId: // value for 'locationId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *   },
 * });
 */
export function useCreateAdHocServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>(CreateAdHocServiceDocument, options);
      }
export type CreateAdHocServiceMutationHookResult = ReturnType<typeof useCreateAdHocServiceMutation>;
export type CreateAdHocServiceMutationResult = Apollo.MutationResult<CreateAdHocServiceMutation>;
export type CreateAdHocServiceMutationOptions = Apollo.BaseMutationOptions<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>;
export const AllApiKeysDocument = gql`
    query allApiKeys {
  allApiKeys {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
    invalidatedAt
  }
}
    `;

/**
 * __useAllApiKeysQuery__
 *
 * To run a query within a React component, call `useAllApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<AllApiKeysQuery, AllApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllApiKeysQuery, AllApiKeysQueryVariables>(AllApiKeysDocument, options);
      }
export function useAllApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllApiKeysQuery, AllApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllApiKeysQuery, AllApiKeysQueryVariables>(AllApiKeysDocument, options);
        }
export function useAllApiKeysSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllApiKeysQuery, AllApiKeysQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllApiKeysQuery, AllApiKeysQueryVariables>(AllApiKeysDocument, options);
        }
export type AllApiKeysQueryHookResult = ReturnType<typeof useAllApiKeysQuery>;
export type AllApiKeysLazyQueryHookResult = ReturnType<typeof useAllApiKeysLazyQuery>;
export type AllApiKeysSuspenseQueryHookResult = ReturnType<typeof useAllApiKeysSuspenseQuery>;
export type AllApiKeysQueryResult = Apollo.QueryResult<AllApiKeysQuery, AllApiKeysQueryVariables>;
export const AllBusinessPatientsDocument = gql`
    query allBusinessPatients($businessId: ID!, $limit: Int!, $skip: Int, $search: String, $sortBy: PatientsOrderByInput, $status: PatientStatus) {
  patients: businessPatients(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
    sortBy: $sortBy
    status: $status
  ) {
    id
    name
    preferredName
    email
    phoneMobile
    patientType
    dob
    age
    gender
    archivedAt
  }
  count: businessPatientsCount(
    businessId: $businessId
    search: $search
    status: $status
  )
}
    `;

/**
 * __useAllBusinessPatientsQuery__
 *
 * To run a query within a React component, call `useAllBusinessPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBusinessPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBusinessPatientsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAllBusinessPatientsQuery(baseOptions: Apollo.QueryHookOptions<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables> & ({ variables: AllBusinessPatientsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>(AllBusinessPatientsDocument, options);
      }
export function useAllBusinessPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>(AllBusinessPatientsDocument, options);
        }
export function useAllBusinessPatientsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>(AllBusinessPatientsDocument, options);
        }
export type AllBusinessPatientsQueryHookResult = ReturnType<typeof useAllBusinessPatientsQuery>;
export type AllBusinessPatientsLazyQueryHookResult = ReturnType<typeof useAllBusinessPatientsLazyQuery>;
export type AllBusinessPatientsSuspenseQueryHookResult = ReturnType<typeof useAllBusinessPatientsSuspenseQuery>;
export type AllBusinessPatientsQueryResult = Apollo.QueryResult<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>;
export const AnalyticsSmsDocument = gql`
    query analyticsSMS($dateFrom: DateTime, $dateTo: DateTime) {
  analytics {
    sms(dateFrom: $dateFrom, dateTo: $dateTo) {
      date
      sent
      delivered
      credits
    }
  }
}
    `;

/**
 * __useAnalyticsSmsQuery__
 *
 * To run a query within a React component, call `useAnalyticsSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsSmsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useAnalyticsSmsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>(AnalyticsSmsDocument, options);
      }
export function useAnalyticsSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>(AnalyticsSmsDocument, options);
        }
export function useAnalyticsSmsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>(AnalyticsSmsDocument, options);
        }
export type AnalyticsSmsQueryHookResult = ReturnType<typeof useAnalyticsSmsQuery>;
export type AnalyticsSmsLazyQueryHookResult = ReturnType<typeof useAnalyticsSmsLazyQuery>;
export type AnalyticsSmsSuspenseQueryHookResult = ReturnType<typeof useAnalyticsSmsSuspenseQuery>;
export type AnalyticsSmsQueryResult = Apollo.QueryResult<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>;
export const AnalyticsAppointmentsDocument = gql`
    query analyticsAppointments($dateFrom: DateTime, $dateTo: DateTime, $staffId: ID) {
  analytics {
    appointments(dateFrom: $dateFrom, dateTo: $dateTo, staffId: $staffId) {
      date
      created
      cancelled
    }
  }
}
    `;

/**
 * __useAnalyticsAppointmentsQuery__
 *
 * To run a query within a React component, call `useAnalyticsAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsAppointmentsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useAnalyticsAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>(AnalyticsAppointmentsDocument, options);
      }
export function useAnalyticsAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>(AnalyticsAppointmentsDocument, options);
        }
export function useAnalyticsAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>(AnalyticsAppointmentsDocument, options);
        }
export type AnalyticsAppointmentsQueryHookResult = ReturnType<typeof useAnalyticsAppointmentsQuery>;
export type AnalyticsAppointmentsLazyQueryHookResult = ReturnType<typeof useAnalyticsAppointmentsLazyQuery>;
export type AnalyticsAppointmentsSuspenseQueryHookResult = ReturnType<typeof useAnalyticsAppointmentsSuspenseQuery>;
export type AnalyticsAppointmentsQueryResult = Apollo.QueryResult<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>;
export const AnalyticsEventsDocument = gql`
    query analyticsEvents($dateFrom: DateTime, $dateTo: DateTime) {
  analytics {
    events(dateFrom: $dateFrom, dateTo: $dateTo) {
      date
      all
      appointmentCreated
      appointmentUpdated
      appointmentDeleted
      businessUpdated
      formCreated
      formUpdated
      formDeleted
      patientCreated
      patientUpdated
      patientDeleted
      serviceCreated
      serviceUpdated
      serviceDeleted
      staffCreated
      staffUpdated
      staffDeleted
    }
  }
}
    `;

/**
 * __useAnalyticsEventsQuery__
 *
 * To run a query within a React component, call `useAnalyticsEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsEventsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useAnalyticsEventsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>(AnalyticsEventsDocument, options);
      }
export function useAnalyticsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>(AnalyticsEventsDocument, options);
        }
export function useAnalyticsEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>(AnalyticsEventsDocument, options);
        }
export type AnalyticsEventsQueryHookResult = ReturnType<typeof useAnalyticsEventsQuery>;
export type AnalyticsEventsLazyQueryHookResult = ReturnType<typeof useAnalyticsEventsLazyQuery>;
export type AnalyticsEventsSuspenseQueryHookResult = ReturnType<typeof useAnalyticsEventsSuspenseQuery>;
export type AnalyticsEventsQueryResult = Apollo.QueryResult<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>;
export const AppointmentDocument = gql`
    query appointment($id: ID!) {
  appointment: Appointment(id: $id) {
    id
    date
    duration
    approved
    locationType
    locationValue
    currency
    price
    answers
    paymentProvider
    rrule
    originalDate
    transactionUrl
    patientVerified
    staff {
      id
      name
      email
    }
    additionalStaff {
      id
      name
    }
    patient {
      id
      name
      email
      phoneMobile
    }
    service {
      id
      name
      description
      durations
    }
    location {
      id
      name
    }
    tagIds
  }
}
    `;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables> & ({ variables: AppointmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export function useAppointmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentSuspenseQueryHookResult = ReturnType<typeof useAppointmentSuspenseQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const ApproveAppointmentDocument = gql`
    mutation approveAppointment($id: ID!, $ignoreBusyTimes: Boolean) {
  approveAppointment(id: $id, ignoreBusyTimes: $ignoreBusyTimes) {
    id
    approved
  }
}
    `;
export type ApproveAppointmentMutationFn = Apollo.MutationFunction<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;

/**
 * __useApproveAppointmentMutation__
 *
 * To run a mutation, you first call `useApproveAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAppointmentMutation, { data, loading, error }] = useApproveAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useApproveAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>(ApproveAppointmentDocument, options);
      }
export type ApproveAppointmentMutationHookResult = ReturnType<typeof useApproveAppointmentMutation>;
export type ApproveAppointmentMutationResult = Apollo.MutationResult<ApproveAppointmentMutation>;
export type ApproveAppointmentMutationOptions = Apollo.BaseMutationOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation cancelAppointment($id: ID!, $futureOccurrences: Boolean, $occurrenceDate: DateTime) {
  deleteAppointment(
    id: $id
    futureOccurrences: $futureOccurrences
    occurrenceDate: $occurrenceDate
  ) {
    id
    cancelled
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      futureOccurrences: // value for 'futureOccurrences'
 *      occurrenceDate: // value for 'occurrenceDate'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const CancelGroupBookingDocument = gql`
    mutation cancelGroupBooking($groupBookingId: String!) {
  cancelGroupBooking(groupBookingId: $groupBookingId) {
    id
  }
}
    `;
export type CancelGroupBookingMutationFn = Apollo.MutationFunction<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>;

/**
 * __useCancelGroupBookingMutation__
 *
 * To run a mutation, you first call `useCancelGroupBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGroupBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGroupBookingMutation, { data, loading, error }] = useCancelGroupBookingMutation({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *   },
 * });
 */
export function useCancelGroupBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>(CancelGroupBookingDocument, options);
      }
export type CancelGroupBookingMutationHookResult = ReturnType<typeof useCancelGroupBookingMutation>;
export type CancelGroupBookingMutationResult = Apollo.MutationResult<CancelGroupBookingMutation>;
export type CancelGroupBookingMutationOptions = Apollo.BaseMutationOptions<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation createAppointment($date: DateTime!, $duration: Int!, $staffId: ID!, $patientId: ID!, $serviceId: ID!, $requestUrl: String, $ignoreBusyTimes: Boolean) {
  createAppointment(
    date: $date
    duration: $duration
    staffId: $staffId
    patient: {connect: {id: $patientId}}
    serviceId: $serviceId
    requestUrl: $requestUrl
    ignoreBusyTimes: $ignoreBusyTimes
  ) {
    id
    date
    price
    currency
    duration
    business {
      id
    }
    staff {
      id
    }
    patient {
      id
    }
    service {
      id
    }
    rrule
    originalDate
    requestUrl
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      serviceId: // value for 'serviceId'
 *      requestUrl: // value for 'requestUrl'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const GroupBookingAppointmentsDocument = gql`
    query groupBookingAppointments($groupBookingId: ID!) {
  appointments: GroupBookingAppointments(groupBookingId: $groupBookingId) {
    id
    date
    duration
    approved
    cancelled
    staff {
      id
      name
      email
    }
    patient {
      id
      name
      email
      phoneMobile
    }
    service {
      id
      name
    }
  }
}
    `;

/**
 * __useGroupBookingAppointmentsQuery__
 *
 * To run a query within a React component, call `useGroupBookingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBookingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBookingAppointmentsQuery({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *   },
 * });
 */
export function useGroupBookingAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables> & ({ variables: GroupBookingAppointmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>(GroupBookingAppointmentsDocument, options);
      }
export function useGroupBookingAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>(GroupBookingAppointmentsDocument, options);
        }
export function useGroupBookingAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>(GroupBookingAppointmentsDocument, options);
        }
export type GroupBookingAppointmentsQueryHookResult = ReturnType<typeof useGroupBookingAppointmentsQuery>;
export type GroupBookingAppointmentsLazyQueryHookResult = ReturnType<typeof useGroupBookingAppointmentsLazyQuery>;
export type GroupBookingAppointmentsSuspenseQueryHookResult = ReturnType<typeof useGroupBookingAppointmentsSuspenseQuery>;
export type GroupBookingAppointmentsQueryResult = Apollo.QueryResult<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>;
export const UpdateAppointmentDocument = gql`
    mutation updateAppointment($id: ID!, $date: DateTime!, $duration: Int, $staffId: ID, $patientId: ID, $serviceId: ID, $singleOccurrence: Boolean, $originalDate: DateTime, $futureOccurrences: Boolean, $ignoreBusyTimes: Boolean) {
  updateAppointment(
    id: $id
    date: $date
    duration: $duration
    staffId: $staffId
    patient: {connect: {id: $patientId}}
    serviceId: $serviceId
    singleOccurrence: $singleOccurrence
    originalDate: $originalDate
    futureOccurrences: $futureOccurrences
    ignoreBusyTimes: $ignoreBusyTimes
  ) {
    id
    date
    price
    currency
    duration
    business {
      id
    }
    staff {
      id
    }
    patient {
      id
    }
    service {
      id
    }
    rrule
    originalDate
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      serviceId: // value for 'serviceId'
 *      singleOccurrence: // value for 'singleOccurrence'
 *      originalDate: // value for 'originalDate'
 *      futureOccurrences: // value for 'futureOccurrences'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdateGroupBookingDocument = gql`
    mutation updateGroupBooking($groupBookingId: String!, $date: DateTime!) {
  updateGroupBooking(groupBookingId: $groupBookingId, date: $date) {
    id
    date
    groupBookingId
  }
}
    `;
export type UpdateGroupBookingMutationFn = Apollo.MutationFunction<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>;

/**
 * __useUpdateGroupBookingMutation__
 *
 * To run a mutation, you first call `useUpdateGroupBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupBookingMutation, { data, loading, error }] = useUpdateGroupBookingMutation({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateGroupBookingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>(UpdateGroupBookingDocument, options);
      }
export type UpdateGroupBookingMutationHookResult = ReturnType<typeof useUpdateGroupBookingMutation>;
export type UpdateGroupBookingMutationResult = Apollo.MutationResult<UpdateGroupBookingMutation>;
export type UpdateGroupBookingMutationOptions = Apollo.BaseMutationOptions<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>;
export const AssignStaffStripeProductIdsDocument = gql`
    mutation assignStaffStripeProductIds($staffId: ID!, $productIds: [String!]!) {
  assignStaffStripeProductIds(staffId: $staffId, productIds: $productIds) {
    id
    stripeProductIds
    availableBoolFeatures
    business {
      id
      stripeCustomerId
      stripeSubscriptionId
      stripeData
    }
  }
}
    `;
export type AssignStaffStripeProductIdsMutationFn = Apollo.MutationFunction<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>;

/**
 * __useAssignStaffStripeProductIdsMutation__
 *
 * To run a mutation, you first call `useAssignStaffStripeProductIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStaffStripeProductIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStaffStripeProductIdsMutation, { data, loading, error }] = useAssignStaffStripeProductIdsMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useAssignStaffStripeProductIdsMutation(baseOptions?: Apollo.MutationHookOptions<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>(AssignStaffStripeProductIdsDocument, options);
      }
export type AssignStaffStripeProductIdsMutationHookResult = ReturnType<typeof useAssignStaffStripeProductIdsMutation>;
export type AssignStaffStripeProductIdsMutationResult = Apollo.MutationResult<AssignStaffStripeProductIdsMutation>;
export type AssignStaffStripeProductIdsMutationOptions = Apollo.BaseMutationOptions<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>;
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($auth0Code: String!, $timezone: String, $signupKey: String, $hubspotCookie: String) {
  authenticateUser(
    auth0Code: $auth0Code
    timezone: $timezone
    signupKey: $signupKey
    hubspotCookie: $hubspotCookie
  ) {
    id
    token
    userIsNew
    forcedSessionId
    redirectUrl
    user {
      id
    }
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      auth0Code: // value for 'auth0Code'
 *      timezone: // value for 'timezone'
 *      signupKey: // value for 'signupKey'
 *      hubspotCookie: // value for 'hubspotCookie'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const CurrentBusinessProductsDocument = gql`
    query currentBusinessProducts($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    id
    stripeProducts {
      id
      name
    }
  }
}
    `;

/**
 * __useCurrentBusinessProductsQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessProductsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentBusinessProductsQuery(baseOptions: Apollo.QueryHookOptions<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables> & ({ variables: CurrentBusinessProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>(CurrentBusinessProductsDocument, options);
      }
export function useCurrentBusinessProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>(CurrentBusinessProductsDocument, options);
        }
export function useCurrentBusinessProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>(CurrentBusinessProductsDocument, options);
        }
export type CurrentBusinessProductsQueryHookResult = ReturnType<typeof useCurrentBusinessProductsQuery>;
export type CurrentBusinessProductsLazyQueryHookResult = ReturnType<typeof useCurrentBusinessProductsLazyQuery>;
export type CurrentBusinessProductsSuspenseQueryHookResult = ReturnType<typeof useCurrentBusinessProductsSuspenseQuery>;
export type CurrentBusinessProductsQueryResult = Apollo.QueryResult<CurrentBusinessProductsQuery, CurrentBusinessProductsQueryVariables>;
export const BusinessDocument = gql`
    query business($id: ID!) {
  business: Business(id: $id) {
    ...Fields
  }
}
    ${FieldsFragmentDoc}`;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessQuery(baseOptions: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables> & ({ variables: BusinessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
      }
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export function useBusinessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessSuspenseQueryHookResult = ReturnType<typeof useBusinessSuspenseQuery>;
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>;
export const CurrentBusinessDocument = gql`
    query currentBusiness($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    ...Fields
  }
}
    ${FieldsFragmentDoc}`;

/**
 * __useCurrentBusinessQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentBusinessQuery(baseOptions: Apollo.QueryHookOptions<CurrentBusinessQuery, CurrentBusinessQueryVariables> & ({ variables: CurrentBusinessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentBusinessQuery, CurrentBusinessQueryVariables>(CurrentBusinessDocument, options);
      }
export function useCurrentBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessQuery, CurrentBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentBusinessQuery, CurrentBusinessQueryVariables>(CurrentBusinessDocument, options);
        }
export function useCurrentBusinessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentBusinessQuery, CurrentBusinessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentBusinessQuery, CurrentBusinessQueryVariables>(CurrentBusinessDocument, options);
        }
export type CurrentBusinessQueryHookResult = ReturnType<typeof useCurrentBusinessQuery>;
export type CurrentBusinessLazyQueryHookResult = ReturnType<typeof useCurrentBusinessLazyQuery>;
export type CurrentBusinessSuspenseQueryHookResult = ReturnType<typeof useCurrentBusinessSuspenseQuery>;
export type CurrentBusinessQueryResult = Apollo.QueryResult<CurrentBusinessQuery, CurrentBusinessQueryVariables>;
export const BusinessSettingsDocument = gql`
    query businessSettings($id: ID!) {
  business: Business(id: $id) {
    id
    alias
    name
    email
    website
    timezone
    currency
    dateFormat
    physicalAddress
    bufferBeforeMinutes
    bufferAfterMinutes
    allowStaffApprovals
    availabilityLimitDays
    availableFeatures
    workingHours
    ownerNotificationsRecipient
    ownerNotificationsCC
    bookingNotificationsReplyTo
    attachICSFiles
    sendSMSFromStaffNumber
    color
    logo {
      id
      url
    }
    logoPosition
    allowChoosingStaff
    allowNoStaffPreference
    marketingMeta
    defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour
    contactEmail
    contactPhone
    contactUrl
    privacyPolicyUrl
    termsOfServiceUrl
  }
}
    `;

/**
 * __useBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessSettingsQuery(baseOptions: Apollo.QueryHookOptions<BusinessSettingsQuery, BusinessSettingsQueryVariables> & ({ variables: BusinessSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessSettingsQuery, BusinessSettingsQueryVariables>(BusinessSettingsDocument, options);
      }
export function useBusinessSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessSettingsQuery, BusinessSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessSettingsQuery, BusinessSettingsQueryVariables>(BusinessSettingsDocument, options);
        }
export function useBusinessSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessSettingsQuery, BusinessSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessSettingsQuery, BusinessSettingsQueryVariables>(BusinessSettingsDocument, options);
        }
export type BusinessSettingsQueryHookResult = ReturnType<typeof useBusinessSettingsQuery>;
export type BusinessSettingsLazyQueryHookResult = ReturnType<typeof useBusinessSettingsLazyQuery>;
export type BusinessSettingsSuspenseQueryHookResult = ReturnType<typeof useBusinessSettingsSuspenseQuery>;
export type BusinessSettingsQueryResult = Apollo.QueryResult<BusinessSettingsQuery, BusinessSettingsQueryVariables>;
export const UpdateBusinessSettingsDocument = gql`
    mutation updateBusinessSettings($id: ID!, $enabled: Boolean, $alias: String, $name: String, $email: String, $color: String, $logoId: ID, $website: String, $currency: String, $physicalAddress: String, $timezone: String, $workingHours: Json, $dateFormat: Json, $bufferBeforeMinutes: Int, $bufferAfterMinutes: Int, $redirectAfterBookingUrl: String, $postOnRedirect: Boolean, $includeAnswersOnRedirect: Boolean, $googleTrackingId: String, $trackingPixelUrl: String, $availabilityLimitDays: Int, $allowStaffApprovals: Boolean, $logoPosition: String, $allowTimeRequests: Boolean, $timeRequestButtonLabel: String, $timeRequestText: String, $hidePrivacyButton: Boolean, $allowChoosingStaff: Boolean, $allowNoStaffPreference: Boolean, $exportedEventsTemplate: Json, $marketingMeta: Json, $onboardingCompleted: Boolean, $defaultInvoiceDaysUntilDue: Int, $autoGenerateBookingInvoices: Boolean, $bookingsRequireApprovalUntilPaid: Boolean, $invoicesTaxBehaviour: TaxBehaviour, $contactEmail: String, $contactPhone: String, $contactUrl: String, $privacyPolicyUrl: String, $termsOfServiceUrl: String) {
  updateBusiness(
    id: $id
    enabled: $enabled
    alias: $alias
    name: $name
    email: $email
    website: $website
    timezone: $timezone
    currency: $currency
    physicalAddress: $physicalAddress
    color: $color
    logoId: $logoId
    logoPosition: $logoPosition
    workingHours: $workingHours
    dateFormat: $dateFormat
    bufferBeforeMinutes: $bufferBeforeMinutes
    bufferAfterMinutes: $bufferAfterMinutes
    redirectAfterBookingUrl: $redirectAfterBookingUrl
    postOnRedirect: $postOnRedirect
    includeAnswersOnRedirect: $includeAnswersOnRedirect
    googleTrackingId: $googleTrackingId
    trackingPixelUrl: $trackingPixelUrl
    availabilityLimitDays: $availabilityLimitDays
    allowStaffApprovals: $allowStaffApprovals
    allowTimeRequests: $allowTimeRequests
    timeRequestButtonLabel: $timeRequestButtonLabel
    timeRequestText: $timeRequestText
    hidePrivacyButton: $hidePrivacyButton
    allowChoosingStaff: $allowChoosingStaff
    allowNoStaffPreference: $allowNoStaffPreference
    exportedEventsTemplate: $exportedEventsTemplate
    marketingMeta: $marketingMeta
    onboardingCompleted: $onboardingCompleted
    defaultInvoiceDaysUntilDue: $defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices: $autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid: $bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour: $invoicesTaxBehaviour
    contactEmail: $contactEmail
    contactPhone: $contactPhone
    contactUrl: $contactUrl
    privacyPolicyUrl: $privacyPolicyUrl
    termsOfServiceUrl: $termsOfServiceUrl
  ) {
    id
    enabled
    alias
    name
    email
    website
    currency
    physicalAddress
    timezone
    color
    logo {
      id
      url
    }
    logoPosition
    workingHours
    availableFeatures
    dateFormat
    bufferBeforeMinutes
    bufferAfterMinutes
    redirectAfterBookingUrl
    postOnRedirect
    includeAnswersOnRedirect
    googleTrackingId
    trackingPixelUrl
    availabilityLimitDays
    allowStaffApprovals
    allowTimeRequests
    timeRequestButtonLabel
    timeRequestText
    hidePrivacyButton
    allowChoosingStaff
    allowNoStaffPreference
    exportedEventsTemplate
    marketingMeta
    onboardingCompletedAt
    defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour
    contactEmail
    contactPhone
    contactUrl
    privacyPolicyUrl
    termsOfServiceUrl
  }
}
    `;
export type UpdateBusinessSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;

/**
 * __useUpdateBusinessSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessSettingsMutation, { data, loading, error }] = useUpdateBusinessSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      color: // value for 'color'
 *      logoId: // value for 'logoId'
 *      website: // value for 'website'
 *      currency: // value for 'currency'
 *      physicalAddress: // value for 'physicalAddress'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      dateFormat: // value for 'dateFormat'
 *      bufferBeforeMinutes: // value for 'bufferBeforeMinutes'
 *      bufferAfterMinutes: // value for 'bufferAfterMinutes'
 *      redirectAfterBookingUrl: // value for 'redirectAfterBookingUrl'
 *      postOnRedirect: // value for 'postOnRedirect'
 *      includeAnswersOnRedirect: // value for 'includeAnswersOnRedirect'
 *      googleTrackingId: // value for 'googleTrackingId'
 *      trackingPixelUrl: // value for 'trackingPixelUrl'
 *      availabilityLimitDays: // value for 'availabilityLimitDays'
 *      allowStaffApprovals: // value for 'allowStaffApprovals'
 *      logoPosition: // value for 'logoPosition'
 *      allowTimeRequests: // value for 'allowTimeRequests'
 *      timeRequestButtonLabel: // value for 'timeRequestButtonLabel'
 *      timeRequestText: // value for 'timeRequestText'
 *      hidePrivacyButton: // value for 'hidePrivacyButton'
 *      allowChoosingStaff: // value for 'allowChoosingStaff'
 *      allowNoStaffPreference: // value for 'allowNoStaffPreference'
 *      exportedEventsTemplate: // value for 'exportedEventsTemplate'
 *      marketingMeta: // value for 'marketingMeta'
 *      onboardingCompleted: // value for 'onboardingCompleted'
 *      defaultInvoiceDaysUntilDue: // value for 'defaultInvoiceDaysUntilDue'
 *      autoGenerateBookingInvoices: // value for 'autoGenerateBookingInvoices'
 *      bookingsRequireApprovalUntilPaid: // value for 'bookingsRequireApprovalUntilPaid'
 *      invoicesTaxBehaviour: // value for 'invoicesTaxBehaviour'
 *      contactEmail: // value for 'contactEmail'
 *      contactPhone: // value for 'contactPhone'
 *      contactUrl: // value for 'contactUrl'
 *      privacyPolicyUrl: // value for 'privacyPolicyUrl'
 *      termsOfServiceUrl: // value for 'termsOfServiceUrl'
 *   },
 * });
 */
export function useUpdateBusinessSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>(UpdateBusinessSettingsDocument, options);
      }
export type UpdateBusinessSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationResult = Apollo.MutationResult<UpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;
export const BusinessRoleConfigurationsDocument = gql`
    query businessRoleConfigurations($businessId: ID!) {
  businessRoleConfigurations(businessId: $businessId) {
    id
    name
    description
    scopes
  }
}
    `;

/**
 * __useBusinessRoleConfigurationsQuery__
 *
 * To run a query within a React component, call `useBusinessRoleConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessRoleConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessRoleConfigurationsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessRoleConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables> & ({ variables: BusinessRoleConfigurationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>(BusinessRoleConfigurationsDocument, options);
      }
export function useBusinessRoleConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>(BusinessRoleConfigurationsDocument, options);
        }
export function useBusinessRoleConfigurationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>(BusinessRoleConfigurationsDocument, options);
        }
export type BusinessRoleConfigurationsQueryHookResult = ReturnType<typeof useBusinessRoleConfigurationsQuery>;
export type BusinessRoleConfigurationsLazyQueryHookResult = ReturnType<typeof useBusinessRoleConfigurationsLazyQuery>;
export type BusinessRoleConfigurationsSuspenseQueryHookResult = ReturnType<typeof useBusinessRoleConfigurationsSuspenseQuery>;
export type BusinessRoleConfigurationsQueryResult = Apollo.QueryResult<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>;
export const CalendarConnectionsQueryDocument = gql`
    query CalendarConnectionsQuery($businessId: ID!, $redirectUrl: String!) {
  businessCalendarConnections: calendarConnections(
    resourceId: $businessId
    resourceType: BUSINESS
  ) {
    id
    provider
    accountId
    enabled
    isAuthorized
    lastSyncedAt
    importFromCalendarsIds
    calendars
    exportToCalendarId
    exportAttendees
  }
  personalCalendarConnections: staffCalendarConnections(businessId: $businessId) {
    id
    provider
    accountId
    enabled
    isAuthorized
    lastSyncedAt
    importFromCalendarsIds
    calendars
    exportToCalendarId
    exportAttendees
  }
  businessOAuthUrls: oAuthUrls(businessId: $businessId, redirectUrl: $redirectUrl) {
    google
    microsoft
  }
  personalOAuthUrls: staffOAuthUrls(
    businessId: $businessId
    redirectUrl: $redirectUrl
  ) {
    google
    microsoft
  }
}
    `;

/**
 * __useCalendarConnectionsQueryQuery__
 *
 * To run a query within a React component, call `useCalendarConnectionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarConnectionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarConnectionsQueryQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCalendarConnectionsQueryQuery(baseOptions: Apollo.QueryHookOptions<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables> & ({ variables: CalendarConnectionsQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>(CalendarConnectionsQueryDocument, options);
      }
export function useCalendarConnectionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>(CalendarConnectionsQueryDocument, options);
        }
export function useCalendarConnectionsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>(CalendarConnectionsQueryDocument, options);
        }
export type CalendarConnectionsQueryQueryHookResult = ReturnType<typeof useCalendarConnectionsQueryQuery>;
export type CalendarConnectionsQueryLazyQueryHookResult = ReturnType<typeof useCalendarConnectionsQueryLazyQuery>;
export type CalendarConnectionsQuerySuspenseQueryHookResult = ReturnType<typeof useCalendarConnectionsQuerySuspenseQuery>;
export type CalendarConnectionsQueryQueryResult = Apollo.QueryResult<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>;
export const CalendarSyncSettingsDocument = gql`
    query calendarSyncSettings($businessId: ID!) {
  business: Business(id: $businessId) {
    id
    exportedEventsTemplate
  }
}
    `;

/**
 * __useCalendarSyncSettingsQuery__
 *
 * To run a query within a React component, call `useCalendarSyncSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSyncSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarSyncSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCalendarSyncSettingsQuery(baseOptions: Apollo.QueryHookOptions<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables> & ({ variables: CalendarSyncSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>(CalendarSyncSettingsDocument, options);
      }
export function useCalendarSyncSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>(CalendarSyncSettingsDocument, options);
        }
export function useCalendarSyncSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>(CalendarSyncSettingsDocument, options);
        }
export type CalendarSyncSettingsQueryHookResult = ReturnType<typeof useCalendarSyncSettingsQuery>;
export type CalendarSyncSettingsLazyQueryHookResult = ReturnType<typeof useCalendarSyncSettingsLazyQuery>;
export type CalendarSyncSettingsSuspenseQueryHookResult = ReturnType<typeof useCalendarSyncSettingsSuspenseQuery>;
export type CalendarSyncSettingsQueryResult = Apollo.QueryResult<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>;
export const DisconnectCalendarConnectionDocument = gql`
    mutation disconnectCalendarConnection($id: ID!) {
  disconnectCalendarConnection(id: $id)
}
    `;
export type DisconnectCalendarConnectionMutationFn = Apollo.MutationFunction<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>;

/**
 * __useDisconnectCalendarConnectionMutation__
 *
 * To run a mutation, you first call `useDisconnectCalendarConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCalendarConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCalendarConnectionMutation, { data, loading, error }] = useDisconnectCalendarConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectCalendarConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>(DisconnectCalendarConnectionDocument, options);
      }
export type DisconnectCalendarConnectionMutationHookResult = ReturnType<typeof useDisconnectCalendarConnectionMutation>;
export type DisconnectCalendarConnectionMutationResult = Apollo.MutationResult<DisconnectCalendarConnectionMutation>;
export type DisconnectCalendarConnectionMutationOptions = Apollo.BaseMutationOptions<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>;
export const UpdateCalendarConnectionDocument = gql`
    mutation updateCalendarConnection($id: ID!, $importFromCalendarsIds: [String!], $exportToCalendarId: String, $enabled: Boolean, $allDayEventsTimezone: String, $exportAttendees: [CalendarEventAttendee!]) {
  updateCalendarConnection(
    id: $id
    importFromCalendarsIds: $importFromCalendarsIds
    exportToCalendarId: $exportToCalendarId
    enabled: $enabled
    allDayEventsTimezone: $allDayEventsTimezone
    exportAttendees: $exportAttendees
  ) {
    id
    importFromCalendarsIds
    exportToCalendarId
    calendars
    enabled
    allDayEventsTimezone
    exportAttendees
  }
}
    `;
export type UpdateCalendarConnectionMutationFn = Apollo.MutationFunction<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>;

/**
 * __useUpdateCalendarConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarConnectionMutation, { data, loading, error }] = useUpdateCalendarConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      importFromCalendarsIds: // value for 'importFromCalendarsIds'
 *      exportToCalendarId: // value for 'exportToCalendarId'
 *      enabled: // value for 'enabled'
 *      allDayEventsTimezone: // value for 'allDayEventsTimezone'
 *      exportAttendees: // value for 'exportAttendees'
 *   },
 * });
 */
export function useUpdateCalendarConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>(UpdateCalendarConnectionDocument, options);
      }
export type UpdateCalendarConnectionMutationHookResult = ReturnType<typeof useUpdateCalendarConnectionMutation>;
export type UpdateCalendarConnectionMutationResult = Apollo.MutationResult<UpdateCalendarConnectionMutation>;
export type UpdateCalendarConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>;
export const CalendarAppointmentsDocument = gql`
    query calendarAppointments($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!], $patientId: ID, $locationType: LocationType) {
  appointments: calendarViewAppointments(
    dateStart: $dateStart
    dateEnd: $dateEnd
    staffsIds: $staffsIds
    patientId: $patientId
    locationType: $locationType
  ) {
    id
    approved
    cancelled
    date
    duration
    price
    currency
    groupBookingId
    locationType
    locationValue
    rrule
    originalDate
    recurringAppointmentId
    staff {
      id
      name
    }
    patient {
      id
      name
    }
    service {
      id
      name
    }
    tagIds
  }
}
    `;

/**
 * __useCalendarAppointmentsQuery__
 *
 * To run a query within a React component, call `useCalendarAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarAppointmentsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *      patientId: // value for 'patientId'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useCalendarAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables> & ({ variables: CalendarAppointmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>(CalendarAppointmentsDocument, options);
      }
export function useCalendarAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>(CalendarAppointmentsDocument, options);
        }
export function useCalendarAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>(CalendarAppointmentsDocument, options);
        }
export type CalendarAppointmentsQueryHookResult = ReturnType<typeof useCalendarAppointmentsQuery>;
export type CalendarAppointmentsLazyQueryHookResult = ReturnType<typeof useCalendarAppointmentsLazyQuery>;
export type CalendarAppointmentsSuspenseQueryHookResult = ReturnType<typeof useCalendarAppointmentsSuspenseQuery>;
export type CalendarAppointmentsQueryResult = Apollo.QueryResult<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>;
export const DeleteTimeBlockerDocument = gql`
    mutation deleteTimeBlocker($id: ID!) {
  deleteTimeBlocker(id: $id) {
    id
  }
}
    `;
export type DeleteTimeBlockerMutationFn = Apollo.MutationFunction<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>;

/**
 * __useDeleteTimeBlockerMutation__
 *
 * To run a mutation, you first call `useDeleteTimeBlockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeBlockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeBlockerMutation, { data, loading, error }] = useDeleteTimeBlockerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeBlockerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>(DeleteTimeBlockerDocument, options);
      }
export type DeleteTimeBlockerMutationHookResult = ReturnType<typeof useDeleteTimeBlockerMutation>;
export type DeleteTimeBlockerMutationResult = Apollo.MutationResult<DeleteTimeBlockerMutation>;
export type DeleteTimeBlockerMutationOptions = Apollo.BaseMutationOptions<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>;
export const CalendarExternalEventsDocument = gql`
    query calendarExternalEvents($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!]) {
  externalEvents: calendarViewExternalEvents(
    staffsIds: $staffsIds
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    id
    startDate
    endDate
    title
    staffId
  }
}
    `;

/**
 * __useCalendarExternalEventsQuery__
 *
 * To run a query within a React component, call `useCalendarExternalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarExternalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarExternalEventsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *   },
 * });
 */
export function useCalendarExternalEventsQuery(baseOptions: Apollo.QueryHookOptions<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables> & ({ variables: CalendarExternalEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>(CalendarExternalEventsDocument, options);
      }
export function useCalendarExternalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>(CalendarExternalEventsDocument, options);
        }
export function useCalendarExternalEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>(CalendarExternalEventsDocument, options);
        }
export type CalendarExternalEventsQueryHookResult = ReturnType<typeof useCalendarExternalEventsQuery>;
export type CalendarExternalEventsLazyQueryHookResult = ReturnType<typeof useCalendarExternalEventsLazyQuery>;
export type CalendarExternalEventsSuspenseQueryHookResult = ReturnType<typeof useCalendarExternalEventsSuspenseQuery>;
export type CalendarExternalEventsQueryResult = Apollo.QueryResult<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>;
export const CalendarResourcesDocument = gql`
    query calendarResources($limit: Int, $skip: Int, $time: DateTime!, $businessId: ID!, $filterIds: [ID!]) {
  staffs: managedStaffs(limit: $limit, skip: $skip, filterIds: $filterIds) {
    id
    name
    picture {
      id
      url
    }
    workingHours(time: $time)
  }
  business: Business(id: $businessId) {
    id
    workingHours
  }
}
    `;

/**
 * __useCalendarResourcesQuery__
 *
 * To run a query within a React component, call `useCalendarResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarResourcesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      time: // value for 'time'
 *      businessId: // value for 'businessId'
 *      filterIds: // value for 'filterIds'
 *   },
 * });
 */
export function useCalendarResourcesQuery(baseOptions: Apollo.QueryHookOptions<CalendarResourcesQuery, CalendarResourcesQueryVariables> & ({ variables: CalendarResourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarResourcesQuery, CalendarResourcesQueryVariables>(CalendarResourcesDocument, options);
      }
export function useCalendarResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarResourcesQuery, CalendarResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarResourcesQuery, CalendarResourcesQueryVariables>(CalendarResourcesDocument, options);
        }
export function useCalendarResourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarResourcesQuery, CalendarResourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarResourcesQuery, CalendarResourcesQueryVariables>(CalendarResourcesDocument, options);
        }
export type CalendarResourcesQueryHookResult = ReturnType<typeof useCalendarResourcesQuery>;
export type CalendarResourcesLazyQueryHookResult = ReturnType<typeof useCalendarResourcesLazyQuery>;
export type CalendarResourcesSuspenseQueryHookResult = ReturnType<typeof useCalendarResourcesSuspenseQuery>;
export type CalendarResourcesQueryResult = Apollo.QueryResult<CalendarResourcesQuery, CalendarResourcesQueryVariables>;
export const CouponsDocument = gql`
    query coupons {
  coupons: allCoupons {
    id
    createdAt
    updatedAt
    name
    code
    type
    amountOff
    percentOff
    currency
    expiresAt
    redemptionsCount
  }
}
    `;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export function useCouponsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsSuspenseQueryHookResult = ReturnType<typeof useCouponsSuspenseQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const CreateCouponDocument = gql`
    mutation createCoupon($code: String!, $name: String!, $type: String!, $amountOff: Int, $percentOff: Float, $currency: String, $expiresAt: DateTime) {
  createCoupon(
    code: $code
    name: $name
    type: $type
    amountOff: $amountOff
    percentOff: $percentOff
    currency: $currency
    expiresAt: $expiresAt
  ) {
    id
    createdAt
    updatedAt
    name
    code
    type
    amountOff
    percentOff
    currency
    expiresAt
    redemptionsCount
  }
}
    `;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      amountOff: // value for 'amountOff'
 *      percentOff: // value for 'percentOff'
 *      currency: // value for 'currency'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($id: ID!) {
  deleteCoupon(id: $id)
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<DeleteCouponMutation, DeleteCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($id: ID!, $name: String!) {
  updateCoupon(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation createApiKey($description: String!) {
  createApiKey(description: $description) {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
  }
}
    `;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const CreateBusinessDocument = gql`
    mutation createBusiness($name: String!, $alias: String!, $timezone: String!) {
  createBusiness(name: $name, alias: $alias, timezone: $timezone) {
    id
    alias
    name
    timezone
  }
}
    `;
export type CreateBusinessMutationFn = Apollo.MutationFunction<CreateBusinessMutation, CreateBusinessMutationVariables>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      name: // value for 'name'
 *      alias: // value for 'alias'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessMutation, CreateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(CreateBusinessDocument, options);
      }
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>;
export type CreateBusinessMutationResult = Apollo.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions = Apollo.BaseMutationOptions<CreateBusinessMutation, CreateBusinessMutationVariables>;
export const CreateStripeBillingPortalSessionDocument = gql`
    mutation createStripeBillingPortalSession($flow: String!) {
  createStripeBillingPortalSession(flow: $flow) {
    url
  }
}
    `;
export type CreateStripeBillingPortalSessionMutationFn = Apollo.MutationFunction<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>;

/**
 * __useCreateStripeBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeBillingPortalSessionMutation, { data, loading, error }] = useCreateStripeBillingPortalSessionMutation({
 *   variables: {
 *      flow: // value for 'flow'
 *   },
 * });
 */
export function useCreateStripeBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>(CreateStripeBillingPortalSessionDocument, options);
      }
export type CreateStripeBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateStripeBillingPortalSessionMutation>;
export type CreateStripeBillingPortalSessionMutationResult = Apollo.MutationResult<CreateStripeBillingPortalSessionMutation>;
export type CreateStripeBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation createStripeCheckoutSession {
  createStripeCheckoutSession {
    url
  }
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const CreateStripeSetupIntentDocument = gql`
    mutation createStripeSetupIntent($productQuantities: Json!, $interval: StripeInterval!, $confirmationToken: String) {
  createStripeSetupIntent(
    productQuantities: $productQuantities
    interval: $interval
    confirmationToken: $confirmationToken
  ) {
    id
    customerId
    clientSecret
    status
    usage
    nextAction {
      type
      redirectToUrl {
        returnUrl
        url
      }
      useStripeSDK
    }
  }
}
    `;
export type CreateStripeSetupIntentMutationFn = Apollo.MutationFunction<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>;

/**
 * __useCreateStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSetupIntentMutation, { data, loading, error }] = useCreateStripeSetupIntentMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      interval: // value for 'interval'
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useCreateStripeSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>(CreateStripeSetupIntentDocument, options);
      }
export type CreateStripeSetupIntentMutationHookResult = ReturnType<typeof useCreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationResult = Apollo.MutationResult<CreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>;
export const CurrentBusinessUsageDocument = gql`
    query currentBusinessUsage($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    id
    usage: usage(cached: false)
  }
}
    `;

/**
 * __useCurrentBusinessUsageQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessUsageQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentBusinessUsageQuery(baseOptions: Apollo.QueryHookOptions<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables> & ({ variables: CurrentBusinessUsageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>(CurrentBusinessUsageDocument, options);
      }
export function useCurrentBusinessUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>(CurrentBusinessUsageDocument, options);
        }
export function useCurrentBusinessUsageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>(CurrentBusinessUsageDocument, options);
        }
export type CurrentBusinessUsageQueryHookResult = ReturnType<typeof useCurrentBusinessUsageQuery>;
export type CurrentBusinessUsageLazyQueryHookResult = ReturnType<typeof useCurrentBusinessUsageLazyQuery>;
export type CurrentBusinessUsageSuspenseQueryHookResult = ReturnType<typeof useCurrentBusinessUsageSuspenseQuery>;
export type CurrentBusinessUsageQueryResult = Apollo.QueryResult<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>;
export const CurrentStaffDocument = gql`
    query currentStaff($businessId: ID!) {
  currentStaff(businessId: $businessId) {
    id
    alias
    enabled
    availableBoolFeatures
    status
    name
    occupation
    marketingMeta
    roles
    description
    email
    phoneMobile
    alias
    timezone
    bookingPageUrl
    stripeProductIds
    onboardingCompletedAt
    unreadMessagesCount
    business {
      id
      alias
    }
    managedStaff {
      id
      name
    }
    picture {
      id
      url
    }
    zoomConnection {
      id
    }
    teamsConnection {
      id
    }
  }
}
    `;

/**
 * __useCurrentStaffQuery__
 *
 * To run a query within a React component, call `useCurrentStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentStaffQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentStaffQuery(baseOptions: Apollo.QueryHookOptions<CurrentStaffQuery, CurrentStaffQueryVariables> & ({ variables: CurrentStaffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentStaffQuery, CurrentStaffQueryVariables>(CurrentStaffDocument, options);
      }
export function useCurrentStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentStaffQuery, CurrentStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentStaffQuery, CurrentStaffQueryVariables>(CurrentStaffDocument, options);
        }
export function useCurrentStaffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentStaffQuery, CurrentStaffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentStaffQuery, CurrentStaffQueryVariables>(CurrentStaffDocument, options);
        }
export type CurrentStaffQueryHookResult = ReturnType<typeof useCurrentStaffQuery>;
export type CurrentStaffLazyQueryHookResult = ReturnType<typeof useCurrentStaffLazyQuery>;
export type CurrentStaffSuspenseQueryHookResult = ReturnType<typeof useCurrentStaffSuspenseQuery>;
export type CurrentStaffQueryResult = Apollo.QueryResult<CurrentStaffQuery, CurrentStaffQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    name
    email
    role
    businessesCount
    ownedBusinessesCount
    availableFeatures
    createdAt
    updatedAt
    staffs {
      id
      onboardingCompletedAt
      roles
      business {
        id
        name
        alias
        enabled
        servicesCount
        staffRoles
        onboardingCompletedAt
        subscriptionStatus
        availableFeatures
        stripeData
        remainingTrialDays
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const AllCustomFieldsDocument = gql`
    query allCustomFields {
  allCustomFields {
    id
    alias
    name
    description
    fieldType
    allowedValues
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAllCustomFieldsQuery__
 *
 * To run a query within a React component, call `useAllCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>(AllCustomFieldsDocument, options);
      }
export function useAllCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>(AllCustomFieldsDocument, options);
        }
export function useAllCustomFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>(AllCustomFieldsDocument, options);
        }
export type AllCustomFieldsQueryHookResult = ReturnType<typeof useAllCustomFieldsQuery>;
export type AllCustomFieldsLazyQueryHookResult = ReturnType<typeof useAllCustomFieldsLazyQuery>;
export type AllCustomFieldsSuspenseQueryHookResult = ReturnType<typeof useAllCustomFieldsSuspenseQuery>;
export type AllCustomFieldsQueryResult = Apollo.QueryResult<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>;
export const CreateCustomFieldDocument = gql`
    mutation createCustomField($alias: String!, $name: String!, $fieldType: CustomFieldType!, $description: String!, $allowedValues: [String!]) {
  createCustomField(
    alias: $alias
    name: $name
    fieldType: $fieldType
    description: $description
    allowedValues: $allowedValues
  ) {
    id
    alias
    name
    description
    fieldType
    allowedValues
  }
}
    `;
export type CreateCustomFieldMutationFn = Apollo.MutationFunction<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;

/**
 * __useCreateCustomFieldMutation__
 *
 * To run a mutation, you first call `useCreateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomFieldMutation, { data, loading, error }] = useCreateCustomFieldMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      fieldType: // value for 'fieldType'
 *      description: // value for 'description'
 *      allowedValues: // value for 'allowedValues'
 *   },
 * });
 */
export function useCreateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>(CreateCustomFieldDocument, options);
      }
export type CreateCustomFieldMutationHookResult = ReturnType<typeof useCreateCustomFieldMutation>;
export type CreateCustomFieldMutationResult = Apollo.MutationResult<CreateCustomFieldMutation>;
export type CreateCustomFieldMutationOptions = Apollo.BaseMutationOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;
export const UpdateCustomFieldDocument = gql`
    mutation updateCustomField($id: ID!, $name: String!, $description: String!, $allowedValues: [String!]) {
  updateCustomField(
    id: $id
    name: $name
    description: $description
    allowedValues: $allowedValues
  ) {
    id
    name
    description
    allowedValues
  }
}
    `;
export type UpdateCustomFieldMutationFn = Apollo.MutationFunction<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;

/**
 * __useUpdateCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFieldMutation, { data, loading, error }] = useUpdateCustomFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      allowedValues: // value for 'allowedValues'
 *   },
 * });
 */
export function useUpdateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>(UpdateCustomFieldDocument, options);
      }
export type UpdateCustomFieldMutationHookResult = ReturnType<typeof useUpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationResult = Apollo.MutationResult<UpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;
export const SetPatientCustomFieldValueDocument = gql`
    mutation setPatientCustomFieldValue($patientId: ID!, $fieldKey: String!, $value: String!) {
  setPatientCustomFieldValue(
    patientId: $patientId
    fieldKey: $fieldKey
    value: $value
  ) {
    id
    patientId
    fieldKey
    value
  }
}
    `;
export type SetPatientCustomFieldValueMutationFn = Apollo.MutationFunction<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>;

/**
 * __useSetPatientCustomFieldValueMutation__
 *
 * To run a mutation, you first call `useSetPatientCustomFieldValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientCustomFieldValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientCustomFieldValueMutation, { data, loading, error }] = useSetPatientCustomFieldValueMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      fieldKey: // value for 'fieldKey'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetPatientCustomFieldValueMutation(baseOptions?: Apollo.MutationHookOptions<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>(SetPatientCustomFieldValueDocument, options);
      }
export type SetPatientCustomFieldValueMutationHookResult = ReturnType<typeof useSetPatientCustomFieldValueMutation>;
export type SetPatientCustomFieldValueMutationResult = Apollo.MutationResult<SetPatientCustomFieldValueMutation>;
export type SetPatientCustomFieldValueMutationOptions = Apollo.BaseMutationOptions<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>;
export const ArchivePatientDocument = gql`
    mutation archivePatient($id: ID!, $reason: String) {
  archivePatient(id: $id, reason: $reason) {
    id
    archivedAt
  }
}
    `;
export type ArchivePatientMutationFn = Apollo.MutationFunction<ArchivePatientMutation, ArchivePatientMutationVariables>;

/**
 * __useArchivePatientMutation__
 *
 * To run a mutation, you first call `useArchivePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePatientMutation, { data, loading, error }] = useArchivePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useArchivePatientMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePatientMutation, ArchivePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePatientMutation, ArchivePatientMutationVariables>(ArchivePatientDocument, options);
      }
export type ArchivePatientMutationHookResult = ReturnType<typeof useArchivePatientMutation>;
export type ArchivePatientMutationResult = Apollo.MutationResult<ArchivePatientMutation>;
export type ArchivePatientMutationOptions = Apollo.BaseMutationOptions<ArchivePatientMutation, ArchivePatientMutationVariables>;
export const CreatePatientDocument = gql`
    mutation createPatient($businessId: ID!, $name: String!, $email: String!, $primaryStaffId: ID, $phoneMobile: String, $timezone: String, $dob: String) {
  createPatient(
    businessId: $businessId
    name: $name
    email: $email
    phoneMobile: $phoneMobile
    primaryStaffId: $primaryStaffId
    dob: $dob
    timezone: $timezone
  ) {
    id
    name
    email
    phoneMobile
    timezone
    dob
    age
    primaryStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
}
    `;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      primaryStaffId: // value for 'primaryStaffId'
 *      phoneMobile: // value for 'phoneMobile'
 *      timezone: // value for 'timezone'
 *      dob: // value for 'dob'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, options);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const PatientDocument = gql`
    query patient($patientId: ID!) {
  patient: Patient(id: $patientId) {
    id
    name
    archivedAt
    archivedReason
    preferredName
    email
    phoneMobile
    timezone
    dob
    age
    gender
    biologicalSex
    patientType
    height
    weight
    selfDeclaredDiabeticType
    selfDeclaredHypertension
    selfDeclaredIsSmoker
    stripeCustomerId
    billingAddress {
      id
      city
      country
      line1
      line2
      name
      postalCode
      state
    }
    tagIds
    customFieldValues {
      id
      fieldKey
      fieldType
      value
    }
    primaryStaff {
      id
      name
      email
      picture {
        id
        url
      }
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables> & ({ variables: PatientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }
export function usePatientSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientSuspenseQueryHookResult = ReturnType<typeof usePatientSuspenseQuery>;
export type PatientQueryResult = Apollo.QueryResult<PatientQuery, PatientQueryVariables>;
export const EnablePatientBillingDocument = gql`
    mutation enablePatientBilling($id: ID!) {
  enablePatientBilling(id: $id) {
    id
    stripeCustomerId
  }
}
    `;
export type EnablePatientBillingMutationFn = Apollo.MutationFunction<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>;

/**
 * __useEnablePatientBillingMutation__
 *
 * To run a mutation, you first call `useEnablePatientBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePatientBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePatientBillingMutation, { data, loading, error }] = useEnablePatientBillingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnablePatientBillingMutation(baseOptions?: Apollo.MutationHookOptions<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>(EnablePatientBillingDocument, options);
      }
export type EnablePatientBillingMutationHookResult = ReturnType<typeof useEnablePatientBillingMutation>;
export type EnablePatientBillingMutationResult = Apollo.MutationResult<EnablePatientBillingMutation>;
export type EnablePatientBillingMutationOptions = Apollo.BaseMutationOptions<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>;
export const RestorePatientDocument = gql`
    mutation restorePatient($id: ID!) {
  restorePatient(id: $id) {
    id
    archivedAt
  }
}
    `;
export type RestorePatientMutationFn = Apollo.MutationFunction<RestorePatientMutation, RestorePatientMutationVariables>;

/**
 * __useRestorePatientMutation__
 *
 * To run a mutation, you first call `useRestorePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePatientMutation, { data, loading, error }] = useRestorePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestorePatientMutation(baseOptions?: Apollo.MutationHookOptions<RestorePatientMutation, RestorePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestorePatientMutation, RestorePatientMutationVariables>(RestorePatientDocument, options);
      }
export type RestorePatientMutationHookResult = ReturnType<typeof useRestorePatientMutation>;
export type RestorePatientMutationResult = Apollo.MutationResult<RestorePatientMutation>;
export type RestorePatientMutationOptions = Apollo.BaseMutationOptions<RestorePatientMutation, RestorePatientMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation updatePatient($id: ID!, $name: String, $preferredName: String, $email: String, $phoneMobile: String, $dob: String, $patientType: String, $gender: String, $biologicalSex: BiologicalSex, $billingAddress: AddressInput, $primaryStaffId: ID, $timezone: String) {
  updatePatient(
    id: $id
    name: $name
    preferredName: $preferredName
    email: $email
    phoneMobile: $phoneMobile
    timezone: $timezone
    dob: $dob
    patientType: $patientType
    gender: $gender
    biologicalSex: $biologicalSex
    billingAddress: $billingAddress
    primaryStaffId: $primaryStaffId
  ) {
    id
    name
    preferredName
    email
    phoneMobile
    timezone
    dob
    age
    patientType
    gender
    biologicalSex
    billingAddress {
      id
      city
      country
      line1
      line2
      name
      postalCode
      state
    }
    primaryStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
}
    `;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      preferredName: // value for 'preferredName'
 *      email: // value for 'email'
 *      phoneMobile: // value for 'phoneMobile'
 *      dob: // value for 'dob'
 *      patientType: // value for 'patientType'
 *      gender: // value for 'gender'
 *      biologicalSex: // value for 'biologicalSex'
 *      billingAddress: // value for 'billingAddress'
 *      primaryStaffId: // value for 'primaryStaffId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const DataImportDocument = gql`
    query dataImport($id: ID!) {
  DataImport(id: $id) {
    id
    columnsMap
    createdBy {
      id
      alias
      email
      name
      picture {
        id
        url
      }
    }
    dataImportErrors {
      error
      lineNumber
    }
    resourceType
    rowsComplete
    rowsCount
    rowsFailed
    rowsProcessed
    rowsSkipped
    status
  }
}
    `;

/**
 * __useDataImportQuery__
 *
 * To run a query within a React component, call `useDataImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataImportQuery(baseOptions: Apollo.QueryHookOptions<DataImportQuery, DataImportQueryVariables> & ({ variables: DataImportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataImportQuery, DataImportQueryVariables>(DataImportDocument, options);
      }
export function useDataImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataImportQuery, DataImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataImportQuery, DataImportQueryVariables>(DataImportDocument, options);
        }
export function useDataImportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataImportQuery, DataImportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DataImportQuery, DataImportQueryVariables>(DataImportDocument, options);
        }
export type DataImportQueryHookResult = ReturnType<typeof useDataImportQuery>;
export type DataImportLazyQueryHookResult = ReturnType<typeof useDataImportLazyQuery>;
export type DataImportSuspenseQueryHookResult = ReturnType<typeof useDataImportSuspenseQuery>;
export type DataImportQueryResult = Apollo.QueryResult<DataImportQuery, DataImportQueryVariables>;
export const AllDataImportsDocument = gql`
    query allDataImports {
  allDataImports {
    id
    columnsMap
    createdBy {
      id
      alias
      email
      name
      picture {
        id
        url
      }
    }
    resourceType
    rowsComplete
    rowsCount
    rowsFailed
    rowsProcessed
    rowsSkipped
    status
  }
}
    `;

/**
 * __useAllDataImportsQuery__
 *
 * To run a query within a React component, call `useAllDataImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDataImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDataImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllDataImportsQuery(baseOptions?: Apollo.QueryHookOptions<AllDataImportsQuery, AllDataImportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDataImportsQuery, AllDataImportsQueryVariables>(AllDataImportsDocument, options);
      }
export function useAllDataImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDataImportsQuery, AllDataImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDataImportsQuery, AllDataImportsQueryVariables>(AllDataImportsDocument, options);
        }
export function useAllDataImportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllDataImportsQuery, AllDataImportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllDataImportsQuery, AllDataImportsQueryVariables>(AllDataImportsDocument, options);
        }
export type AllDataImportsQueryHookResult = ReturnType<typeof useAllDataImportsQuery>;
export type AllDataImportsLazyQueryHookResult = ReturnType<typeof useAllDataImportsLazyQuery>;
export type AllDataImportsSuspenseQueryHookResult = ReturnType<typeof useAllDataImportsSuspenseQuery>;
export type AllDataImportsQueryResult = Apollo.QueryResult<AllDataImportsQuery, AllDataImportsQueryVariables>;
export const CreateDataImportDocument = gql`
    mutation createDataImport($fileId: ID!, $columnsMap: Json!, $resourceType: String!, $rowsCount: Int!) {
  createDataImport(
    fileId: $fileId
    columnsMap: $columnsMap
    resourceType: $resourceType
    rowsCount: $rowsCount
  ) {
    id
  }
}
    `;
export type CreateDataImportMutationFn = Apollo.MutationFunction<CreateDataImportMutation, CreateDataImportMutationVariables>;

/**
 * __useCreateDataImportMutation__
 *
 * To run a mutation, you first call `useCreateDataImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataImportMutation, { data, loading, error }] = useCreateDataImportMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      columnsMap: // value for 'columnsMap'
 *      resourceType: // value for 'resourceType'
 *      rowsCount: // value for 'rowsCount'
 *   },
 * });
 */
export function useCreateDataImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataImportMutation, CreateDataImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataImportMutation, CreateDataImportMutationVariables>(CreateDataImportDocument, options);
      }
export type CreateDataImportMutationHookResult = ReturnType<typeof useCreateDataImportMutation>;
export type CreateDataImportMutationResult = Apollo.MutationResult<CreateDataImportMutation>;
export type CreateDataImportMutationOptions = Apollo.BaseMutationOptions<CreateDataImportMutation, CreateDataImportMutationVariables>;
export const DisconnectStripeAccountDocument = gql`
    mutation disconnectStripeAccount($businessId: ID!) {
  disconnectStripeAccount(businessId: $businessId) {
    id
    stripeConnectAccount {
      id
      stripeAccountId
      stripePublishableKey
    }
  }
}
    `;
export type DisconnectStripeAccountMutationFn = Apollo.MutationFunction<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>;

/**
 * __useDisconnectStripeAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectStripeAccountMutation, { data, loading, error }] = useDisconnectStripeAccountMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useDisconnectStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>(DisconnectStripeAccountDocument, options);
      }
export type DisconnectStripeAccountMutationHookResult = ReturnType<typeof useDisconnectStripeAccountMutation>;
export type DisconnectStripeAccountMutationResult = Apollo.MutationResult<DisconnectStripeAccountMutation>;
export type DisconnectStripeAccountMutationOptions = Apollo.BaseMutationOptions<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>;
export const FaceVitalsScanResultDocument = gql`
    query faceVitalsScanResult($businessId: ID!, $id: ID!) {
  faceVitalsScanResult(businessId: $businessId, id: $id) {
    id
    createdAt
    appointment {
      id
    }
    patient {
      id
    }
    inputAge
    inputGender
    inputHeight
    inputWeight
    inputDiabeticType
    inputBloodPressureMedication
    inputHypertension
    inputSmoker
    bloodPressureDiastolicMmhg
    bloodPressureSystolicMmhg
    bloodPressureRiskCategory
    breathingRateBpm
    cardiacWorkload
    cardiovascularDiseaseRisk
    cardiovascularDiseaseRiskCategory
    heartAttackRisk
    heartAttackRiskCategory
    heartRateHz
    heartRateBpm
    heartRateIrregularBpm
    heartRateVariabilitySdnn
    heartRateVariabilityRiskCategory
    strokeRisk
    strokeRiskCategory
    vascularCapacitySecs
  }
}
    `;

/**
 * __useFaceVitalsScanResultQuery__
 *
 * To run a query within a React component, call `useFaceVitalsScanResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaceVitalsScanResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaceVitalsScanResultQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFaceVitalsScanResultQuery(baseOptions: Apollo.QueryHookOptions<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables> & ({ variables: FaceVitalsScanResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>(FaceVitalsScanResultDocument, options);
      }
export function useFaceVitalsScanResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>(FaceVitalsScanResultDocument, options);
        }
export function useFaceVitalsScanResultSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>(FaceVitalsScanResultDocument, options);
        }
export type FaceVitalsScanResultQueryHookResult = ReturnType<typeof useFaceVitalsScanResultQuery>;
export type FaceVitalsScanResultLazyQueryHookResult = ReturnType<typeof useFaceVitalsScanResultLazyQuery>;
export type FaceVitalsScanResultSuspenseQueryHookResult = ReturnType<typeof useFaceVitalsScanResultSuspenseQuery>;
export type FaceVitalsScanResultQueryResult = Apollo.QueryResult<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>;
export const FaceVitalsScanResultsDocument = gql`
    query faceVitalsScanResults($businessId: ID!, $patientId: ID!) {
  faceVitalsScanResults(businessId: $businessId, patientId: $patientId) {
    id
    createdAt
    appointment {
      id
    }
    patient {
      id
      name
      email
    }
    inputAge
    inputGender
    inputHeight
    inputWeight
    inputBloodPressureMedication
    inputHypertension
    inputSmoker
    bloodPressureDiastolicMmhg
    bloodPressureSystolicMmhg
    bloodPressureRiskCategory
    breathingRateBpm
    cardiacWorkload
    cardiovascularDiseaseRisk
    cardiovascularDiseaseRiskCategory
    heartAttackRisk
    heartAttackRiskCategory
    heartRateHz
    heartRateBpm
    heartRateIrregularBpm
    heartRateVariabilitySdnn
    heartRateVariabilityRiskCategory
    strokeRisk
    strokeRiskCategory
    vascularCapacitySecs
  }
}
    `;

/**
 * __useFaceVitalsScanResultsQuery__
 *
 * To run a query within a React component, call `useFaceVitalsScanResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaceVitalsScanResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaceVitalsScanResultsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useFaceVitalsScanResultsQuery(baseOptions: Apollo.QueryHookOptions<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables> & ({ variables: FaceVitalsScanResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>(FaceVitalsScanResultsDocument, options);
      }
export function useFaceVitalsScanResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>(FaceVitalsScanResultsDocument, options);
        }
export function useFaceVitalsScanResultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>(FaceVitalsScanResultsDocument, options);
        }
export type FaceVitalsScanResultsQueryHookResult = ReturnType<typeof useFaceVitalsScanResultsQuery>;
export type FaceVitalsScanResultsLazyQueryHookResult = ReturnType<typeof useFaceVitalsScanResultsLazyQuery>;
export type FaceVitalsScanResultsSuspenseQueryHookResult = ReturnType<typeof useFaceVitalsScanResultsSuspenseQuery>;
export type FaceVitalsScanResultsQueryResult = Apollo.QueryResult<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>;
export const AllPatientFilesDocument = gql`
    query allPatientFiles($patientId: ID!) {
  allPatientFiles(patientId: $patientId) {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useAllPatientFilesQuery__
 *
 * To run a query within a React component, call `useAllPatientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPatientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPatientFilesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useAllPatientFilesQuery(baseOptions: Apollo.QueryHookOptions<AllPatientFilesQuery, AllPatientFilesQueryVariables> & ({ variables: AllPatientFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPatientFilesQuery, AllPatientFilesQueryVariables>(AllPatientFilesDocument, options);
      }
export function useAllPatientFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPatientFilesQuery, AllPatientFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPatientFilesQuery, AllPatientFilesQueryVariables>(AllPatientFilesDocument, options);
        }
export function useAllPatientFilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllPatientFilesQuery, AllPatientFilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllPatientFilesQuery, AllPatientFilesQueryVariables>(AllPatientFilesDocument, options);
        }
export type AllPatientFilesQueryHookResult = ReturnType<typeof useAllPatientFilesQuery>;
export type AllPatientFilesLazyQueryHookResult = ReturnType<typeof useAllPatientFilesLazyQuery>;
export type AllPatientFilesSuspenseQueryHookResult = ReturnType<typeof useAllPatientFilesSuspenseQuery>;
export type AllPatientFilesQueryResult = Apollo.QueryResult<AllPatientFilesQuery, AllPatientFilesQueryVariables>;
export const DownloadFileDocument = gql`
    query downloadFile($id: ID!) {
  downloadFile(id: $id) {
    url
    expiresAt
  }
}
    `;

/**
 * __useDownloadFileQuery__
 *
 * To run a query within a React component, call `useDownloadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables> & ({ variables: DownloadFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
      }
export function useDownloadFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
        }
export function useDownloadFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DownloadFileQuery, DownloadFileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DownloadFileQuery, DownloadFileQueryVariables>(DownloadFileDocument, options);
        }
export type DownloadFileQueryHookResult = ReturnType<typeof useDownloadFileQuery>;
export type DownloadFileLazyQueryHookResult = ReturnType<typeof useDownloadFileLazyQuery>;
export type DownloadFileSuspenseQueryHookResult = ReturnType<typeof useDownloadFileSuspenseQuery>;
export type DownloadFileQueryResult = Apollo.QueryResult<DownloadFileQuery, DownloadFileQueryVariables>;
export const BusinessFormsDocument = gql`
    query businessForms($limit: Int, $skip: Int, $search: String) {
  forms(limit: $limit, skip: $skip, search: $search) {
    id
    business {
      id
      alias
    }
    url
    title
    alias
    description
    useAsTemplate
    category
    createdAt
    updatedAt
    status
    steps
    submissionsCount
    unreadSubmissionsCount
  }
  count: formsCount(search: $search)
}
    `;

/**
 * __useBusinessFormsQuery__
 *
 * To run a query within a React component, call `useBusinessFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessFormsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessFormsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessFormsQuery, BusinessFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessFormsQuery, BusinessFormsQueryVariables>(BusinessFormsDocument, options);
      }
export function useBusinessFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessFormsQuery, BusinessFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessFormsQuery, BusinessFormsQueryVariables>(BusinessFormsDocument, options);
        }
export function useBusinessFormsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessFormsQuery, BusinessFormsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessFormsQuery, BusinessFormsQueryVariables>(BusinessFormsDocument, options);
        }
export type BusinessFormsQueryHookResult = ReturnType<typeof useBusinessFormsQuery>;
export type BusinessFormsLazyQueryHookResult = ReturnType<typeof useBusinessFormsLazyQuery>;
export type BusinessFormsSuspenseQueryHookResult = ReturnType<typeof useBusinessFormsSuspenseQuery>;
export type BusinessFormsQueryResult = Apollo.QueryResult<BusinessFormsQuery, BusinessFormsQueryVariables>;
export const CreateFormDocument = gql`
    mutation createForm($title: String!, $formTemplateId: ID) {
  createForm(title: $title, formTemplateId: $formTemplateId) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      title: // value for 'title'
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const DeleteFormDocument = gql`
    mutation deleteForm($id: ID!) {
  deleteForm(id: $id)
}
    `;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;
export const DuplicateFormDocument = gql`
    mutation duplicateForm($id: ID!) {
  duplicateForm(id: $id) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type DuplicateFormMutationFn = Apollo.MutationFunction<DuplicateFormMutation, DuplicateFormMutationVariables>;

/**
 * __useDuplicateFormMutation__
 *
 * To run a mutation, you first call `useDuplicateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFormMutation, { data, loading, error }] = useDuplicateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateFormMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateFormMutation, DuplicateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateFormMutation, DuplicateFormMutationVariables>(DuplicateFormDocument, options);
      }
export type DuplicateFormMutationHookResult = ReturnType<typeof useDuplicateFormMutation>;
export type DuplicateFormMutationResult = Apollo.MutationResult<DuplicateFormMutation>;
export type DuplicateFormMutationOptions = Apollo.BaseMutationOptions<DuplicateFormMutation, DuplicateFormMutationVariables>;
export const FormDocument = gql`
    query form($id: ID!) {
  form(id: $id, mode: PREVIEW) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables> & ({ variables: FormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export function useFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormSuspenseQueryHookResult = ReturnType<typeof useFormSuspenseQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const FormStepsDocument = gql`
    query formSteps($id: ID!) {
  form(id: $id, mode: PREVIEW) {
    ...FormEditorFields
  }
}
    ${FormEditorFieldsFragmentDoc}`;

/**
 * __useFormStepsQuery__
 *
 * To run a query within a React component, call `useFormStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormStepsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormStepsQuery(baseOptions: Apollo.QueryHookOptions<FormStepsQuery, FormStepsQueryVariables> & ({ variables: FormStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormStepsQuery, FormStepsQueryVariables>(FormStepsDocument, options);
      }
export function useFormStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormStepsQuery, FormStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormStepsQuery, FormStepsQueryVariables>(FormStepsDocument, options);
        }
export function useFormStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormStepsQuery, FormStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormStepsQuery, FormStepsQueryVariables>(FormStepsDocument, options);
        }
export type FormStepsQueryHookResult = ReturnType<typeof useFormStepsQuery>;
export type FormStepsLazyQueryHookResult = ReturnType<typeof useFormStepsLazyQuery>;
export type FormStepsSuspenseQueryHookResult = ReturnType<typeof useFormStepsSuspenseQuery>;
export type FormStepsQueryResult = Apollo.QueryResult<FormStepsQuery, FormStepsQueryVariables>;
export const GetAllFormTemplatesDocument = gql`
    query GetAllFormTemplates($library: FormTemplateLibrary!) {
  formTemplates(library: $library) {
    id
    name
    description
    thumbnailUrl
    previewUrl
  }
}
    `;

/**
 * __useGetAllFormTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFormTemplatesQuery({
 *   variables: {
 *      library: // value for 'library'
 *   },
 * });
 */
export function useGetAllFormTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables> & ({ variables: GetAllFormTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>(GetAllFormTemplatesDocument, options);
      }
export function useGetAllFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>(GetAllFormTemplatesDocument, options);
        }
export function useGetAllFormTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>(GetAllFormTemplatesDocument, options);
        }
export type GetAllFormTemplatesQueryHookResult = ReturnType<typeof useGetAllFormTemplatesQuery>;
export type GetAllFormTemplatesLazyQueryHookResult = ReturnType<typeof useGetAllFormTemplatesLazyQuery>;
export type GetAllFormTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetAllFormTemplatesSuspenseQuery>;
export type GetAllFormTemplatesQueryResult = Apollo.QueryResult<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>;
export const PublishFormDocument = gql`
    mutation publishForm($id: ID!) {
  form: publishForm(id: $id) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type PublishFormMutationFn = Apollo.MutationFunction<PublishFormMutation, PublishFormMutationVariables>;

/**
 * __usePublishFormMutation__
 *
 * To run a mutation, you first call `usePublishFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFormMutation, { data, loading, error }] = usePublishFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishFormMutation(baseOptions?: Apollo.MutationHookOptions<PublishFormMutation, PublishFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishFormMutation, PublishFormMutationVariables>(PublishFormDocument, options);
      }
export type PublishFormMutationHookResult = ReturnType<typeof usePublishFormMutation>;
export type PublishFormMutationResult = Apollo.MutationResult<PublishFormMutation>;
export type PublishFormMutationOptions = Apollo.BaseMutationOptions<PublishFormMutation, PublishFormMutationVariables>;
export const RenameFormDocument = gql`
    mutation renameForm($id: ID!, $title: String, $alias: String, $description: String, $thumbnail: Json, $category: String, $useAsTemplate: Boolean) {
  renameForm(
    id: $id
    title: $title
    alias: $alias
    description: $description
    thumbnail: $thumbnail
    category: $category
    useAsTemplate: $useAsTemplate
  ) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type RenameFormMutationFn = Apollo.MutationFunction<RenameFormMutation, RenameFormMutationVariables>;

/**
 * __useRenameFormMutation__
 *
 * To run a mutation, you first call `useRenameFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFormMutation, { data, loading, error }] = useRenameFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      alias: // value for 'alias'
 *      description: // value for 'description'
 *      thumbnail: // value for 'thumbnail'
 *      category: // value for 'category'
 *      useAsTemplate: // value for 'useAsTemplate'
 *   },
 * });
 */
export function useRenameFormMutation(baseOptions?: Apollo.MutationHookOptions<RenameFormMutation, RenameFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameFormMutation, RenameFormMutationVariables>(RenameFormDocument, options);
      }
export type RenameFormMutationHookResult = ReturnType<typeof useRenameFormMutation>;
export type RenameFormMutationResult = Apollo.MutationResult<RenameFormMutation>;
export type RenameFormMutationOptions = Apollo.BaseMutationOptions<RenameFormMutation, RenameFormMutationVariables>;
export const ResetBusinessBookingFormDocument = gql`
    mutation resetBusinessBookingForm {
  resetBusinessBookingForm
}
    `;
export type ResetBusinessBookingFormMutationFn = Apollo.MutationFunction<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>;

/**
 * __useResetBusinessBookingFormMutation__
 *
 * To run a mutation, you first call `useResetBusinessBookingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBusinessBookingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBusinessBookingFormMutation, { data, loading, error }] = useResetBusinessBookingFormMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetBusinessBookingFormMutation(baseOptions?: Apollo.MutationHookOptions<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>(ResetBusinessBookingFormDocument, options);
      }
export type ResetBusinessBookingFormMutationHookResult = ReturnType<typeof useResetBusinessBookingFormMutation>;
export type ResetBusinessBookingFormMutationResult = Apollo.MutationResult<ResetBusinessBookingFormMutation>;
export type ResetBusinessBookingFormMutationOptions = Apollo.BaseMutationOptions<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>;
export const FormSubmissionDocument = gql`
    query formSubmission($id: ID!) {
  formSubmission(id: $id) {
    id
    startedAt
    answers
    createdAt
    questions
    readAt
    form {
      id
      title
    }
  }
}
    `;

/**
 * __useFormSubmissionQuery__
 *
 * To run a query within a React component, call `useFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormSubmissionQuery(baseOptions: Apollo.QueryHookOptions<FormSubmissionQuery, FormSubmissionQueryVariables> & ({ variables: FormSubmissionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionQuery, FormSubmissionQueryVariables>(FormSubmissionDocument, options);
      }
export function useFormSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionQuery, FormSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionQuery, FormSubmissionQueryVariables>(FormSubmissionDocument, options);
        }
export function useFormSubmissionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormSubmissionQuery, FormSubmissionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormSubmissionQuery, FormSubmissionQueryVariables>(FormSubmissionDocument, options);
        }
export type FormSubmissionQueryHookResult = ReturnType<typeof useFormSubmissionQuery>;
export type FormSubmissionLazyQueryHookResult = ReturnType<typeof useFormSubmissionLazyQuery>;
export type FormSubmissionSuspenseQueryHookResult = ReturnType<typeof useFormSubmissionSuspenseQuery>;
export type FormSubmissionQueryResult = Apollo.QueryResult<FormSubmissionQuery, FormSubmissionQueryVariables>;
export const FormSubmissionAttachmentUrlDocument = gql`
    query formSubmissionAttachmentURL($formSubmissionId: ID!, $answerKey: String!) {
  url: formSubmissionAttachmentURL(
    formSubmissionId: $formSubmissionId
    answerKey: $answerKey
  )
}
    `;

/**
 * __useFormSubmissionAttachmentUrlQuery__
 *
 * To run a query within a React component, call `useFormSubmissionAttachmentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionAttachmentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionAttachmentUrlQuery({
 *   variables: {
 *      formSubmissionId: // value for 'formSubmissionId'
 *      answerKey: // value for 'answerKey'
 *   },
 * });
 */
export function useFormSubmissionAttachmentUrlQuery(baseOptions: Apollo.QueryHookOptions<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables> & ({ variables: FormSubmissionAttachmentUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>(FormSubmissionAttachmentUrlDocument, options);
      }
export function useFormSubmissionAttachmentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>(FormSubmissionAttachmentUrlDocument, options);
        }
export function useFormSubmissionAttachmentUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>(FormSubmissionAttachmentUrlDocument, options);
        }
export type FormSubmissionAttachmentUrlQueryHookResult = ReturnType<typeof useFormSubmissionAttachmentUrlQuery>;
export type FormSubmissionAttachmentUrlLazyQueryHookResult = ReturnType<typeof useFormSubmissionAttachmentUrlLazyQuery>;
export type FormSubmissionAttachmentUrlSuspenseQueryHookResult = ReturnType<typeof useFormSubmissionAttachmentUrlSuspenseQuery>;
export type FormSubmissionAttachmentUrlQueryResult = Apollo.QueryResult<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>;
export const FormSubmissionsDocument = gql`
    query formSubmissions($formId: ID, $limit: Int, $skip: Int, $dateFrom: DateTime, $dateTo: DateTime, $search: String, $appointmentId: ID, $patientId: ID) {
  response: formSubmissions(
    formId: $formId
    limit: $limit
    skip: $skip
    dateFrom: $dateFrom
    dateTo: $dateTo
    search: $search
    appointmentId: $appointmentId
    patientId: $patientId
  ) {
    submissions: formSubmissions {
      id
      startedAt
      answers
      createdAt
      questions
      readAt
      patientVerified
      patient {
        id
        name
      }
      form {
        id
        title
      }
      appointment {
        id
      }
    }
    meta: formSubmissionsMeta {
      count
    }
  }
}
    `;

/**
 * __useFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      search: // value for 'search'
 *      appointmentId: // value for 'appointmentId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useFormSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
      }
export function useFormSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
        }
export function useFormSubmissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
        }
export type FormSubmissionsQueryHookResult = ReturnType<typeof useFormSubmissionsQuery>;
export type FormSubmissionsLazyQueryHookResult = ReturnType<typeof useFormSubmissionsLazyQuery>;
export type FormSubmissionsSuspenseQueryHookResult = ReturnType<typeof useFormSubmissionsSuspenseQuery>;
export type FormSubmissionsQueryResult = Apollo.QueryResult<FormSubmissionsQuery, FormSubmissionsQueryVariables>;
export const UpdateFormSubmissionDocument = gql`
    mutation updateFormSubmission($id: ID!, $read: Boolean!) {
  submission: updateFormSubmission(id: $id, read: $read) {
    id
    readAt
  }
}
    `;
export type UpdateFormSubmissionMutationFn = Apollo.MutationFunction<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>;

/**
 * __useUpdateFormSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormSubmissionMutation, { data, loading, error }] = useUpdateFormSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpdateFormSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>(UpdateFormSubmissionDocument, options);
      }
export type UpdateFormSubmissionMutationHookResult = ReturnType<typeof useUpdateFormSubmissionMutation>;
export type UpdateFormSubmissionMutationResult = Apollo.MutationResult<UpdateFormSubmissionMutation>;
export type UpdateFormSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>;
export const UpdateFormDraftDocument = gql`
    mutation updateFormDraft($formId: ID!, $steps: Json, $settings: Json) {
  updateFormDraft(formId: $formId, steps: $steps, settings: $settings) {
    ...FormEditorFields
  }
}
    ${FormEditorFieldsFragmentDoc}`;
export type UpdateFormDraftMutationFn = Apollo.MutationFunction<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>;

/**
 * __useUpdateFormDraftMutation__
 *
 * To run a mutation, you first call `useUpdateFormDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormDraftMutation, { data, loading, error }] = useUpdateFormDraftMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      steps: // value for 'steps'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateFormDraftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>(UpdateFormDraftDocument, options);
      }
export type UpdateFormDraftMutationHookResult = ReturnType<typeof useUpdateFormDraftMutation>;
export type UpdateFormDraftMutationResult = Apollo.MutationResult<UpdateFormDraftMutation>;
export type UpdateFormDraftMutationOptions = Apollo.BaseMutationOptions<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>;
export const InvalidateApiKeyDocument = gql`
    mutation invalidateApiKey($id: ID!) {
  invalidateApiKey(id: $id) {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
    invalidatedAt
  }
}
    `;
export type InvalidateApiKeyMutationFn = Apollo.MutationFunction<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>;

/**
 * __useInvalidateApiKeyMutation__
 *
 * To run a mutation, you first call `useInvalidateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateApiKeyMutation, { data, loading, error }] = useInvalidateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>(InvalidateApiKeyDocument, options);
      }
export type InvalidateApiKeyMutationHookResult = ReturnType<typeof useInvalidateApiKeyMutation>;
export type InvalidateApiKeyMutationResult = Apollo.MutationResult<InvalidateApiKeyMutation>;
export type InvalidateApiKeyMutationOptions = Apollo.BaseMutationOptions<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>;
export const BusinessLocationDocument = gql`
    query businessLocation($id: ID!) {
  Location(id: $id) {
    description
    address
    createdAt
    googlePlaceAddress
    googlePlaceId
    googlePlaceMapUrl
    googlePlaceImageUrl
    googlePlaceName
    id
    instructions
    lat
    lng
    name
    phone
    timezone
    updatedAt
    workingHours
    business {
      id
      name
    }
    picture {
      id
      url
    }
    services {
      id
      name
    }
  }
}
    `;

/**
 * __useBusinessLocationQuery__
 *
 * To run a query within a React component, call `useBusinessLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessLocationQuery(baseOptions: Apollo.QueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables> & ({ variables: BusinessLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
      }
export function useBusinessLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
        }
export function useBusinessLocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
        }
export type BusinessLocationQueryHookResult = ReturnType<typeof useBusinessLocationQuery>;
export type BusinessLocationLazyQueryHookResult = ReturnType<typeof useBusinessLocationLazyQuery>;
export type BusinessLocationSuspenseQueryHookResult = ReturnType<typeof useBusinessLocationSuspenseQuery>;
export type BusinessLocationQueryResult = Apollo.QueryResult<BusinessLocationQuery, BusinessLocationQueryVariables>;
export const BusinessLocationsDocument = gql`
    query businessLocations($limit: Int, $skip: Int, $search: String) {
  businessLocations(limit: $limit, skip: $skip, search: $search) {
    address
    createdAt
    googlePlaceAddress
    googlePlaceId
    googlePlaceMapUrl
    googlePlaceImageUrl
    googlePlaceName
    id
    instructions
    lat
    lng
    name
    phone
    timezone
    updatedAt
    workingHours
    business {
      id
      name
    }
    picture {
      id
      url
    }
    services {
      id
      name
    }
  }
  count: businessLocationsCount(search: $search)
}
    `;

/**
 * __useBusinessLocationsQuery__
 *
 * To run a query within a React component, call `useBusinessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
      }
export function useBusinessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
        }
export function useBusinessLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
        }
export type BusinessLocationsQueryHookResult = ReturnType<typeof useBusinessLocationsQuery>;
export type BusinessLocationsLazyQueryHookResult = ReturnType<typeof useBusinessLocationsLazyQuery>;
export type BusinessLocationsSuspenseQueryHookResult = ReturnType<typeof useBusinessLocationsSuspenseQuery>;
export type BusinessLocationsQueryResult = Apollo.QueryResult<BusinessLocationsQuery, BusinessLocationsQueryVariables>;
export const SelectLocationsListDocument = gql`
    query selectLocationsList($limit: Int, $skip: Int, $search: String) {
  locations: businessLocations(limit: $limit, skip: $skip, search: $search) {
    id
    name
    address
  }
}
    `;

/**
 * __useSelectLocationsListQuery__
 *
 * To run a query within a React component, call `useSelectLocationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectLocationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectLocationsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSelectLocationsListQuery(baseOptions?: Apollo.QueryHookOptions<SelectLocationsListQuery, SelectLocationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectLocationsListQuery, SelectLocationsListQueryVariables>(SelectLocationsListDocument, options);
      }
export function useSelectLocationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectLocationsListQuery, SelectLocationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectLocationsListQuery, SelectLocationsListQueryVariables>(SelectLocationsListDocument, options);
        }
export function useSelectLocationsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectLocationsListQuery, SelectLocationsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectLocationsListQuery, SelectLocationsListQueryVariables>(SelectLocationsListDocument, options);
        }
export type SelectLocationsListQueryHookResult = ReturnType<typeof useSelectLocationsListQuery>;
export type SelectLocationsListLazyQueryHookResult = ReturnType<typeof useSelectLocationsListLazyQuery>;
export type SelectLocationsListSuspenseQueryHookResult = ReturnType<typeof useSelectLocationsListSuspenseQuery>;
export type SelectLocationsListQueryResult = Apollo.QueryResult<SelectLocationsListQuery, SelectLocationsListQueryVariables>;
export const CreateBusinessLocationDocument = gql`
    mutation createBusinessLocation($name: String!, $address: String, $instructions: String, $phone: String, $lat: Float, $lng: Float, $timezone: String, $workingHours: Json, $googlePlaceId: String, $googlePlaceName: String, $googlePlaceAddress: String, $googlePlaceMapUrl: String, $googlePlaceImageUrl: String, $pictureId: ID) {
  createLocation(
    name: $name
    address: $address
    instructions: $instructions
    phone: $phone
    lat: $lat
    lng: $lng
    timezone: $timezone
    workingHours: $workingHours
    googlePlaceId: $googlePlaceId
    googlePlaceName: $googlePlaceName
    googlePlaceAddress: $googlePlaceAddress
    googlePlaceMapUrl: $googlePlaceMapUrl
    googlePlaceImageUrl: $googlePlaceImageUrl
    pictureId: $pictureId
  ) {
    id
    name
    address
    instructions
    phone
    lat
    lng
    timezone
    workingHours
    googlePlaceId
    googlePlaceName
    googlePlaceAddress
    googlePlaceMapUrl
    googlePlaceImageUrl
    picture {
      id
    }
    business {
      id
    }
  }
}
    `;
export type CreateBusinessLocationMutationFn = Apollo.MutationFunction<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>;

/**
 * __useCreateBusinessLocationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessLocationMutation, { data, loading, error }] = useCreateBusinessLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      instructions: // value for 'instructions'
 *      phone: // value for 'phone'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      googlePlaceName: // value for 'googlePlaceName'
 *      googlePlaceAddress: // value for 'googlePlaceAddress'
 *      googlePlaceMapUrl: // value for 'googlePlaceMapUrl'
 *      googlePlaceImageUrl: // value for 'googlePlaceImageUrl'
 *      pictureId: // value for 'pictureId'
 *   },
 * });
 */
export function useCreateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>(CreateBusinessLocationDocument, options);
      }
export type CreateBusinessLocationMutationHookResult = ReturnType<typeof useCreateBusinessLocationMutation>;
export type CreateBusinessLocationMutationResult = Apollo.MutationResult<CreateBusinessLocationMutation>;
export type CreateBusinessLocationMutationOptions = Apollo.BaseMutationOptions<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>;
export const DeleteBusinessLocationDocument = gql`
    mutation deleteBusinessLocation($id: ID!) {
  deleteLocation(id: $id)
}
    `;
export type DeleteBusinessLocationMutationFn = Apollo.MutationFunction<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>;

/**
 * __useDeleteBusinessLocationMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessLocationMutation, { data, loading, error }] = useDeleteBusinessLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>(DeleteBusinessLocationDocument, options);
      }
export type DeleteBusinessLocationMutationHookResult = ReturnType<typeof useDeleteBusinessLocationMutation>;
export type DeleteBusinessLocationMutationResult = Apollo.MutationResult<DeleteBusinessLocationMutation>;
export type DeleteBusinessLocationMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>;
export const UpdateBusinessLocationDocument = gql`
    mutation updateBusinessLocation($id: ID!, $name: String!, $address: String, $instructions: String, $phone: String, $lat: Float, $lng: Float, $timezone: String, $workingHours: Json, $googlePlaceId: String, $googlePlaceName: String, $googlePlaceAddress: String, $googlePlaceMapUrl: String, $googlePlaceImageUrl: String, $pictureId: ID, $description: String) {
  updateLocation(
    id: $id
    name: $name
    address: $address
    instructions: $instructions
    phone: $phone
    lat: $lat
    lng: $lng
    timezone: $timezone
    workingHours: $workingHours
    googlePlaceId: $googlePlaceId
    googlePlaceName: $googlePlaceName
    googlePlaceAddress: $googlePlaceAddress
    googlePlaceMapUrl: $googlePlaceMapUrl
    googlePlaceImageUrl: $googlePlaceImageUrl
    pictureId: $pictureId
    description: $description
  ) {
    id
    name
    address
    instructions
    phone
    lat
    lng
    timezone
    workingHours
    googlePlaceId
    googlePlaceName
    googlePlaceAddress
    googlePlaceMapUrl
    googlePlaceImageUrl
    description
    picture {
      id
      url
    }
    business {
      id
    }
    services {
      id
    }
  }
}
    `;
export type UpdateBusinessLocationMutationFn = Apollo.MutationFunction<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>;

/**
 * __useUpdateBusinessLocationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessLocationMutation, { data, loading, error }] = useUpdateBusinessLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      instructions: // value for 'instructions'
 *      phone: // value for 'phone'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      googlePlaceName: // value for 'googlePlaceName'
 *      googlePlaceAddress: // value for 'googlePlaceAddress'
 *      googlePlaceMapUrl: // value for 'googlePlaceMapUrl'
 *      googlePlaceImageUrl: // value for 'googlePlaceImageUrl'
 *      pictureId: // value for 'pictureId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>(UpdateBusinessLocationDocument, options);
      }
export type UpdateBusinessLocationMutationHookResult = ReturnType<typeof useUpdateBusinessLocationMutation>;
export type UpdateBusinessLocationMutationResult = Apollo.MutationResult<UpdateBusinessLocationMutation>;
export type UpdateBusinessLocationMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>;
export const MessageTemplatesDocument = gql`
    query messageTemplates($skip: Int, $limit: Int) {
  messageTemplates(skip: $skip, limit: $limit) {
    templates {
      ...MessageTemplateFields
    }
    totalCount
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;

/**
 * __useMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MessageTemplatesQuery, MessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageTemplatesQuery, MessageTemplatesQueryVariables>(MessageTemplatesDocument, options);
      }
export function useMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageTemplatesQuery, MessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageTemplatesQuery, MessageTemplatesQueryVariables>(MessageTemplatesDocument, options);
        }
export function useMessageTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MessageTemplatesQuery, MessageTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageTemplatesQuery, MessageTemplatesQueryVariables>(MessageTemplatesDocument, options);
        }
export type MessageTemplatesQueryHookResult = ReturnType<typeof useMessageTemplatesQuery>;
export type MessageTemplatesLazyQueryHookResult = ReturnType<typeof useMessageTemplatesLazyQuery>;
export type MessageTemplatesSuspenseQueryHookResult = ReturnType<typeof useMessageTemplatesSuspenseQuery>;
export type MessageTemplatesQueryResult = Apollo.QueryResult<MessageTemplatesQuery, MessageTemplatesQueryVariables>;
export const MessageTemplateDocument = gql`
    query messageTemplate($id: ID!) {
  messageTemplate(id: $id) {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;

/**
 * __useMessageTemplateQuery__
 *
 * To run a query within a React component, call `useMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageTemplateQuery(baseOptions: Apollo.QueryHookOptions<MessageTemplateQuery, MessageTemplateQueryVariables> & ({ variables: MessageTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageTemplateQuery, MessageTemplateQueryVariables>(MessageTemplateDocument, options);
      }
export function useMessageTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageTemplateQuery, MessageTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageTemplateQuery, MessageTemplateQueryVariables>(MessageTemplateDocument, options);
        }
export function useMessageTemplateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MessageTemplateQuery, MessageTemplateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageTemplateQuery, MessageTemplateQueryVariables>(MessageTemplateDocument, options);
        }
export type MessageTemplateQueryHookResult = ReturnType<typeof useMessageTemplateQuery>;
export type MessageTemplateLazyQueryHookResult = ReturnType<typeof useMessageTemplateLazyQuery>;
export type MessageTemplateSuspenseQueryHookResult = ReturnType<typeof useMessageTemplateSuspenseQuery>;
export type MessageTemplateQueryResult = Apollo.QueryResult<MessageTemplateQuery, MessageTemplateQueryVariables>;
export const CreateMessageTemplateDocument = gql`
    mutation createMessageTemplate($name: String!, $description: String, $content: String!) {
  createMessageTemplate(name: $name, description: $description, content: $content) {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CreateMessageTemplateDocument, options);
      }
export type CreateMessageTemplateMutationHookResult = ReturnType<typeof useCreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationResult = Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;
export const UpdateMessageTemplateDocument = gql`
    mutation updateMessageTemplate($id: ID!, $name: String, $description: String, $content: String) {
  updateMessageTemplate(
    id: $id
    name: $name
    description: $description
    content: $content
  ) {
    ...MessageTemplateFields
  }
}
    ${MessageTemplateFieldsFragmentDoc}`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument, options);
      }
export type UpdateMessageTemplateMutationHookResult = ReturnType<typeof useUpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationResult = Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;
export const DeleteMessageTemplateDocument = gql`
    mutation deleteMessageTemplate($id: ID!) {
  success: deleteMessageTemplate(id: $id)
}
    `;
export type DeleteMessageTemplateMutationFn = Apollo.MutationFunction<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;

/**
 * __useDeleteMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageTemplateMutation, { data, loading, error }] = useDeleteMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>(DeleteMessageTemplateDocument, options);
      }
export type DeleteMessageTemplateMutationHookResult = ReturnType<typeof useDeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationResult = Apollo.MutationResult<DeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;
export const MessagesDocument = gql`
    query messages($threadId: ID!) {
  messages: threadMessages(threadId: $threadId) {
    id
    createdAt
    readAt
    sentBy
    sentAt
    content
    patient {
      id
      name
    }
    staff {
      id
      name
      picture {
        id
        url
      }
    }
    attachments {
      id
      url
      label
      type
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables> & ({ variables: MessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export function useMessagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesSuspenseQueryHookResult = ReturnType<typeof useMessagesSuspenseQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($threadId: ID!, $content: String!, $attachments: [MessageAttachmentInput!]) {
  sendMessage(threadId: $threadId, content: $content, attachments: $attachments) {
    id
    createdAt
    readAt
    sentBy
    content
    patient {
      id
      name
    }
    staff {
      id
      name
    }
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const UpsertMessageDraftDocument = gql`
    mutation upsertMessageDraft($threadId: ID!, $content: String!, $attachments: [MessageAttachmentInput!]) {
  upsertMessageDraft(
    threadId: $threadId
    content: $content
    attachments: $attachments
  ) {
    id
    createdAt
    readAt
    sentBy
    content
    patient {
      id
      name
    }
    staff {
      id
      name
    }
  }
}
    `;
export type UpsertMessageDraftMutationFn = Apollo.MutationFunction<UpsertMessageDraftMutation, UpsertMessageDraftMutationVariables>;

/**
 * __useUpsertMessageDraftMutation__
 *
 * To run a mutation, you first call `useUpsertMessageDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMessageDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMessageDraftMutation, { data, loading, error }] = useUpsertMessageDraftMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useUpsertMessageDraftMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMessageDraftMutation, UpsertMessageDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMessageDraftMutation, UpsertMessageDraftMutationVariables>(UpsertMessageDraftDocument, options);
      }
export type UpsertMessageDraftMutationHookResult = ReturnType<typeof useUpsertMessageDraftMutation>;
export type UpsertMessageDraftMutationResult = Apollo.MutationResult<UpsertMessageDraftMutation>;
export type UpsertMessageDraftMutationOptions = Apollo.BaseMutationOptions<UpsertMessageDraftMutation, UpsertMessageDraftMutationVariables>;
export const ReadMessageDocument = gql`
    mutation readMessage($id: ID!) {
  readMessage(id: $id) {
    id
    readAt
  }
}
    `;
export type ReadMessageMutationFn = Apollo.MutationFunction<ReadMessageMutation, ReadMessageMutationVariables>;

/**
 * __useReadMessageMutation__
 *
 * To run a mutation, you first call `useReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessageMutation, { data, loading, error }] = useReadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadMessageMutation(baseOptions?: Apollo.MutationHookOptions<ReadMessageMutation, ReadMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadMessageMutation, ReadMessageMutationVariables>(ReadMessageDocument, options);
      }
export type ReadMessageMutationHookResult = ReturnType<typeof useReadMessageMutation>;
export type ReadMessageMutationResult = Apollo.MutationResult<ReadMessageMutation>;
export type ReadMessageMutationOptions = Apollo.BaseMutationOptions<ReadMessageMutation, ReadMessageMutationVariables>;
export const UnreadMessageDocument = gql`
    mutation unreadMessage($id: ID!) {
  unreadMessage(id: $id) {
    id
    readAt
  }
}
    `;
export type UnreadMessageMutationFn = Apollo.MutationFunction<UnreadMessageMutation, UnreadMessageMutationVariables>;

/**
 * __useUnreadMessageMutation__
 *
 * To run a mutation, you first call `useUnreadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unreadMessageMutation, { data, loading, error }] = useUnreadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnreadMessageMutation(baseOptions?: Apollo.MutationHookOptions<UnreadMessageMutation, UnreadMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnreadMessageMutation, UnreadMessageMutationVariables>(UnreadMessageDocument, options);
      }
export type UnreadMessageMutationHookResult = ReturnType<typeof useUnreadMessageMutation>;
export type UnreadMessageMutationResult = Apollo.MutationResult<UnreadMessageMutation>;
export type UnreadMessageMutationOptions = Apollo.BaseMutationOptions<UnreadMessageMutation, UnreadMessageMutationVariables>;
export const StaffThreadsDocument = gql`
    query staffThreads($status: ThreadStatus!, $limit: Int, $skip: Int) {
  staffThreads(status: $status, limit: $limit, skip: $skip) {
    threads {
      ...ThreadFields
    }
    totalCount
  }
}
    ${ThreadFieldsFragmentDoc}`;

/**
 * __useStaffThreadsQuery__
 *
 * To run a query within a React component, call `useStaffThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffThreadsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useStaffThreadsQuery(baseOptions: Apollo.QueryHookOptions<StaffThreadsQuery, StaffThreadsQueryVariables> & ({ variables: StaffThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffThreadsQuery, StaffThreadsQueryVariables>(StaffThreadsDocument, options);
      }
export function useStaffThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffThreadsQuery, StaffThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffThreadsQuery, StaffThreadsQueryVariables>(StaffThreadsDocument, options);
        }
export function useStaffThreadsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StaffThreadsQuery, StaffThreadsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffThreadsQuery, StaffThreadsQueryVariables>(StaffThreadsDocument, options);
        }
export type StaffThreadsQueryHookResult = ReturnType<typeof useStaffThreadsQuery>;
export type StaffThreadsLazyQueryHookResult = ReturnType<typeof useStaffThreadsLazyQuery>;
export type StaffThreadsSuspenseQueryHookResult = ReturnType<typeof useStaffThreadsSuspenseQuery>;
export type StaffThreadsQueryResult = Apollo.QueryResult<StaffThreadsQuery, StaffThreadsQueryVariables>;
export const PatientThreadsDocument = gql`
    query patientThreads($patientId: ID!, $status: ThreadStatus!, $limit: Int, $skip: Int) {
  patientThreads(
    patientId: $patientId
    status: $status
    limit: $limit
    skip: $skip
  ) {
    threads {
      ...ThreadFields
    }
    totalCount
  }
}
    ${ThreadFieldsFragmentDoc}`;

/**
 * __usePatientThreadsQuery__
 *
 * To run a query within a React component, call `usePatientThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientThreadsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePatientThreadsQuery(baseOptions: Apollo.QueryHookOptions<PatientThreadsQuery, PatientThreadsQueryVariables> & ({ variables: PatientThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientThreadsQuery, PatientThreadsQueryVariables>(PatientThreadsDocument, options);
      }
export function usePatientThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientThreadsQuery, PatientThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientThreadsQuery, PatientThreadsQueryVariables>(PatientThreadsDocument, options);
        }
export function usePatientThreadsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientThreadsQuery, PatientThreadsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientThreadsQuery, PatientThreadsQueryVariables>(PatientThreadsDocument, options);
        }
export type PatientThreadsQueryHookResult = ReturnType<typeof usePatientThreadsQuery>;
export type PatientThreadsLazyQueryHookResult = ReturnType<typeof usePatientThreadsLazyQuery>;
export type PatientThreadsSuspenseQueryHookResult = ReturnType<typeof usePatientThreadsSuspenseQuery>;
export type PatientThreadsQueryResult = Apollo.QueryResult<PatientThreadsQuery, PatientThreadsQueryVariables>;
export const ThreadDocument = gql`
    query thread($id: ID!) {
  thread(id: $id) {
    ...ThreadFields
  }
  messageDraft(threadId: $id) {
    id
    content
    attachments {
      id
      url
      label
      type
    }
  }
}
    ${ThreadFieldsFragmentDoc}`;

/**
 * __useThreadQuery__
 *
 * To run a query within a React component, call `useThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreadQuery(baseOptions: Apollo.QueryHookOptions<ThreadQuery, ThreadQueryVariables> & ({ variables: ThreadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreadQuery, ThreadQueryVariables>(ThreadDocument, options);
      }
export function useThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreadQuery, ThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreadQuery, ThreadQueryVariables>(ThreadDocument, options);
        }
export function useThreadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThreadQuery, ThreadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThreadQuery, ThreadQueryVariables>(ThreadDocument, options);
        }
export type ThreadQueryHookResult = ReturnType<typeof useThreadQuery>;
export type ThreadLazyQueryHookResult = ReturnType<typeof useThreadLazyQuery>;
export type ThreadSuspenseQueryHookResult = ReturnType<typeof useThreadSuspenseQuery>;
export type ThreadQueryResult = Apollo.QueryResult<ThreadQuery, ThreadQueryVariables>;
export const CreateMessageThreadDocument = gql`
    mutation createMessageThread($patientId: ID!, $subject: String!, $content: String!, $attachments: [MessageAttachmentInput!]) {
  createMessageThread(
    patientId: $patientId
    subject: $subject
    content: $content
    attachments: $attachments
  ) {
    ...ThreadFields
  }
}
    ${ThreadFieldsFragmentDoc}`;
export type CreateMessageThreadMutationFn = Apollo.MutationFunction<CreateMessageThreadMutation, CreateMessageThreadMutationVariables>;

/**
 * __useCreateMessageThreadMutation__
 *
 * To run a mutation, you first call `useCreateMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageThreadMutation, { data, loading, error }] = useCreateMessageThreadMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      subject: // value for 'subject'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateMessageThreadMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageThreadMutation, CreateMessageThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageThreadMutation, CreateMessageThreadMutationVariables>(CreateMessageThreadDocument, options);
      }
export type CreateMessageThreadMutationHookResult = ReturnType<typeof useCreateMessageThreadMutation>;
export type CreateMessageThreadMutationResult = Apollo.MutationResult<CreateMessageThreadMutation>;
export type CreateMessageThreadMutationOptions = Apollo.BaseMutationOptions<CreateMessageThreadMutation, CreateMessageThreadMutationVariables>;
export const CreateThreadDocument = gql`
    mutation createThread($patientId: ID!) {
  createThread(patientId: $patientId) {
    ...ThreadFields
  }
}
    ${ThreadFieldsFragmentDoc}`;
export type CreateThreadMutationFn = Apollo.MutationFunction<CreateThreadMutation, CreateThreadMutationVariables>;

/**
 * __useCreateThreadMutation__
 *
 * To run a mutation, you first call `useCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadMutation, { data, loading, error }] = useCreateThreadMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCreateThreadMutation(baseOptions?: Apollo.MutationHookOptions<CreateThreadMutation, CreateThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThreadMutation, CreateThreadMutationVariables>(CreateThreadDocument, options);
      }
export type CreateThreadMutationHookResult = ReturnType<typeof useCreateThreadMutation>;
export type CreateThreadMutationResult = Apollo.MutationResult<CreateThreadMutation>;
export type CreateThreadMutationOptions = Apollo.BaseMutationOptions<CreateThreadMutation, CreateThreadMutationVariables>;
export const UpdateThreadDocument = gql`
    mutation updateThread($id: ID!, $subject: String!) {
  updateThread(id: $id, subject: $subject) {
    ...ThreadFields
  }
}
    ${ThreadFieldsFragmentDoc}`;
export type UpdateThreadMutationFn = Apollo.MutationFunction<UpdateThreadMutation, UpdateThreadMutationVariables>;

/**
 * __useUpdateThreadMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMutation, { data, loading, error }] = useUpdateThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useUpdateThreadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThreadMutation, UpdateThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThreadMutation, UpdateThreadMutationVariables>(UpdateThreadDocument, options);
      }
export type UpdateThreadMutationHookResult = ReturnType<typeof useUpdateThreadMutation>;
export type UpdateThreadMutationResult = Apollo.MutationResult<UpdateThreadMutation>;
export type UpdateThreadMutationOptions = Apollo.BaseMutationOptions<UpdateThreadMutation, UpdateThreadMutationVariables>;
export const ArchiveMessageThreadDocument = gql`
    mutation archiveMessageThread($id: ID!) {
  archiveMessageThread(id: $id) {
    id
    archivedAt
  }
}
    `;
export type ArchiveMessageThreadMutationFn = Apollo.MutationFunction<ArchiveMessageThreadMutation, ArchiveMessageThreadMutationVariables>;

/**
 * __useArchiveMessageThreadMutation__
 *
 * To run a mutation, you first call `useArchiveMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMessageThreadMutation, { data, loading, error }] = useArchiveMessageThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMessageThreadMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMessageThreadMutation, ArchiveMessageThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveMessageThreadMutation, ArchiveMessageThreadMutationVariables>(ArchiveMessageThreadDocument, options);
      }
export type ArchiveMessageThreadMutationHookResult = ReturnType<typeof useArchiveMessageThreadMutation>;
export type ArchiveMessageThreadMutationResult = Apollo.MutationResult<ArchiveMessageThreadMutation>;
export type ArchiveMessageThreadMutationOptions = Apollo.BaseMutationOptions<ArchiveMessageThreadMutation, ArchiveMessageThreadMutationVariables>;
export const RestoreMessageThreadDocument = gql`
    mutation restoreMessageThread($id: ID!) {
  restoreMessageThread(id: $id) {
    id
    archivedAt
  }
}
    `;
export type RestoreMessageThreadMutationFn = Apollo.MutationFunction<RestoreMessageThreadMutation, RestoreMessageThreadMutationVariables>;

/**
 * __useRestoreMessageThreadMutation__
 *
 * To run a mutation, you first call `useRestoreMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMessageThreadMutation, { data, loading, error }] = useRestoreMessageThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreMessageThreadMutation(baseOptions?: Apollo.MutationHookOptions<RestoreMessageThreadMutation, RestoreMessageThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreMessageThreadMutation, RestoreMessageThreadMutationVariables>(RestoreMessageThreadDocument, options);
      }
export type RestoreMessageThreadMutationHookResult = ReturnType<typeof useRestoreMessageThreadMutation>;
export type RestoreMessageThreadMutationResult = Apollo.MutationResult<RestoreMessageThreadMutation>;
export type RestoreMessageThreadMutationOptions = Apollo.BaseMutationOptions<RestoreMessageThreadMutation, RestoreMessageThreadMutationVariables>;
export const NoteTemplatesDocument = gql`
    query noteTemplates($skip: Int, $limit: Int) {
  noteTemplates(skip: $skip, limit: $limit) {
    ...NoteTemplateFields
  }
  noteTemplatesCount
}
    ${NoteTemplateFieldsFragmentDoc}`;

/**
 * __useNoteTemplatesQuery__
 *
 * To run a query within a React component, call `useNoteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTemplatesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNoteTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<NoteTemplatesQuery, NoteTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteTemplatesQuery, NoteTemplatesQueryVariables>(NoteTemplatesDocument, options);
      }
export function useNoteTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteTemplatesQuery, NoteTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteTemplatesQuery, NoteTemplatesQueryVariables>(NoteTemplatesDocument, options);
        }
export function useNoteTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NoteTemplatesQuery, NoteTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NoteTemplatesQuery, NoteTemplatesQueryVariables>(NoteTemplatesDocument, options);
        }
export type NoteTemplatesQueryHookResult = ReturnType<typeof useNoteTemplatesQuery>;
export type NoteTemplatesLazyQueryHookResult = ReturnType<typeof useNoteTemplatesLazyQuery>;
export type NoteTemplatesSuspenseQueryHookResult = ReturnType<typeof useNoteTemplatesSuspenseQuery>;
export type NoteTemplatesQueryResult = Apollo.QueryResult<NoteTemplatesQuery, NoteTemplatesQueryVariables>;
export const NoteTemplateDocument = gql`
    query noteTemplate($id: ID!) {
  noteTemplate(id: $id) {
    ...NoteTemplateFields
  }
}
    ${NoteTemplateFieldsFragmentDoc}`;

/**
 * __useNoteTemplateQuery__
 *
 * To run a query within a React component, call `useNoteTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteTemplateQuery(baseOptions: Apollo.QueryHookOptions<NoteTemplateQuery, NoteTemplateQueryVariables> & ({ variables: NoteTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteTemplateQuery, NoteTemplateQueryVariables>(NoteTemplateDocument, options);
      }
export function useNoteTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteTemplateQuery, NoteTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteTemplateQuery, NoteTemplateQueryVariables>(NoteTemplateDocument, options);
        }
export function useNoteTemplateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NoteTemplateQuery, NoteTemplateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NoteTemplateQuery, NoteTemplateQueryVariables>(NoteTemplateDocument, options);
        }
export type NoteTemplateQueryHookResult = ReturnType<typeof useNoteTemplateQuery>;
export type NoteTemplateLazyQueryHookResult = ReturnType<typeof useNoteTemplateLazyQuery>;
export type NoteTemplateSuspenseQueryHookResult = ReturnType<typeof useNoteTemplateSuspenseQuery>;
export type NoteTemplateQueryResult = Apollo.QueryResult<NoteTemplateQuery, NoteTemplateQueryVariables>;
export const CreateNoteTemplateDocument = gql`
    mutation createNoteTemplate($name: String!, $content: String!) {
  createNoteTemplate(name: $name, content: $content) {
    ...NoteTemplateFields
  }
}
    ${NoteTemplateFieldsFragmentDoc}`;
export type CreateNoteTemplateMutationFn = Apollo.MutationFunction<CreateNoteTemplateMutation, CreateNoteTemplateMutationVariables>;

/**
 * __useCreateNoteTemplateMutation__
 *
 * To run a mutation, you first call `useCreateNoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteTemplateMutation, { data, loading, error }] = useCreateNoteTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateNoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteTemplateMutation, CreateNoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteTemplateMutation, CreateNoteTemplateMutationVariables>(CreateNoteTemplateDocument, options);
      }
export type CreateNoteTemplateMutationHookResult = ReturnType<typeof useCreateNoteTemplateMutation>;
export type CreateNoteTemplateMutationResult = Apollo.MutationResult<CreateNoteTemplateMutation>;
export type CreateNoteTemplateMutationOptions = Apollo.BaseMutationOptions<CreateNoteTemplateMutation, CreateNoteTemplateMutationVariables>;
export const UpdateNoteTemplateDocument = gql`
    mutation updateNoteTemplate($id: ID!, $name: String, $content: String) {
  updateNoteTemplate(id: $id, name: $name, content: $content) {
    ...NoteTemplateFields
  }
}
    ${NoteTemplateFieldsFragmentDoc}`;
export type UpdateNoteTemplateMutationFn = Apollo.MutationFunction<UpdateNoteTemplateMutation, UpdateNoteTemplateMutationVariables>;

/**
 * __useUpdateNoteTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateNoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteTemplateMutation, { data, loading, error }] = useUpdateNoteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateNoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteTemplateMutation, UpdateNoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteTemplateMutation, UpdateNoteTemplateMutationVariables>(UpdateNoteTemplateDocument, options);
      }
export type UpdateNoteTemplateMutationHookResult = ReturnType<typeof useUpdateNoteTemplateMutation>;
export type UpdateNoteTemplateMutationResult = Apollo.MutationResult<UpdateNoteTemplateMutation>;
export type UpdateNoteTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateNoteTemplateMutation, UpdateNoteTemplateMutationVariables>;
export const DeleteNoteTemplateDocument = gql`
    mutation deleteNoteTemplate($id: ID!) {
  success: deleteNoteTemplate(id: $id)
}
    `;
export type DeleteNoteTemplateMutationFn = Apollo.MutationFunction<DeleteNoteTemplateMutation, DeleteNoteTemplateMutationVariables>;

/**
 * __useDeleteNoteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteNoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteTemplateMutation, { data, loading, error }] = useDeleteNoteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteTemplateMutation, DeleteNoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteTemplateMutation, DeleteNoteTemplateMutationVariables>(DeleteNoteTemplateDocument, options);
      }
export type DeleteNoteTemplateMutationHookResult = ReturnType<typeof useDeleteNoteTemplateMutation>;
export type DeleteNoteTemplateMutationResult = Apollo.MutationResult<DeleteNoteTemplateMutation>;
export type DeleteNoteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteNoteTemplateMutation, DeleteNoteTemplateMutationVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($appointmentId: ID, $patientId: ID!, $templateId: ID) {
  createNote(
    appointmentId: $appointmentId
    patientId: $patientId
    templateId: $templateId
  ) {
    id
    content
    title
    createdAt
    updatedAt
    appointment {
      id
    }
    patient {
      id
    }
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      patientId: // value for 'patientId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id)
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const NoteDocument = gql`
    query note($id: ID!) {
  note(id: $id) {
    id
    createdAt
    updatedAt
    appointment {
      id
    }
    patient {
      id
      archivedAt
    }
    staff {
      id
      name
      picture {
        id
        url
      }
    }
    content
    title
  }
}
    `;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQuery, NoteQueryVariables> & ({ variables: NoteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export function useNoteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteSuspenseQueryHookResult = ReturnType<typeof useNoteSuspenseQuery>;
export type NoteQueryResult = Apollo.QueryResult<NoteQuery, NoteQueryVariables>;
export const PatientNotesDocument = gql`
    query patientNotes($patientId: ID!, $appointmentId: ID, $limit: Int, $skip: Int) {
  notes: patientNotes(
    patientId: $patientId
    appointmentId: $appointmentId
    limit: $limit
    skip: $skip
  ) {
    id
    createdAt
    title
    content
    appointment {
      id
    }
    staff {
      id
      name
      email
      picture {
        id
        url
      }
    }
  }
  count: patientNotesCount(patientId: $patientId, appointmentId: $appointmentId)
}
    `;

/**
 * __usePatientNotesQuery__
 *
 * To run a query within a React component, call `usePatientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNotesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePatientNotesQuery(baseOptions: Apollo.QueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables> & ({ variables: PatientNotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
      }
export function usePatientNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
        }
export function usePatientNotesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
        }
export type PatientNotesQueryHookResult = ReturnType<typeof usePatientNotesQuery>;
export type PatientNotesLazyQueryHookResult = ReturnType<typeof usePatientNotesLazyQuery>;
export type PatientNotesSuspenseQueryHookResult = ReturnType<typeof usePatientNotesSuspenseQuery>;
export type PatientNotesQueryResult = Apollo.QueryResult<PatientNotesQuery, PatientNotesQueryVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: ID!, $content: String!, $title: String) {
  updateNote(id: $id, content: $content, title: $title) {
    id
    content
    title
    createdAt
    updatedAt
    appointment {
      id
    }
    patient {
      id
    }
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const BusinessNotificationsDocument = gql`
    query businessNotifications($businessId: ID!, $limit: Int!, $after: ID) {
  notifications: businessNotifications(
    businessId: $businessId
    limit: $limit
    after: $after
  ) {
    id
    title
    content
    type
    read
    createdAt
  }
  totalCount: businessNotificationsCount(businessId: $businessId)
}
    `;

/**
 * __useBusinessNotificationsQuery__
 *
 * To run a query within a React component, call `useBusinessNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessNotificationsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBusinessNotificationsQuery(baseOptions: Apollo.QueryHookOptions<BusinessNotificationsQuery, BusinessNotificationsQueryVariables> & ({ variables: BusinessNotificationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>(BusinessNotificationsDocument, options);
      }
export function useBusinessNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>(BusinessNotificationsDocument, options);
        }
export function useBusinessNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>(BusinessNotificationsDocument, options);
        }
export type BusinessNotificationsQueryHookResult = ReturnType<typeof useBusinessNotificationsQuery>;
export type BusinessNotificationsLazyQueryHookResult = ReturnType<typeof useBusinessNotificationsLazyQuery>;
export type BusinessNotificationsSuspenseQueryHookResult = ReturnType<typeof useBusinessNotificationsSuspenseQuery>;
export type BusinessNotificationsQueryResult = Apollo.QueryResult<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>;
export const BookingEventNotificationTemplatesDocument = gql`
    query bookingEventNotificationTemplates($businessId: ID!) {
  templates: businessBookingEventNotificationTemplates(businessId: $businessId) {
    id
    event
    type
    services {
      id
      name
    }
    enabled
    deleted
    recipient
    messageTemplate
    defaultTemplate
  }
  services: businessServices(businessId: $businessId) {
    id
    name
    enabled
  }
  business: Business(id: $businessId) {
    id
    templates
  }
}
    `;

/**
 * __useBookingEventNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useBookingEventNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingEventNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingEventNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBookingEventNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables> & ({ variables: BookingEventNotificationTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>(BookingEventNotificationTemplatesDocument, options);
      }
export function useBookingEventNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>(BookingEventNotificationTemplatesDocument, options);
        }
export function useBookingEventNotificationTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>(BookingEventNotificationTemplatesDocument, options);
        }
export type BookingEventNotificationTemplatesQueryHookResult = ReturnType<typeof useBookingEventNotificationTemplatesQuery>;
export type BookingEventNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useBookingEventNotificationTemplatesLazyQuery>;
export type BookingEventNotificationTemplatesSuspenseQueryHookResult = ReturnType<typeof useBookingEventNotificationTemplatesSuspenseQuery>;
export type BookingEventNotificationTemplatesQueryResult = Apollo.QueryResult<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>;
export const CreateBookingEventNotificationTemplateDocument = gql`
    mutation createBookingEventNotificationTemplate($businessId: ID!, $recipient: MessageRecipient!, $type: MessageType!, $event: BookingEvent!, $services: [ID!]!) {
  createBookingEventNotificationTemplate(
    businessId: $businessId
    type: $type
    recipient: $recipient
    services: $services
    event: $event
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    type
    event
    recipient
    enabled
    deleted
  }
}
    `;
export type CreateBookingEventNotificationTemplateMutationFn = Apollo.MutationFunction<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>;

/**
 * __useCreateBookingEventNotificationTemplateMutation__
 *
 * To run a mutation, you first call `useCreateBookingEventNotificationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingEventNotificationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingEventNotificationTemplateMutation, { data, loading, error }] = useCreateBookingEventNotificationTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      recipient: // value for 'recipient'
 *      type: // value for 'type'
 *      event: // value for 'event'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateBookingEventNotificationTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>(CreateBookingEventNotificationTemplateDocument, options);
      }
export type CreateBookingEventNotificationTemplateMutationHookResult = ReturnType<typeof useCreateBookingEventNotificationTemplateMutation>;
export type CreateBookingEventNotificationTemplateMutationResult = Apollo.MutationResult<CreateBookingEventNotificationTemplateMutation>;
export type CreateBookingEventNotificationTemplateMutationOptions = Apollo.BaseMutationOptions<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>;
export const CreateFollowupMessageTemplateDocument = gql`
    mutation createFollowupMessageTemplate($businessId: ID!, $minutes: Int!, $recipient: MessageRecipient!, $type: MessageType!, $services: [ID!]!) {
  createPostAppointmentMessageTemplate(
    businessId: $businessId
    minutes: $minutes
    type: $type
    recipient: $recipient
    services: $services
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    minutes
    type
    recipient
    enabled
    deleted
  }
}
    `;
export type CreateFollowupMessageTemplateMutationFn = Apollo.MutationFunction<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>;

/**
 * __useCreateFollowupMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFollowupMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowupMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowupMessageTemplateMutation, { data, loading, error }] = useCreateFollowupMessageTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      minutes: // value for 'minutes'
 *      recipient: // value for 'recipient'
 *      type: // value for 'type'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateFollowupMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>(CreateFollowupMessageTemplateDocument, options);
      }
export type CreateFollowupMessageTemplateMutationHookResult = ReturnType<typeof useCreateFollowupMessageTemplateMutation>;
export type CreateFollowupMessageTemplateMutationResult = Apollo.MutationResult<CreateFollowupMessageTemplateMutation>;
export type CreateFollowupMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>;
export const CreateReminderTemplateDocument = gql`
    mutation createReminderTemplate($businessId: ID!, $minutes: Int!, $type: ReminderType!, $resourceType: ReminderResourceType!, $services: [ID!]!) {
  createReminderTemplate(
    businessId: $businessId
    minutes: $minutes
    type: $type
    resourceType: $resourceType
    services: $services
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    minutes
    type
    resourceType
    enabled
    deleted
  }
}
    `;
export type CreateReminderTemplateMutationFn = Apollo.MutationFunction<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>;

/**
 * __useCreateReminderTemplateMutation__
 *
 * To run a mutation, you first call `useCreateReminderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderTemplateMutation, { data, loading, error }] = useCreateReminderTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      minutes: // value for 'minutes'
 *      type: // value for 'type'
 *      resourceType: // value for 'resourceType'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateReminderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>(CreateReminderTemplateDocument, options);
      }
export type CreateReminderTemplateMutationHookResult = ReturnType<typeof useCreateReminderTemplateMutation>;
export type CreateReminderTemplateMutationResult = Apollo.MutationResult<CreateReminderTemplateMutation>;
export type CreateReminderTemplateMutationOptions = Apollo.BaseMutationOptions<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>;
export const FollowupNotificationTemplatesDocument = gql`
    query followupNotificationTemplates($businessId: ID!) {
  postBookingTemplates: businessPostAppointmentMessageTemplates(
    businessId: $businessId
  ) {
    id
    minutes
    type
    enabled
    deleted
    recipient
    messageTemplate
    defaultTemplate
    services {
      id
      name
    }
  }
  services: businessServices(businessId: $businessId) {
    id
    name
  }
  business: Business(id: $businessId) {
    id
    templates
  }
  defaultMessageTemplates: defaultNotificationsTemplates {
    templates
  }
}
    `;

/**
 * __useFollowupNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useFollowupNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowupNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowupNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useFollowupNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables> & ({ variables: FollowupNotificationTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>(FollowupNotificationTemplatesDocument, options);
      }
export function useFollowupNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>(FollowupNotificationTemplatesDocument, options);
        }
export function useFollowupNotificationTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>(FollowupNotificationTemplatesDocument, options);
        }
export type FollowupNotificationTemplatesQueryHookResult = ReturnType<typeof useFollowupNotificationTemplatesQuery>;
export type FollowupNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useFollowupNotificationTemplatesLazyQuery>;
export type FollowupNotificationTemplatesSuspenseQueryHookResult = ReturnType<typeof useFollowupNotificationTemplatesSuspenseQuery>;
export type FollowupNotificationTemplatesQueryResult = Apollo.QueryResult<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>;
export const ReminderNotificationTemplatesDocument = gql`
    query reminderNotificationTemplates($businessId: ID!) {
  reminders: allReminderTemplates(businessId: $businessId) {
    id
    minutes
    type
    resourceType
    enabled
    deleted
    messageTemplate
    defaultTemplate
    services {
      id
      name
    }
  }
  business: Business(id: $businessId) {
    id
    templates
  }
  services: businessServices(businessId: $businessId) {
    id
    name
  }
  defaultMessageTemplates: defaultNotificationsTemplates {
    templates
  }
}
    `;

/**
 * __useReminderNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useReminderNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useReminderNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables> & ({ variables: ReminderNotificationTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>(ReminderNotificationTemplatesDocument, options);
      }
export function useReminderNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>(ReminderNotificationTemplatesDocument, options);
        }
export function useReminderNotificationTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>(ReminderNotificationTemplatesDocument, options);
        }
export type ReminderNotificationTemplatesQueryHookResult = ReturnType<typeof useReminderNotificationTemplatesQuery>;
export type ReminderNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useReminderNotificationTemplatesLazyQuery>;
export type ReminderNotificationTemplatesSuspenseQueryHookResult = ReturnType<typeof useReminderNotificationTemplatesSuspenseQuery>;
export type ReminderNotificationTemplatesQueryResult = Apollo.QueryResult<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>;
export const UpdateBookingEventNotificationTemplateDocument = gql`
    mutation updateBookingEventNotificationTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updateBookingEventNotificationTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdateBookingEventNotificationTemplateMutationFn = Apollo.MutationFunction<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>;

/**
 * __useUpdateBookingEventNotificationTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateBookingEventNotificationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingEventNotificationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingEventNotificationTemplateMutation, { data, loading, error }] = useUpdateBookingEventNotificationTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdateBookingEventNotificationTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>(UpdateBookingEventNotificationTemplateDocument, options);
      }
export type UpdateBookingEventNotificationTemplateMutationHookResult = ReturnType<typeof useUpdateBookingEventNotificationTemplateMutation>;
export type UpdateBookingEventNotificationTemplateMutationResult = Apollo.MutationResult<UpdateBookingEventNotificationTemplateMutation>;
export type UpdateBookingEventNotificationTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>;
export const UpdatePostAppointmentMessageTemplateDocument = gql`
    mutation updatePostAppointmentMessageTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updatePostAppointmentMessageTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdatePostAppointmentMessageTemplateMutationFn = Apollo.MutationFunction<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>;

/**
 * __useUpdatePostAppointmentMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePostAppointmentMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostAppointmentMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostAppointmentMessageTemplateMutation, { data, loading, error }] = useUpdatePostAppointmentMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdatePostAppointmentMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>(UpdatePostAppointmentMessageTemplateDocument, options);
      }
export type UpdatePostAppointmentMessageTemplateMutationHookResult = ReturnType<typeof useUpdatePostAppointmentMessageTemplateMutation>;
export type UpdatePostAppointmentMessageTemplateMutationResult = Apollo.MutationResult<UpdatePostAppointmentMessageTemplateMutation>;
export type UpdatePostAppointmentMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>;
export const UpdateReminderTemplateDocument = gql`
    mutation updateReminderTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updateReminderTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdateReminderTemplateMutationFn = Apollo.MutationFunction<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>;

/**
 * __useUpdateReminderTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateReminderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderTemplateMutation, { data, loading, error }] = useUpdateReminderTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdateReminderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>(UpdateReminderTemplateDocument, options);
      }
export type UpdateReminderTemplateMutationHookResult = ReturnType<typeof useUpdateReminderTemplateMutation>;
export type UpdateReminderTemplateMutationResult = Apollo.MutationResult<UpdateReminderTemplateMutation>;
export type UpdateReminderTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>;
export const OutgoingWebhooksDocument = gql`
    query outgoingWebhooks($businessId: ID!) {
  outgoingWebhooks(businessId: $businessId) {
    id
    endpoint
    description
    enabled
  }
}
    `;

/**
 * __useOutgoingWebhooksQuery__
 *
 * To run a query within a React component, call `useOutgoingWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingWebhooksQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useOutgoingWebhooksQuery(baseOptions: Apollo.QueryHookOptions<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables> & ({ variables: OutgoingWebhooksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>(OutgoingWebhooksDocument, options);
      }
export function useOutgoingWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>(OutgoingWebhooksDocument, options);
        }
export function useOutgoingWebhooksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>(OutgoingWebhooksDocument, options);
        }
export type OutgoingWebhooksQueryHookResult = ReturnType<typeof useOutgoingWebhooksQuery>;
export type OutgoingWebhooksLazyQueryHookResult = ReturnType<typeof useOutgoingWebhooksLazyQuery>;
export type OutgoingWebhooksSuspenseQueryHookResult = ReturnType<typeof useOutgoingWebhooksSuspenseQuery>;
export type OutgoingWebhooksQueryResult = Apollo.QueryResult<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>;
export const OutgoingWebhookDocument = gql`
    query outgoingWebhook($businessId: ID!, $outgoingWebhookId: ID!, $eventsLimit: Int) {
  outgoingWebhook(businessId: $businessId, outgoingWebhookId: $outgoingWebhookId) {
    id
    endpoint
    description
    enabled
    events(limit: $eventsLimit) {
      id
      name
      data
      createdAt
      outgoingWebhookDeliveries {
        id
        event {
          id
        }
        outgoingWebhook {
          id
        }
        duration
        requestHeaders
        requestPayload
        responseStatus
        responseHeaders
        responsePayload
      }
    }
  }
}
    `;

/**
 * __useOutgoingWebhookQuery__
 *
 * To run a query within a React component, call `useOutgoingWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingWebhookQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      outgoingWebhookId: // value for 'outgoingWebhookId'
 *      eventsLimit: // value for 'eventsLimit'
 *   },
 * });
 */
export function useOutgoingWebhookQuery(baseOptions: Apollo.QueryHookOptions<OutgoingWebhookQuery, OutgoingWebhookQueryVariables> & ({ variables: OutgoingWebhookQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>(OutgoingWebhookDocument, options);
      }
export function useOutgoingWebhookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>(OutgoingWebhookDocument, options);
        }
export function useOutgoingWebhookSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>(OutgoingWebhookDocument, options);
        }
export type OutgoingWebhookQueryHookResult = ReturnType<typeof useOutgoingWebhookQuery>;
export type OutgoingWebhookLazyQueryHookResult = ReturnType<typeof useOutgoingWebhookLazyQuery>;
export type OutgoingWebhookSuspenseQueryHookResult = ReturnType<typeof useOutgoingWebhookSuspenseQuery>;
export type OutgoingWebhookQueryResult = Apollo.QueryResult<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>;
export const CreateOutgoingWebhookDocument = gql`
    mutation createOutgoingWebhook($businessId: ID!, $endpoint: String!) {
  createOutgoingWebhook(businessId: $businessId, endpoint: $endpoint) {
    id
    endpoint
    description
    enabled
  }
}
    `;
export type CreateOutgoingWebhookMutationFn = Apollo.MutationFunction<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>;

/**
 * __useCreateOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useCreateOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutgoingWebhookMutation, { data, loading, error }] = useCreateOutgoingWebhookMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      endpoint: // value for 'endpoint'
 *   },
 * });
 */
export function useCreateOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>(CreateOutgoingWebhookDocument, options);
      }
export type CreateOutgoingWebhookMutationHookResult = ReturnType<typeof useCreateOutgoingWebhookMutation>;
export type CreateOutgoingWebhookMutationResult = Apollo.MutationResult<CreateOutgoingWebhookMutation>;
export type CreateOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>;
export const UpdateOutgoingWebhookDocument = gql`
    mutation updateOutgoingWebhook($id: ID!, $enabled: Boolean!) {
  updateOutgoingWebhook(id: $id, enabled: $enabled) {
    id
    endpoint
    description
    enabled
  }
}
    `;
export type UpdateOutgoingWebhookMutationFn = Apollo.MutationFunction<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>;

/**
 * __useUpdateOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutgoingWebhookMutation, { data, loading, error }] = useUpdateOutgoingWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>(UpdateOutgoingWebhookDocument, options);
      }
export type UpdateOutgoingWebhookMutationHookResult = ReturnType<typeof useUpdateOutgoingWebhookMutation>;
export type UpdateOutgoingWebhookMutationResult = Apollo.MutationResult<UpdateOutgoingWebhookMutation>;
export type UpdateOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>;
export const DeleteOutgoingWebhookDocument = gql`
    mutation deleteOutgoingWebhook($id: ID!) {
  deleteOutgoingWebhook(id: $id)
}
    `;
export type DeleteOutgoingWebhookMutationFn = Apollo.MutationFunction<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>;

/**
 * __useDeleteOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutgoingWebhookMutation, { data, loading, error }] = useDeleteOutgoingWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>(DeleteOutgoingWebhookDocument, options);
      }
export type DeleteOutgoingWebhookMutationHookResult = ReturnType<typeof useDeleteOutgoingWebhookMutation>;
export type DeleteOutgoingWebhookMutationResult = Apollo.MutationResult<DeleteOutgoingWebhookMutation>;
export type DeleteOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>;
export const AddInvoiceItemDocument = gql`
    mutation addInvoiceItem($invoiceId: ID!, $description: String!, $quantity: Int!, $amount: Float!) {
  addInvoiceItem(
    invoiceId: $invoiceId
    description: $description
    quantity: $quantity
    amount: $amount
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type AddInvoiceItemMutationFn = Apollo.MutationFunction<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;

/**
 * __useAddInvoiceItemMutation__
 *
 * To run a mutation, you first call `useAddInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceItemMutation, { data, loading, error }] = useAddInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAddInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>(AddInvoiceItemDocument, options);
      }
export type AddInvoiceItemMutationHookResult = ReturnType<typeof useAddInvoiceItemMutation>;
export type AddInvoiceItemMutationResult = Apollo.MutationResult<AddInvoiceItemMutation>;
export type AddInvoiceItemMutationOptions = Apollo.BaseMutationOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;
export const CreateDraftInvoiceDocument = gql`
    mutation createDraftInvoice($patientId: ID!, $currency: String) {
  createDraftInvoice(patientId: $patientId, currency: $currency) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type CreateDraftInvoiceMutationFn = Apollo.MutationFunction<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>;

/**
 * __useCreateDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftInvoiceMutation, { data, loading, error }] = useCreateDraftInvoiceMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>(CreateDraftInvoiceDocument, options);
      }
export type CreateDraftInvoiceMutationHookResult = ReturnType<typeof useCreateDraftInvoiceMutation>;
export type CreateDraftInvoiceMutationResult = Apollo.MutationResult<CreateDraftInvoiceMutation>;
export type CreateDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>;
export const DeleteDraftInvoiceDocument = gql`
    mutation deleteDraftInvoice($invoiceId: ID!) {
  deleted: deleteDraftInvoice(invoiceId: $invoiceId)
}
    `;
export type DeleteDraftInvoiceMutationFn = Apollo.MutationFunction<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;

/**
 * __useDeleteDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftInvoiceMutation, { data, loading, error }] = useDeleteDraftInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDeleteDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>(DeleteDraftInvoiceDocument, options);
      }
export type DeleteDraftInvoiceMutationHookResult = ReturnType<typeof useDeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationResult = Apollo.MutationResult<DeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;
export const DeleteInvoiceItemDocument = gql`
    mutation deleteInvoiceItem($invoiceId: ID!, $invoiceItemId: ID!) {
  deleteInvoiceItem(invoiceId: $invoiceId, invoiceItemId: $invoiceItemId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type DeleteInvoiceItemMutationFn = Apollo.MutationFunction<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;

/**
 * __useDeleteInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceItemMutation, { data, loading, error }] = useDeleteInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      invoiceItemId: // value for 'invoiceItemId'
 *   },
 * });
 */
export function useDeleteInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>(DeleteInvoiceItemDocument, options);
      }
export type DeleteInvoiceItemMutationHookResult = ReturnType<typeof useDeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationResult = Apollo.MutationResult<DeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;
export const FinalizeInvoiceDocument = gql`
    mutation finalizeInvoice($invoiceId: ID!) {
  finalizeInvoice(invoiceId: $invoiceId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type FinalizeInvoiceMutationFn = Apollo.MutationFunction<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;

/**
 * __useFinalizeInvoiceMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoiceMutation, { data, loading, error }] = useFinalizeInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useFinalizeInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>(FinalizeInvoiceDocument, options);
      }
export type FinalizeInvoiceMutationHookResult = ReturnType<typeof useFinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationResult = Apollo.MutationResult<FinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationOptions = Apollo.BaseMutationOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: ID!) {
  invoice: Invoice(id: $id) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables> & ({ variables: InvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export function useInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<typeof useInvoiceSuspenseQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const PatientInvoicesDocument = gql`
    query patientInvoices($patientId: ID!, $statuses: [InvoiceStatus!], $limit: Int, $skip: Int) {
  invoices: patientInvoices(
    patientId: $patientId
    statuses: $statuses
    limit: $limit
    skip: $skip
  ) {
    id
    number
    createdAt
    status
    currency
    dueDate
    amountTotal
    amountOutstanding
  }
  count: patientInvoicesCount(patientId: $patientId, statuses: $statuses)
}
    `;

/**
 * __usePatientInvoicesQuery__
 *
 * To run a query within a React component, call `usePatientInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInvoicesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePatientInvoicesQuery(baseOptions: Apollo.QueryHookOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables> & ({ variables: PatientInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, options);
      }
export function usePatientInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, options);
        }
export function usePatientInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, options);
        }
export type PatientInvoicesQueryHookResult = ReturnType<typeof usePatientInvoicesQuery>;
export type PatientInvoicesLazyQueryHookResult = ReturnType<typeof usePatientInvoicesLazyQuery>;
export type PatientInvoicesSuspenseQueryHookResult = ReturnType<typeof usePatientInvoicesSuspenseQuery>;
export type PatientInvoicesQueryResult = Apollo.QueryResult<PatientInvoicesQuery, PatientInvoicesQueryVariables>;
export const UpdateDraftInvoiceDocument = gql`
    mutation updateDraftInvoice($invoiceId: ID!, $dueDate: DateTime, $couponCode: String) {
  updateDraftInvoice(
    invoiceId: $invoiceId
    dueDate: $dueDate
    couponCode: $couponCode
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type UpdateDraftInvoiceMutationFn = Apollo.MutationFunction<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>;

/**
 * __useUpdateDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftInvoiceMutation, { data, loading, error }] = useUpdateDraftInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      dueDate: // value for 'dueDate'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useUpdateDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>(UpdateDraftInvoiceDocument, options);
      }
export type UpdateDraftInvoiceMutationHookResult = ReturnType<typeof useUpdateDraftInvoiceMutation>;
export type UpdateDraftInvoiceMutationResult = Apollo.MutationResult<UpdateDraftInvoiceMutation>;
export type UpdateDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>;
export const UpdateInvoiceItemDocument = gql`
    mutation updateInvoiceItem($invoiceId: ID!, $invoiceItemId: ID!, $description: String!, $quantity: Int!, $amount: Float!) {
  updateInvoiceItem(
    invoiceId: $invoiceId
    invoiceItemId: $invoiceItemId
    description: $description
    quantity: $quantity
    amount: $amount
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type UpdateInvoiceItemMutationFn = Apollo.MutationFunction<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>;

/**
 * __useUpdateInvoiceItemMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceItemMutation, { data, loading, error }] = useUpdateInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      invoiceItemId: // value for 'invoiceItemId'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>(UpdateInvoiceItemDocument, options);
      }
export type UpdateInvoiceItemMutationHookResult = ReturnType<typeof useUpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationResult = Apollo.MutationResult<UpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>;
export const VoidInvoiceDocument = gql`
    mutation voidInvoice($invoiceId: ID!) {
  voidInvoice(invoiceId: $invoiceId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<VoidInvoiceMutation, VoidInvoiceMutationVariables>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useVoidInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument, options);
      }
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>;
export const SendPatientIntakeInviteDocument = gql`
    mutation sendPatientIntakeInvite($patientId: ID!, $subject: String!, $body: String!, $formList: [String!]!) {
  success: sendPatientIntakeInvite(
    patientId: $patientId
    subject: $subject
    body: $body
    formList: $formList
  )
}
    `;
export type SendPatientIntakeInviteMutationFn = Apollo.MutationFunction<SendPatientIntakeInviteMutation, SendPatientIntakeInviteMutationVariables>;

/**
 * __useSendPatientIntakeInviteMutation__
 *
 * To run a mutation, you first call `useSendPatientIntakeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientIntakeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientIntakeInviteMutation, { data, loading, error }] = useSendPatientIntakeInviteMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *      formList: // value for 'formList'
 *   },
 * });
 */
export function useSendPatientIntakeInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendPatientIntakeInviteMutation, SendPatientIntakeInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPatientIntakeInviteMutation, SendPatientIntakeInviteMutationVariables>(SendPatientIntakeInviteDocument, options);
      }
export type SendPatientIntakeInviteMutationHookResult = ReturnType<typeof useSendPatientIntakeInviteMutation>;
export type SendPatientIntakeInviteMutationResult = Apollo.MutationResult<SendPatientIntakeInviteMutation>;
export type SendPatientIntakeInviteMutationOptions = Apollo.BaseMutationOptions<SendPatientIntakeInviteMutation, SendPatientIntakeInviteMutationVariables>;
export const SendPortalInviteDocument = gql`
    mutation sendPortalInvite($patientId: ID!, $subject: String!, $body: String!) {
  success: sendPatientInvite(
    patientId: $patientId
    subject: $subject
    body: $body
  )
}
    `;
export type SendPortalInviteMutationFn = Apollo.MutationFunction<SendPortalInviteMutation, SendPortalInviteMutationVariables>;

/**
 * __useSendPortalInviteMutation__
 *
 * To run a mutation, you first call `useSendPortalInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPortalInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPortalInviteMutation, { data, loading, error }] = useSendPortalInviteMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendPortalInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendPortalInviteMutation, SendPortalInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPortalInviteMutation, SendPortalInviteMutationVariables>(SendPortalInviteDocument, options);
      }
export type SendPortalInviteMutationHookResult = ReturnType<typeof useSendPortalInviteMutation>;
export type SendPortalInviteMutationResult = Apollo.MutationResult<SendPortalInviteMutation>;
export type SendPortalInviteMutationOptions = Apollo.BaseMutationOptions<SendPortalInviteMutation, SendPortalInviteMutationVariables>;
export const SelectPatientsListDocument = gql`
    query selectPatientsList($businessId: ID!, $limit: Int!, $search: String) {
  patients: businessPatients(
    businessId: $businessId
    limit: $limit
    search: $search
  ) {
    id
    name
    email
  }
}
    `;

/**
 * __useSelectPatientsListQuery__
 *
 * To run a query within a React component, call `useSelectPatientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectPatientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectPatientsListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSelectPatientsListQuery(baseOptions: Apollo.QueryHookOptions<SelectPatientsListQuery, SelectPatientsListQueryVariables> & ({ variables: SelectPatientsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectPatientsListQuery, SelectPatientsListQueryVariables>(SelectPatientsListDocument, options);
      }
export function useSelectPatientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectPatientsListQuery, SelectPatientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectPatientsListQuery, SelectPatientsListQueryVariables>(SelectPatientsListDocument, options);
        }
export function useSelectPatientsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectPatientsListQuery, SelectPatientsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectPatientsListQuery, SelectPatientsListQueryVariables>(SelectPatientsListDocument, options);
        }
export type SelectPatientsListQueryHookResult = ReturnType<typeof useSelectPatientsListQuery>;
export type SelectPatientsListLazyQueryHookResult = ReturnType<typeof useSelectPatientsListLazyQuery>;
export type SelectPatientsListSuspenseQueryHookResult = ReturnType<typeof useSelectPatientsListSuspenseQuery>;
export type SelectPatientsListQueryResult = Apollo.QueryResult<SelectPatientsListQuery, SelectPatientsListQueryVariables>;
export const SelectServicesListDocument = gql`
    query selectServicesList($businessId: ID!, $limit: Int!, $search: String, $excludeIds: [ID!]) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    search: $search
    excludeIds: $excludeIds
  ) {
    id
    name
    description
    durations
  }
}
    `;

/**
 * __useSelectServicesListQuery__
 *
 * To run a query within a React component, call `useSelectServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectServicesListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useSelectServicesListQuery(baseOptions: Apollo.QueryHookOptions<SelectServicesListQuery, SelectServicesListQueryVariables> & ({ variables: SelectServicesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectServicesListQuery, SelectServicesListQueryVariables>(SelectServicesListDocument, options);
      }
export function useSelectServicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectServicesListQuery, SelectServicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectServicesListQuery, SelectServicesListQueryVariables>(SelectServicesListDocument, options);
        }
export function useSelectServicesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectServicesListQuery, SelectServicesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectServicesListQuery, SelectServicesListQueryVariables>(SelectServicesListDocument, options);
        }
export type SelectServicesListQueryHookResult = ReturnType<typeof useSelectServicesListQuery>;
export type SelectServicesListLazyQueryHookResult = ReturnType<typeof useSelectServicesListLazyQuery>;
export type SelectServicesListSuspenseQueryHookResult = ReturnType<typeof useSelectServicesListSuspenseQuery>;
export type SelectServicesListQueryResult = Apollo.QueryResult<SelectServicesListQuery, SelectServicesListQueryVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: ID!) {
  deleteServiceCategory(id: $id) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const ServiceCategoriesDocument = gql`
    query serviceCategories($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    id
    name
    serviceCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoriesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useServiceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables> & ({ variables: ServiceCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(ServiceCategoriesDocument, options);
      }
export function useServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(ServiceCategoriesDocument, options);
        }
export function useServiceCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(ServiceCategoriesDocument, options);
        }
export type ServiceCategoriesQueryHookResult = ReturnType<typeof useServiceCategoriesQuery>;
export type ServiceCategoriesLazyQueryHookResult = ReturnType<typeof useServiceCategoriesLazyQuery>;
export type ServiceCategoriesSuspenseQueryHookResult = ReturnType<typeof useServiceCategoriesSuspenseQuery>;
export type ServiceCategoriesQueryResult = Apollo.QueryResult<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>;
export const AllBusinessServicesDocument = gql`
    query allBusinessServices($businessId: ID!, $limit: Int!, $skip: Int, $search: String, $excludeIds: [ID!]) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
    excludeIds: $excludeIds
  ) {
    id
    alias
    name
    description
    durations
    currency
    enabled
    locationType
    locationValue
    business {
      id
      alias
    }
    bookingPageUrl
    staffs {
      id
      name
      picture {
        id
        url
      }
    }
    categories {
      id
      name
    }
    picture {
      id
      url
    }
  }
  count: businessServicesCount(
    businessId: $businessId
    search: $search
    excludeIds: $excludeIds
  )
}
    `;

/**
 * __useAllBusinessServicesQuery__
 *
 * To run a query within a React component, call `useAllBusinessServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBusinessServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBusinessServicesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useAllBusinessServicesQuery(baseOptions: Apollo.QueryHookOptions<AllBusinessServicesQuery, AllBusinessServicesQueryVariables> & ({ variables: AllBusinessServicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>(AllBusinessServicesDocument, options);
      }
export function useAllBusinessServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>(AllBusinessServicesDocument, options);
        }
export function useAllBusinessServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>(AllBusinessServicesDocument, options);
        }
export type AllBusinessServicesQueryHookResult = ReturnType<typeof useAllBusinessServicesQuery>;
export type AllBusinessServicesLazyQueryHookResult = ReturnType<typeof useAllBusinessServicesLazyQuery>;
export type AllBusinessServicesSuspenseQueryHookResult = ReturnType<typeof useAllBusinessServicesSuspenseQuery>;
export type AllBusinessServicesQueryResult = Apollo.QueryResult<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>;
export const BusinessServiceDocument = gql`
    query businessService($serviceId: ID) {
  service: Service(id: $serviceId) {
    id
    alias
    name
    deleted
    description
    durations
    currency
    leadTime
    cancelLeadTime
    timeIncrement
    categories {
      id
      name
    }
    workingHours
    availabilityLimitDays
    staffs {
      id
      name
      user {
        id
      }
    }
    additionalStaff {
      id
    }
    locationType
    locationValue
    recurrence
    enabled
    requiresStripePayment
    bookingsPerTimeslot
    bookingsRequireApproval
    hideTimeslotsSeats
    bookingPageVisibility
    allowGuests
    locationOverridesStaffs
    bufferBeforeMinutes
    bufferAfterMinutes
    requiredAdditionalStaffCount
    picture {
      id
      url
    }
    location {
      id
      name
      address
    }
  }
}
    `;

/**
 * __useBusinessServiceQuery__
 *
 * To run a query within a React component, call `useBusinessServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessServiceQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useBusinessServiceQuery(baseOptions?: Apollo.QueryHookOptions<BusinessServiceQuery, BusinessServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessServiceQuery, BusinessServiceQueryVariables>(BusinessServiceDocument, options);
      }
export function useBusinessServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessServiceQuery, BusinessServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessServiceQuery, BusinessServiceQueryVariables>(BusinessServiceDocument, options);
        }
export function useBusinessServiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessServiceQuery, BusinessServiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessServiceQuery, BusinessServiceQueryVariables>(BusinessServiceDocument, options);
        }
export type BusinessServiceQueryHookResult = ReturnType<typeof useBusinessServiceQuery>;
export type BusinessServiceLazyQueryHookResult = ReturnType<typeof useBusinessServiceLazyQuery>;
export type BusinessServiceSuspenseQueryHookResult = ReturnType<typeof useBusinessServiceSuspenseQuery>;
export type BusinessServiceQueryResult = Apollo.QueryResult<BusinessServiceQuery, BusinessServiceQueryVariables>;
export const BusinessServicesNamesDocument = gql`
    query businessServicesNames($businessId: ID!, $limit: Int!, $skip: Int, $search: String) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
  ) {
    id
    name
  }
  count: businessServicesCount(businessId: $businessId)
}
    `;

/**
 * __useBusinessServicesNamesQuery__
 *
 * To run a query within a React component, call `useBusinessServicesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessServicesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessServicesNamesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessServicesNamesQuery(baseOptions: Apollo.QueryHookOptions<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables> & ({ variables: BusinessServicesNamesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>(BusinessServicesNamesDocument, options);
      }
export function useBusinessServicesNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>(BusinessServicesNamesDocument, options);
        }
export function useBusinessServicesNamesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>(BusinessServicesNamesDocument, options);
        }
export type BusinessServicesNamesQueryHookResult = ReturnType<typeof useBusinessServicesNamesQuery>;
export type BusinessServicesNamesLazyQueryHookResult = ReturnType<typeof useBusinessServicesNamesLazyQuery>;
export type BusinessServicesNamesSuspenseQueryHookResult = ReturnType<typeof useBusinessServicesNamesSuspenseQuery>;
export type BusinessServicesNamesQueryResult = Apollo.QueryResult<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>;
export const CreateServiceDocument = gql`
    mutation createService($name: String!, $description: String, $businessId: ID!, $durations: Json!, $currency: String!) {
  createService(
    name: $name
    description: $description
    businessId: $businessId
    durations: $durations
    currency: $currency
  ) {
    id
    name
    business {
      id
      servicesCount
    }
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      businessId: // value for 'businessId'
 *      durations: // value for 'durations'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($name: String!, $businessId: ID!) {
  createServiceCategory(name: $name, businessId: $businessId) {
    id
    name
    business {
      id
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const DeleteServiceDocument = gql`
    mutation deleteService($id: ID!) {
  updateService(id: $id, deleted: true) {
    id
    business {
      id
      servicesCount
    }
  }
}
    `;
export type DeleteServiceMutationFn = Apollo.MutationFunction<DeleteServiceMutation, DeleteServiceMutationVariables>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceMutation, DeleteServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument, options);
      }
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<DeleteServiceMutation, DeleteServiceMutationVariables>;
export const DuplicateServiceDocument = gql`
    mutation duplicateService($id: ID!) {
  service: duplicateService(id: $id) {
    id
  }
}
    `;
export type DuplicateServiceMutationFn = Apollo.MutationFunction<DuplicateServiceMutation, DuplicateServiceMutationVariables>;

/**
 * __useDuplicateServiceMutation__
 *
 * To run a mutation, you first call `useDuplicateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateServiceMutation, { data, loading, error }] = useDuplicateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateServiceMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateServiceMutation, DuplicateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateServiceMutation, DuplicateServiceMutationVariables>(DuplicateServiceDocument, options);
      }
export type DuplicateServiceMutationHookResult = ReturnType<typeof useDuplicateServiceMutation>;
export type DuplicateServiceMutationResult = Apollo.MutationResult<DuplicateServiceMutation>;
export type DuplicateServiceMutationOptions = Apollo.BaseMutationOptions<DuplicateServiceMutation, DuplicateServiceMutationVariables>;
export const ServiceDocument = gql`
    query service($id: ID!) {
  service: Service(id: $id) {
    id
    name
    durations
  }
}
    `;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables> & ({ variables: ServiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export function useServiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceSuspenseQueryHookResult = ReturnType<typeof useServiceSuspenseQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const ServiceWorkingHoursDocument = gql`
    query serviceWorkingHours($id: ID!) {
  service: Service(id: $id) {
    id
    name
    workingHours
    business {
      id
      timezone
      workingHours
    }
  }
}
    `;

/**
 * __useServiceWorkingHoursQuery__
 *
 * To run a query within a React component, call `useServiceWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceWorkingHoursQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables> & ({ variables: ServiceWorkingHoursQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>(ServiceWorkingHoursDocument, options);
      }
export function useServiceWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>(ServiceWorkingHoursDocument, options);
        }
export function useServiceWorkingHoursSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>(ServiceWorkingHoursDocument, options);
        }
export type ServiceWorkingHoursQueryHookResult = ReturnType<typeof useServiceWorkingHoursQuery>;
export type ServiceWorkingHoursLazyQueryHookResult = ReturnType<typeof useServiceWorkingHoursLazyQuery>;
export type ServiceWorkingHoursSuspenseQueryHookResult = ReturnType<typeof useServiceWorkingHoursSuspenseQuery>;
export type ServiceWorkingHoursQueryResult = Apollo.QueryResult<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>;
export const UpdateServiceDocument = gql`
    mutation updateService($id: ID!, $alias: String, $name: String, $description: String, $durations: Json, $currency: String, $categories: [ID!], $staffs: [ID!], $leadTime: Int, $timeIncrement: Int, $locationType: String, $locationValue: String, $recurrence: Json, $requiresStripePayment: Boolean, $workingHours: Json, $availabilityLimitDays: Int, $bookingsPerTimeslot: Int, $additionalStaff: [ID!], $bookingsRequireApproval: Boolean, $hideTimeslotsSeats: Boolean, $bookingPageVisibility: String, $allowGuests: Boolean, $locationOverridesStaffs: Boolean, $cancelLeadTime: Int, $bufferBeforeMinutes: Int, $bufferAfterMinutes: Int, $requiredAdditionalStaffCount: Int, $pictureId: ID, $locationId: ID) {
  updateService(
    id: $id
    alias: $alias
    name: $name
    description: $description
    currency: $currency
    durations: $durations
    categoriesIds: $categories
    staffsIds: $staffs
    leadTime: $leadTime
    timeIncrement: $timeIncrement
    locationType: $locationType
    locationValue: $locationValue
    recurrence: $recurrence
    requiresStripePayment: $requiresStripePayment
    workingHours: $workingHours
    availabilityLimitDays: $availabilityLimitDays
    bookingsPerTimeslot: $bookingsPerTimeslot
    additionalStaffIds: $additionalStaff
    bookingsRequireApproval: $bookingsRequireApproval
    hideTimeslotsSeats: $hideTimeslotsSeats
    bookingPageVisibility: $bookingPageVisibility
    allowGuests: $allowGuests
    locationOverridesStaffs: $locationOverridesStaffs
    cancelLeadTime: $cancelLeadTime
    bufferBeforeMinutes: $bufferBeforeMinutes
    bufferAfterMinutes: $bufferAfterMinutes
    requiredAdditionalStaffCount: $requiredAdditionalStaffCount
    pictureId: $pictureId
    locationId: $locationId
  ) {
    id
    alias
    name
    description
    currency
    durations
    leadTime
    timeIncrement
    categories {
      id
    }
    staffs {
      id
    }
    locationType
    locationValue
    recurrence
    requiresStripePayment
    workingHours
    availabilityLimitDays
    bookingsPerTimeslot
    additionalStaff {
      id
    }
    bookingsRequireApproval
    hideTimeslotsSeats
    bookingPageVisibility
    allowGuests
    locationOverridesStaffs
    cancelLeadTime
    bufferBeforeMinutes
    bufferAfterMinutes
    requiredAdditionalStaffCount
    picture {
      id
      url
    }
    business {
      id
      servicesCount
    }
    location {
      id
    }
  }
}
    `;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      durations: // value for 'durations'
 *      currency: // value for 'currency'
 *      categories: // value for 'categories'
 *      staffs: // value for 'staffs'
 *      leadTime: // value for 'leadTime'
 *      timeIncrement: // value for 'timeIncrement'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      recurrence: // value for 'recurrence'
 *      requiresStripePayment: // value for 'requiresStripePayment'
 *      workingHours: // value for 'workingHours'
 *      availabilityLimitDays: // value for 'availabilityLimitDays'
 *      bookingsPerTimeslot: // value for 'bookingsPerTimeslot'
 *      additionalStaff: // value for 'additionalStaff'
 *      bookingsRequireApproval: // value for 'bookingsRequireApproval'
 *      hideTimeslotsSeats: // value for 'hideTimeslotsSeats'
 *      bookingPageVisibility: // value for 'bookingPageVisibility'
 *      allowGuests: // value for 'allowGuests'
 *      locationOverridesStaffs: // value for 'locationOverridesStaffs'
 *      cancelLeadTime: // value for 'cancelLeadTime'
 *      bufferBeforeMinutes: // value for 'bufferBeforeMinutes'
 *      bufferAfterMinutes: // value for 'bufferAfterMinutes'
 *      requiredAdditionalStaffCount: // value for 'requiredAdditionalStaffCount'
 *      pictureId: // value for 'pictureId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const UpdateServiceStatusDocument = gql`
    mutation updateServiceStatus($id: ID!, $enabled: Boolean!) {
  updateService(id: $id, enabled: $enabled) {
    id
    enabled
  }
}
    `;
export type UpdateServiceStatusMutationFn = Apollo.MutationFunction<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>;

/**
 * __useUpdateServiceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateServiceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceStatusMutation, { data, loading, error }] = useUpdateServiceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateServiceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>(UpdateServiceStatusDocument, options);
      }
export type UpdateServiceStatusMutationHookResult = ReturnType<typeof useUpdateServiceStatusMutation>;
export type UpdateServiceStatusMutationResult = Apollo.MutationResult<UpdateServiceStatusMutation>;
export type UpdateServiceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>;
export const UpdateBusinessEmailSettingsDocument = gql`
    mutation updateBusinessEmailSettings($businessId: ID!, $ownerNotificationsRecipient: OwnerNotificationsRecipient, $bookingNotificationsReplyTo: NotificationsReplyToType, $ownerNotificationsCC: String, $attachICSFiles: Boolean) {
  updateBusiness(
    id: $businessId
    ownerNotificationsRecipient: $ownerNotificationsRecipient
    bookingNotificationsReplyTo: $bookingNotificationsReplyTo
    ownerNotificationsCC: $ownerNotificationsCC
    attachICSFiles: $attachICSFiles
  ) {
    id
    ownerNotificationsRecipient
    bookingNotificationsReplyTo
    ownerNotificationsCC
    attachICSFiles
  }
}
    `;
export type UpdateBusinessEmailSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>;

/**
 * __useUpdateBusinessEmailSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessEmailSettingsMutation, { data, loading, error }] = useUpdateBusinessEmailSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      ownerNotificationsRecipient: // value for 'ownerNotificationsRecipient'
 *      bookingNotificationsReplyTo: // value for 'bookingNotificationsReplyTo'
 *      ownerNotificationsCC: // value for 'ownerNotificationsCC'
 *      attachICSFiles: // value for 'attachICSFiles'
 *   },
 * });
 */
export function useUpdateBusinessEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>(UpdateBusinessEmailSettingsDocument, options);
      }
export type UpdateBusinessEmailSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessEmailSettingsMutation>;
export type UpdateBusinessEmailSettingsMutationResult = Apollo.MutationResult<UpdateBusinessEmailSettingsMutation>;
export type UpdateBusinessEmailSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>;
export const UpdateSmsSettingsDocument = gql`
    mutation updateSMSSettings($businessId: ID!, $sendSMSFromStaffNumber: Boolean) {
  updateBusiness(id: $businessId, sendSMSFromStaffNumber: $sendSMSFromStaffNumber) {
    id
    sendSMSFromStaffNumber
  }
}
    `;
export type UpdateSmsSettingsMutationFn = Apollo.MutationFunction<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>;

/**
 * __useUpdateSmsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSmsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsSettingsMutation, { data, loading, error }] = useUpdateSmsSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      sendSMSFromStaffNumber: // value for 'sendSMSFromStaffNumber'
 *   },
 * });
 */
export function useUpdateSmsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>(UpdateSmsSettingsDocument, options);
      }
export type UpdateSmsSettingsMutationHookResult = ReturnType<typeof useUpdateSmsSettingsMutation>;
export type UpdateSmsSettingsMutationResult = Apollo.MutationResult<UpdateSmsSettingsMutation>;
export type UpdateSmsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>;
export const AllManagedStaffDocument = gql`
    query allManagedStaff($limit: Int, $skip: Int, $search: String, $roles: [StaffRole!], $status: [StaffStatus!]) {
  staff: managedStaffs(
    limit: $limit
    skip: $skip
    search: $search
    roles: $roles
    status: $status
  ) {
    id
    alias
    name
    enabled
    description
    email
    lastInvitedAt
    roles
    status
    createdAt
    business {
      id
      alias
    }
    bookingPageUrl
    stripeProductIds
    user {
      id
    }
    picture {
      id
      url
    }
    managedStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
  count: managedStaffsCount(search: $search, roles: $roles, status: $status)
}
    `;

/**
 * __useAllManagedStaffQuery__
 *
 * To run a query within a React component, call `useAllManagedStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllManagedStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllManagedStaffQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      roles: // value for 'roles'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAllManagedStaffQuery(baseOptions?: Apollo.QueryHookOptions<AllManagedStaffQuery, AllManagedStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllManagedStaffQuery, AllManagedStaffQueryVariables>(AllManagedStaffDocument, options);
      }
export function useAllManagedStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllManagedStaffQuery, AllManagedStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllManagedStaffQuery, AllManagedStaffQueryVariables>(AllManagedStaffDocument, options);
        }
export function useAllManagedStaffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllManagedStaffQuery, AllManagedStaffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllManagedStaffQuery, AllManagedStaffQueryVariables>(AllManagedStaffDocument, options);
        }
export type AllManagedStaffQueryHookResult = ReturnType<typeof useAllManagedStaffQuery>;
export type AllManagedStaffLazyQueryHookResult = ReturnType<typeof useAllManagedStaffLazyQuery>;
export type AllManagedStaffSuspenseQueryHookResult = ReturnType<typeof useAllManagedStaffSuspenseQuery>;
export type AllManagedStaffQueryResult = Apollo.QueryResult<AllManagedStaffQuery, AllManagedStaffQueryVariables>;
export const AllManagedStaffCompactDocument = gql`
    query allManagedStaffCompact {
  staff: managedStaffs(limit: 0) {
    id
    name
  }
}
    `;

/**
 * __useAllManagedStaffCompactQuery__
 *
 * To run a query within a React component, call `useAllManagedStaffCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllManagedStaffCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllManagedStaffCompactQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllManagedStaffCompactQuery(baseOptions?: Apollo.QueryHookOptions<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>(AllManagedStaffCompactDocument, options);
      }
export function useAllManagedStaffCompactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>(AllManagedStaffCompactDocument, options);
        }
export function useAllManagedStaffCompactSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>(AllManagedStaffCompactDocument, options);
        }
export type AllManagedStaffCompactQueryHookResult = ReturnType<typeof useAllManagedStaffCompactQuery>;
export type AllManagedStaffCompactLazyQueryHookResult = ReturnType<typeof useAllManagedStaffCompactLazyQuery>;
export type AllManagedStaffCompactSuspenseQueryHookResult = ReturnType<typeof useAllManagedStaffCompactSuspenseQuery>;
export type AllManagedStaffCompactQueryResult = Apollo.QueryResult<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>;
export const StaffFiltersDocument = gql`
    query staffFilters($filterIds: [ID!]) {
  staffs: managedStaffs(limit: 0, filterIds: $filterIds) {
    id
    name
  }
}
    `;

/**
 * __useStaffFiltersQuery__
 *
 * To run a query within a React component, call `useStaffFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffFiltersQuery({
 *   variables: {
 *      filterIds: // value for 'filterIds'
 *   },
 * });
 */
export function useStaffFiltersQuery(baseOptions?: Apollo.QueryHookOptions<StaffFiltersQuery, StaffFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffFiltersQuery, StaffFiltersQueryVariables>(StaffFiltersDocument, options);
      }
export function useStaffFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffFiltersQuery, StaffFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffFiltersQuery, StaffFiltersQueryVariables>(StaffFiltersDocument, options);
        }
export function useStaffFiltersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StaffFiltersQuery, StaffFiltersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffFiltersQuery, StaffFiltersQueryVariables>(StaffFiltersDocument, options);
        }
export type StaffFiltersQueryHookResult = ReturnType<typeof useStaffFiltersQuery>;
export type StaffFiltersLazyQueryHookResult = ReturnType<typeof useStaffFiltersLazyQuery>;
export type StaffFiltersSuspenseQueryHookResult = ReturnType<typeof useStaffFiltersSuspenseQuery>;
export type StaffFiltersQueryResult = Apollo.QueryResult<StaffFiltersQuery, StaffFiltersQueryVariables>;
export const SelectStaffListDocument = gql`
    query selectStaffList($limit: Int, $skip: Int, $search: String, $excludeIds: [ID!], $serviceId: ID, $status: [StaffStatus!]) {
  staff: managedStaffs(
    limit: $limit
    skip: $skip
    search: $search
    excludeIds: $excludeIds
    serviceId: $serviceId
    status: $status
  ) {
    id
    name
    email
  }
}
    `;

/**
 * __useSelectStaffListQuery__
 *
 * To run a query within a React component, call `useSelectStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectStaffListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *      serviceId: // value for 'serviceId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSelectStaffListQuery(baseOptions?: Apollo.QueryHookOptions<SelectStaffListQuery, SelectStaffListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectStaffListQuery, SelectStaffListQueryVariables>(SelectStaffListDocument, options);
      }
export function useSelectStaffListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectStaffListQuery, SelectStaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectStaffListQuery, SelectStaffListQueryVariables>(SelectStaffListDocument, options);
        }
export function useSelectStaffListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectStaffListQuery, SelectStaffListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectStaffListQuery, SelectStaffListQueryVariables>(SelectStaffListDocument, options);
        }
export type SelectStaffListQueryHookResult = ReturnType<typeof useSelectStaffListQuery>;
export type SelectStaffListLazyQueryHookResult = ReturnType<typeof useSelectStaffListLazyQuery>;
export type SelectStaffListSuspenseQueryHookResult = ReturnType<typeof useSelectStaffListSuspenseQuery>;
export type SelectStaffListQueryResult = Apollo.QueryResult<SelectStaffListQuery, SelectStaffListQueryVariables>;
export const CreateStaffDocument = gql`
    mutation createStaff($email: String!, $name: String!, $description: String, $businessId: ID!, $phoneMobile: String, $roles: [StaffRole!], $stripeProductIds: [String!]) {
  staff: createStaff(
    email: $email
    name: $name
    description: $description
    businessId: $businessId
    phoneMobile: $phoneMobile
    roles: $roles
    stripeProductIds: $stripeProductIds
  ) {
    id
    name
    description
    email
    phoneMobile
    roles
    stripeProductIds
  }
}
    `;
export type CreateStaffMutationFn = Apollo.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      businessId: // value for 'businessId'
 *      phoneMobile: // value for 'phoneMobile'
 *      roles: // value for 'roles'
 *      stripeProductIds: // value for 'stripeProductIds'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, options);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const CreateWorkingHoursScheduleDocument = gql`
    mutation createWorkingHoursSchedule($staffId: ID!, $from: DateTime!, $to: DateTime, $workingHours: Json!) {
  createWorkingHoursSchedule(
    staffId: $staffId
    from: $from
    to: $to
    workingHours: $workingHours
  ) {
    id
    staff {
      id
      workingHours(time: $from)
      business {
        id
        workingHours
        timezone
      }
    }
  }
}
    `;
export type CreateWorkingHoursScheduleMutationFn = Apollo.MutationFunction<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>;

/**
 * __useCreateWorkingHoursScheduleMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHoursScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHoursScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHoursScheduleMutation, { data, loading, error }] = useCreateWorkingHoursScheduleMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      workingHours: // value for 'workingHours'
 *   },
 * });
 */
export function useCreateWorkingHoursScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>(CreateWorkingHoursScheduleDocument, options);
      }
export type CreateWorkingHoursScheduleMutationHookResult = ReturnType<typeof useCreateWorkingHoursScheduleMutation>;
export type CreateWorkingHoursScheduleMutationResult = Apollo.MutationResult<CreateWorkingHoursScheduleMutation>;
export type CreateWorkingHoursScheduleMutationOptions = Apollo.BaseMutationOptions<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation deleteStaff($id: ID!) {
  deleteStaff(id: $id) {
    id
  }
}
    `;
export type DeleteStaffMutationFn = Apollo.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const DeleteWorkingHoursSchedulesDocument = gql`
    mutation deleteWorkingHoursSchedules($staffId: ID!) {
  defaultToBusinessWorkingHours(staffId: $staffId)
}
    `;
export type DeleteWorkingHoursSchedulesMutationFn = Apollo.MutationFunction<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>;

/**
 * __useDeleteWorkingHoursSchedulesMutation__
 *
 * To run a mutation, you first call `useDeleteWorkingHoursSchedulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkingHoursSchedulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkingHoursSchedulesMutation, { data, loading, error }] = useDeleteWorkingHoursSchedulesMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useDeleteWorkingHoursSchedulesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>(DeleteWorkingHoursSchedulesDocument, options);
      }
export type DeleteWorkingHoursSchedulesMutationHookResult = ReturnType<typeof useDeleteWorkingHoursSchedulesMutation>;
export type DeleteWorkingHoursSchedulesMutationResult = Apollo.MutationResult<DeleteWorkingHoursSchedulesMutation>;
export type DeleteWorkingHoursSchedulesMutationOptions = Apollo.BaseMutationOptions<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>;
export const ResendStaffInviteDocument = gql`
    mutation resendStaffInvite($staffId: ID!) {
  updateStaff(id: $staffId, resendInvite: true) {
    id
    enabled
    status
    lastInvitedAt
    name
    email
  }
}
    `;
export type ResendStaffInviteMutationFn = Apollo.MutationFunction<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>;

/**
 * __useResendStaffInviteMutation__
 *
 * To run a mutation, you first call `useResendStaffInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendStaffInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendStaffInviteMutation, { data, loading, error }] = useResendStaffInviteMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useResendStaffInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>(ResendStaffInviteDocument, options);
      }
export type ResendStaffInviteMutationHookResult = ReturnType<typeof useResendStaffInviteMutation>;
export type ResendStaffInviteMutationResult = Apollo.MutationResult<ResendStaffInviteMutation>;
export type ResendStaffInviteMutationOptions = Apollo.BaseMutationOptions<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>;
export const StaffDocument = gql`
    query staff($staffId: ID!) {
  staff: Staff(id: $staffId) {
    id
    alias
    name
    roles
    enabled
    status
    description
    email
    occupation
    phoneMobile
    timezone
    alias
    bookingPageUrl
    locationType
    locationValue
    business {
      id
      alias
    }
    managedStaff {
      id
      name
    }
    picture {
      id
      url
    }
  }
}
    `;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useStaffQuery(baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables> & ({ variables: StaffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export function useStaffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffSuspenseQueryHookResult = ReturnType<typeof useStaffSuspenseQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export const StaffIsAvailableDocument = gql`
    query staffIsAvailable($staffId: ID!, $serviceId: ID!, $date: DateTime!, $duration: Int!, $ignoreAppointmentId: ID) {
  isAvailable: staffIsAvailable(
    staffId: $staffId
    serviceId: $serviceId
    date: $date
    duration: $duration
    ignoreAppointmentId: $ignoreAppointmentId
  )
}
    `;

/**
 * __useStaffIsAvailableQuery__
 *
 * To run a query within a React component, call `useStaffIsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffIsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffIsAvailableQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      serviceId: // value for 'serviceId'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      ignoreAppointmentId: // value for 'ignoreAppointmentId'
 *   },
 * });
 */
export function useStaffIsAvailableQuery(baseOptions: Apollo.QueryHookOptions<StaffIsAvailableQuery, StaffIsAvailableQueryVariables> & ({ variables: StaffIsAvailableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>(StaffIsAvailableDocument, options);
      }
export function useStaffIsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>(StaffIsAvailableDocument, options);
        }
export function useStaffIsAvailableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>(StaffIsAvailableDocument, options);
        }
export type StaffIsAvailableQueryHookResult = ReturnType<typeof useStaffIsAvailableQuery>;
export type StaffIsAvailableLazyQueryHookResult = ReturnType<typeof useStaffIsAvailableLazyQuery>;
export type StaffIsAvailableSuspenseQueryHookResult = ReturnType<typeof useStaffIsAvailableSuspenseQuery>;
export type StaffIsAvailableQueryResult = Apollo.QueryResult<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>;
export const UpdateStaffDocument = gql`
    mutation updateStaff($id: ID!, $alias: String, $enabled: Boolean, $name: String, $description: String, $email: String, $phoneMobile: String, $pictureId: ID, $locationType: String, $locationValue: String, $timezone: String, $roles: [StaffRole!], $managedStaff: [ID!], $occupation: String, $marketingMeta: Json, $onboardingCompleted: Boolean) {
  updateStaff(
    id: $id
    alias: $alias
    enabled: $enabled
    name: $name
    description: $description
    email: $email
    phoneMobile: $phoneMobile
    pictureId: $pictureId
    locationType: $locationType
    locationValue: $locationValue
    timezone: $timezone
    roles: $roles
    managedStaff: $managedStaff
    occupation: $occupation
    marketingMeta: $marketingMeta
    onboardingCompleted: $onboardingCompleted
  ) {
    id
    alias
    enabled
    status
    name
    description
    email
    phoneMobile
    locationType
    locationValue
    timezone
    roles
    onboardingCompletedAt
    picture {
      id
      url
    }
    managedStaff {
      id
      name
    }
    occupation
    marketingMeta
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *      enabled: // value for 'enabled'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      email: // value for 'email'
 *      phoneMobile: // value for 'phoneMobile'
 *      pictureId: // value for 'pictureId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      timezone: // value for 'timezone'
 *      roles: // value for 'roles'
 *      managedStaff: // value for 'managedStaff'
 *      occupation: // value for 'occupation'
 *      marketingMeta: // value for 'marketingMeta'
 *      onboardingCompleted: // value for 'onboardingCompleted'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const WorkingHoursSchedulesDocument = gql`
    query workingHoursSchedules($staffId: ID!, $time: DateTime!) {
  staff: Staff(id: $staffId) {
    id
    name
    workingHours(time: $time)
    business {
      id
      workingHours
      timezone
    }
    timezone
  }
}
    `;

/**
 * __useWorkingHoursSchedulesQuery__
 *
 * To run a query within a React component, call `useWorkingHoursSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursSchedulesQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useWorkingHoursSchedulesQuery(baseOptions: Apollo.QueryHookOptions<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables> & ({ variables: WorkingHoursSchedulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>(WorkingHoursSchedulesDocument, options);
      }
export function useWorkingHoursSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>(WorkingHoursSchedulesDocument, options);
        }
export function useWorkingHoursSchedulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>(WorkingHoursSchedulesDocument, options);
        }
export type WorkingHoursSchedulesQueryHookResult = ReturnType<typeof useWorkingHoursSchedulesQuery>;
export type WorkingHoursSchedulesLazyQueryHookResult = ReturnType<typeof useWorkingHoursSchedulesLazyQuery>;
export type WorkingHoursSchedulesSuspenseQueryHookResult = ReturnType<typeof useWorkingHoursSchedulesSuspenseQuery>;
export type WorkingHoursSchedulesQueryResult = Apollo.QueryResult<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>;
export const BusinessStripeAccountDocument = gql`
    query businessStripeAccount($id: ID!) {
  business: Business(id: $id) {
    id
    stripeConnectAccount {
      id
      onboardingCompletedAt
    }
  }
}
    `;

/**
 * __useBusinessStripeAccountQuery__
 *
 * To run a query within a React component, call `useBusinessStripeAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessStripeAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessStripeAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessStripeAccountQuery(baseOptions: Apollo.QueryHookOptions<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables> & ({ variables: BusinessStripeAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>(BusinessStripeAccountDocument, options);
      }
export function useBusinessStripeAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>(BusinessStripeAccountDocument, options);
        }
export function useBusinessStripeAccountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>(BusinessStripeAccountDocument, options);
        }
export type BusinessStripeAccountQueryHookResult = ReturnType<typeof useBusinessStripeAccountQuery>;
export type BusinessStripeAccountLazyQueryHookResult = ReturnType<typeof useBusinessStripeAccountLazyQuery>;
export type BusinessStripeAccountSuspenseQueryHookResult = ReturnType<typeof useBusinessStripeAccountSuspenseQuery>;
export type BusinessStripeAccountQueryResult = Apollo.QueryResult<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>;
export const CompleteStripeConnectAccountOnboardingDocument = gql`
    mutation completeStripeConnectAccountOnboarding($id: ID!) {
  completeStripeConnectAccountOnboarding(id: $id) {
    id
    onboardingCompletedAt
  }
}
    `;
export type CompleteStripeConnectAccountOnboardingMutationFn = Apollo.MutationFunction<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>;

/**
 * __useCompleteStripeConnectAccountOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteStripeConnectAccountOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStripeConnectAccountOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStripeConnectAccountOnboardingMutation, { data, loading, error }] = useCompleteStripeConnectAccountOnboardingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteStripeConnectAccountOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>(CompleteStripeConnectAccountOnboardingDocument, options);
      }
export type CompleteStripeConnectAccountOnboardingMutationHookResult = ReturnType<typeof useCompleteStripeConnectAccountOnboardingMutation>;
export type CompleteStripeConnectAccountOnboardingMutationResult = Apollo.MutationResult<CompleteStripeConnectAccountOnboardingMutation>;
export type CompleteStripeConnectAccountOnboardingMutationOptions = Apollo.BaseMutationOptions<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>;
export const CreateStripeConnectAccountDocument = gql`
    mutation createStripeConnectAccount($country: String!) {
  createStripeConnectAccount(country: $country) {
    success
    stripeConnectAccountId
    session {
      clientSecret
      expiresAt
    }
  }
}
    `;
export type CreateStripeConnectAccountMutationFn = Apollo.MutationFunction<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>;

/**
 * __useCreateStripeConnectAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeConnectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeConnectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeConnectAccountMutation, { data, loading, error }] = useCreateStripeConnectAccountMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateStripeConnectAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>(CreateStripeConnectAccountDocument, options);
      }
export type CreateStripeConnectAccountMutationHookResult = ReturnType<typeof useCreateStripeConnectAccountMutation>;
export type CreateStripeConnectAccountMutationResult = Apollo.MutationResult<CreateStripeConnectAccountMutation>;
export type CreateStripeConnectAccountMutationOptions = Apollo.BaseMutationOptions<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>;
export const CreateStripeConnectAccountSessionDocument = gql`
    mutation createStripeConnectAccountSession {
  session: createStripeConnectAccountSession {
    clientSecret
    expiresAt
  }
}
    `;
export type CreateStripeConnectAccountSessionMutationFn = Apollo.MutationFunction<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>;

/**
 * __useCreateStripeConnectAccountSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeConnectAccountSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeConnectAccountSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeConnectAccountSessionMutation, { data, loading, error }] = useCreateStripeConnectAccountSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeConnectAccountSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>(CreateStripeConnectAccountSessionDocument, options);
      }
export type CreateStripeConnectAccountSessionMutationHookResult = ReturnType<typeof useCreateStripeConnectAccountSessionMutation>;
export type CreateStripeConnectAccountSessionMutationResult = Apollo.MutationResult<CreateStripeConnectAccountSessionMutation>;
export type CreateStripeConnectAccountSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>;
export const StripeProductsDocument = gql`
    query stripeProducts($businessId: ID!) {
  business: Business(id: $businessId) {
    stripeData
    stripeProducts {
      id
      name
      description
      featureList
      prices {
        id
        currency
        unitAmount
        interval
      }
    }
  }
}
    `;

/**
 * __useStripeProductsQuery__
 *
 * To run a query within a React component, call `useStripeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeProductsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useStripeProductsQuery(baseOptions: Apollo.QueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables> & ({ variables: StripeProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
      }
export function useStripeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
        }
export function useStripeProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
        }
export type StripeProductsQueryHookResult = ReturnType<typeof useStripeProductsQuery>;
export type StripeProductsLazyQueryHookResult = ReturnType<typeof useStripeProductsLazyQuery>;
export type StripeProductsSuspenseQueryHookResult = ReturnType<typeof useStripeProductsSuspenseQuery>;
export type StripeProductsQueryResult = Apollo.QueryResult<StripeProductsQuery, StripeProductsQueryVariables>;
export const SyncStripeCustomerDocument = gql`
    mutation syncStripeCustomer {
  syncStripeCustomer {
    business {
      id
      stripeCustomerId
      stripeSubscriptionId
      stripeData
    }
  }
}
    `;
export type SyncStripeCustomerMutationFn = Apollo.MutationFunction<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>;

/**
 * __useSyncStripeCustomerMutation__
 *
 * To run a mutation, you first call `useSyncStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncStripeCustomerMutation, { data, loading, error }] = useSyncStripeCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncStripeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>(SyncStripeCustomerDocument, options);
      }
export type SyncStripeCustomerMutationHookResult = ReturnType<typeof useSyncStripeCustomerMutation>;
export type SyncStripeCustomerMutationResult = Apollo.MutationResult<SyncStripeCustomerMutation>;
export type SyncStripeCustomerMutationOptions = Apollo.BaseMutationOptions<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>;
export const AllTagsDocument = gql`
    query allTags {
  allTags {
    id
    alias
    name
    description
    color
  }
}
    `;

/**
 * __useAllTagsQuery__
 *
 * To run a query within a React component, call `useAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<AllTagsQuery, AllTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTagsQuery, AllTagsQueryVariables>(AllTagsDocument, options);
      }
export function useAllTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTagsQuery, AllTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTagsQuery, AllTagsQueryVariables>(AllTagsDocument, options);
        }
export function useAllTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllTagsQuery, AllTagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllTagsQuery, AllTagsQueryVariables>(AllTagsDocument, options);
        }
export type AllTagsQueryHookResult = ReturnType<typeof useAllTagsQuery>;
export type AllTagsLazyQueryHookResult = ReturnType<typeof useAllTagsLazyQuery>;
export type AllTagsSuspenseQueryHookResult = ReturnType<typeof useAllTagsSuspenseQuery>;
export type AllTagsQueryResult = Apollo.QueryResult<AllTagsQuery, AllTagsQueryVariables>;
export const TagDocument = gql`
    query tag($id: ID!) {
  tag: Tag(id: $id) {
    id
    alias
    name
    description
    color
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables> & ({ variables: TagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export function useTagSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagSuspenseQueryHookResult = ReturnType<typeof useTagSuspenseQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($alias: String!, $name: String!, $color: String!, $description: String!) {
  createTag(alias: $alias, name: $name, color: $color, description: $description) {
    id
    alias
    name
    color
    description
    createdAt
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($id: ID!, $name: String!, $color: String!, $description: String!) {
  updateTag(id: $id, name: $name, color: $color, description: $description) {
    id
    alias
    name
    color
    description
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdatePatientTagsDocument = gql`
    mutation updatePatientTags($patientId: ID!, $tagIds: [String!]!) {
  setPatientTags(patientId: $patientId, tagIds: $tagIds) {
    id
    tagIds
  }
}
    `;
export type UpdatePatientTagsMutationFn = Apollo.MutationFunction<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>;

/**
 * __useUpdatePatientTagsMutation__
 *
 * To run a mutation, you first call `useUpdatePatientTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientTagsMutation, { data, loading, error }] = useUpdatePatientTagsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useUpdatePatientTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>(UpdatePatientTagsDocument, options);
      }
export type UpdatePatientTagsMutationHookResult = ReturnType<typeof useUpdatePatientTagsMutation>;
export type UpdatePatientTagsMutationResult = Apollo.MutationResult<UpdatePatientTagsMutation>;
export type UpdatePatientTagsMutationOptions = Apollo.BaseMutationOptions<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>;
export const SetAppointmentTagsDocument = gql`
    mutation setAppointmentTags($appointmentId: ID!, $tagIds: [String!]!) {
  setAppointmentTags(appointmentId: $appointmentId, tagIds: $tagIds) {
    id
    tagIds
  }
}
    `;
export type SetAppointmentTagsMutationFn = Apollo.MutationFunction<SetAppointmentTagsMutation, SetAppointmentTagsMutationVariables>;

/**
 * __useSetAppointmentTagsMutation__
 *
 * To run a mutation, you first call `useSetAppointmentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAppointmentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAppointmentTagsMutation, { data, loading, error }] = useSetAppointmentTagsMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useSetAppointmentTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetAppointmentTagsMutation, SetAppointmentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAppointmentTagsMutation, SetAppointmentTagsMutationVariables>(SetAppointmentTagsDocument, options);
      }
export type SetAppointmentTagsMutationHookResult = ReturnType<typeof useSetAppointmentTagsMutation>;
export type SetAppointmentTagsMutationResult = Apollo.MutationResult<SetAppointmentTagsMutation>;
export type SetAppointmentTagsMutationOptions = Apollo.BaseMutationOptions<SetAppointmentTagsMutation, SetAppointmentTagsMutationVariables>;
export const ThirdPartyConnectionsDocument = gql`
    query thirdPartyConnections($businessId: ID!, $staffId: ID!) {
  businessZoomConnections: thirdPartyConnections(
    businessId: $businessId
    staffId: null
    provider: ZOOM
  ) {
    id
    provider
    name
    email
  }
  staffZoomConnections: thirdPartyConnections(staffId: $staffId, provider: ZOOM) {
    id
    provider
    name
    email
  }
}
    `;

/**
 * __useThirdPartyConnectionsQuery__
 *
 * To run a query within a React component, call `useThirdPartyConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyConnectionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useThirdPartyConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables> & ({ variables: ThirdPartyConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>(ThirdPartyConnectionsDocument, options);
      }
export function useThirdPartyConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>(ThirdPartyConnectionsDocument, options);
        }
export function useThirdPartyConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>(ThirdPartyConnectionsDocument, options);
        }
export type ThirdPartyConnectionsQueryHookResult = ReturnType<typeof useThirdPartyConnectionsQuery>;
export type ThirdPartyConnectionsLazyQueryHookResult = ReturnType<typeof useThirdPartyConnectionsLazyQuery>;
export type ThirdPartyConnectionsSuspenseQueryHookResult = ReturnType<typeof useThirdPartyConnectionsSuspenseQuery>;
export type ThirdPartyConnectionsQueryResult = Apollo.QueryResult<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>;
export const CreateThirdPartyConnectionOAuthSessionDocument = gql`
    mutation createThirdPartyConnectionOAuthSession($provider: ThirdPartyConnectionProvider!, $resourceType: ThirdPartyConnectionResourceType!, $redirectUrl: String!) {
  createThirdPartyConnectionOAuthSession(
    provider: $provider
    resourceType: $resourceType
    redirectUrl: $redirectUrl
  ) {
    redirectUrl
  }
}
    `;
export type CreateThirdPartyConnectionOAuthSessionMutationFn = Apollo.MutationFunction<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>;

/**
 * __useCreateThirdPartyConnectionOAuthSessionMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyConnectionOAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyConnectionOAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyConnectionOAuthSessionMutation, { data, loading, error }] = useCreateThirdPartyConnectionOAuthSessionMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      resourceType: // value for 'resourceType'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateThirdPartyConnectionOAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>(CreateThirdPartyConnectionOAuthSessionDocument, options);
      }
export type CreateThirdPartyConnectionOAuthSessionMutationHookResult = ReturnType<typeof useCreateThirdPartyConnectionOAuthSessionMutation>;
export type CreateThirdPartyConnectionOAuthSessionMutationResult = Apollo.MutationResult<CreateThirdPartyConnectionOAuthSessionMutation>;
export type CreateThirdPartyConnectionOAuthSessionMutationOptions = Apollo.BaseMutationOptions<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>;
export const UpdateThirdPartyConnectionDocument = gql`
    mutation updateThirdPartyConnection($id: ID!, $enabled: Boolean!) {
  updateThirdPartyConnection(id: $id, enabled: $enabled) {
    id
    provider
    name
    email
    staff {
      id
      zoomConnection {
        id
      }
    }
    business {
      id
      zoomConnection {
        id
      }
    }
  }
}
    `;
export type UpdateThirdPartyConnectionMutationFn = Apollo.MutationFunction<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>;

/**
 * __useUpdateThirdPartyConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateThirdPartyConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThirdPartyConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThirdPartyConnectionMutation, { data, loading, error }] = useUpdateThirdPartyConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateThirdPartyConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>(UpdateThirdPartyConnectionDocument, options);
      }
export type UpdateThirdPartyConnectionMutationHookResult = ReturnType<typeof useUpdateThirdPartyConnectionMutation>;
export type UpdateThirdPartyConnectionMutationResult = Apollo.MutationResult<UpdateThirdPartyConnectionMutation>;
export type UpdateThirdPartyConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>;
export const DeleteThirdPartyConnectionDocument = gql`
    mutation deleteThirdPartyConnection($id: ID!) {
  deleteThirdPartyConnection(id: $id)
}
    `;
export type DeleteThirdPartyConnectionMutationFn = Apollo.MutationFunction<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>;

/**
 * __useDeleteThirdPartyConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteThirdPartyConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThirdPartyConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThirdPartyConnectionMutation, { data, loading, error }] = useDeleteThirdPartyConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteThirdPartyConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>(DeleteThirdPartyConnectionDocument, options);
      }
export type DeleteThirdPartyConnectionMutationHookResult = ReturnType<typeof useDeleteThirdPartyConnectionMutation>;
export type DeleteThirdPartyConnectionMutationResult = Apollo.MutationResult<DeleteThirdPartyConnectionMutation>;
export type DeleteThirdPartyConnectionMutationOptions = Apollo.BaseMutationOptions<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>;
export const CreateTimeBlockerDocument = gql`
    mutation createTimeBlocker($staffId: ID, $startDate: DateTime!, $endDate: DateTime!, $reason: String) {
  createTimeBlocker(
    staffId: $staffId
    startDate: $startDate
    endDate: $endDate
    reason: $reason
  ) {
    id
    business {
      id
    }
    staff {
      id
      name
    }
    startDate
    endDate
    reason
  }
}
    `;
export type CreateTimeBlockerMutationFn = Apollo.MutationFunction<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>;

/**
 * __useCreateTimeBlockerMutation__
 *
 * To run a mutation, you first call `useCreateTimeBlockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeBlockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeBlockerMutation, { data, loading, error }] = useCreateTimeBlockerMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateTimeBlockerMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>(CreateTimeBlockerDocument, options);
      }
export type CreateTimeBlockerMutationHookResult = ReturnType<typeof useCreateTimeBlockerMutation>;
export type CreateTimeBlockerMutationResult = Apollo.MutationResult<CreateTimeBlockerMutation>;
export type CreateTimeBlockerMutationOptions = Apollo.BaseMutationOptions<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>;
export const CalendarTimeBlockersDocument = gql`
    query calendarTimeBlockers($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!]) {
  timeBlockers: allTimeBlockers(
    staffsIds: $staffsIds
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    id
    startDate
    endDate
    reason
    staff {
      id
      name
    }
  }
}
    `;

/**
 * __useCalendarTimeBlockersQuery__
 *
 * To run a query within a React component, call `useCalendarTimeBlockersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTimeBlockersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTimeBlockersQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *   },
 * });
 */
export function useCalendarTimeBlockersQuery(baseOptions: Apollo.QueryHookOptions<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables> & ({ variables: CalendarTimeBlockersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>(CalendarTimeBlockersDocument, options);
      }
export function useCalendarTimeBlockersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>(CalendarTimeBlockersDocument, options);
        }
export function useCalendarTimeBlockersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>(CalendarTimeBlockersDocument, options);
        }
export type CalendarTimeBlockersQueryHookResult = ReturnType<typeof useCalendarTimeBlockersQuery>;
export type CalendarTimeBlockersLazyQueryHookResult = ReturnType<typeof useCalendarTimeBlockersLazyQuery>;
export type CalendarTimeBlockersSuspenseQueryHookResult = ReturnType<typeof useCalendarTimeBlockersSuspenseQuery>;
export type CalendarTimeBlockersQueryResult = Apollo.QueryResult<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>;
export const UpdateSeatsDocument = gql`
    mutation updateSeats($productQuantities: Json!, $interval: StripeInterval!) {
  updateSeats(productQuantities: $productQuantities, interval: $interval) {
    business {
      id
      stripeData
    }
    checkoutSession {
      id
      url
    }
  }
}
    `;
export type UpdateSeatsMutationFn = Apollo.MutationFunction<UpdateSeatsMutation, UpdateSeatsMutationVariables>;

/**
 * __useUpdateSeatsMutation__
 *
 * To run a mutation, you first call `useUpdateSeatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeatsMutation, { data, loading, error }] = useUpdateSeatsMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useUpdateSeatsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeatsMutation, UpdateSeatsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeatsMutation, UpdateSeatsMutationVariables>(UpdateSeatsDocument, options);
      }
export type UpdateSeatsMutationHookResult = ReturnType<typeof useUpdateSeatsMutation>;
export type UpdateSeatsMutationResult = Apollo.MutationResult<UpdateSeatsMutation>;
export type UpdateSeatsMutationOptions = Apollo.BaseMutationOptions<UpdateSeatsMutation, UpdateSeatsMutationVariables>;
export const UpdateUserPersonalDataDocument = gql`
    mutation updateUserPersonalData($name: String!) {
  updateUserPersonalData(name: $name) {
    id
    name
  }
}
    `;
export type UpdateUserPersonalDataMutationFn = Apollo.MutationFunction<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>;

/**
 * __useUpdateUserPersonalDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserPersonalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPersonalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPersonalDataMutation, { data, loading, error }] = useUpdateUserPersonalDataMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserPersonalDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>(UpdateUserPersonalDataDocument, options);
      }
export type UpdateUserPersonalDataMutationHookResult = ReturnType<typeof useUpdateUserPersonalDataMutation>;
export type UpdateUserPersonalDataMutationResult = Apollo.MutationResult<UpdateUserPersonalDataMutation>;
export type UpdateUserPersonalDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>;
export const WorkingHoursSlotsDocument = gql`
    query workingHoursSlots($staffIds: [ID!]!, $timeIncrement: Int, $from: DateTime!, $to: DateTime!) {
  workingHoursSlots(
    staffIds: $staffIds
    timeIncrement: $timeIncrement
    from: $from
    to: $to
  ) {
    slots
    staffSlots
  }
}
    `;

/**
 * __useWorkingHoursSlotsQuery__
 *
 * To run a query within a React component, call `useWorkingHoursSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursSlotsQuery({
 *   variables: {
 *      staffIds: // value for 'staffIds'
 *      timeIncrement: // value for 'timeIncrement'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useWorkingHoursSlotsQuery(baseOptions: Apollo.QueryHookOptions<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables> & ({ variables: WorkingHoursSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>(WorkingHoursSlotsDocument, options);
      }
export function useWorkingHoursSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>(WorkingHoursSlotsDocument, options);
        }
export function useWorkingHoursSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>(WorkingHoursSlotsDocument, options);
        }
export type WorkingHoursSlotsQueryHookResult = ReturnType<typeof useWorkingHoursSlotsQuery>;
export type WorkingHoursSlotsLazyQueryHookResult = ReturnType<typeof useWorkingHoursSlotsLazyQuery>;
export type WorkingHoursSlotsSuspenseQueryHookResult = ReturnType<typeof useWorkingHoursSlotsSuspenseQuery>;
export type WorkingHoursSlotsQueryResult = Apollo.QueryResult<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>;